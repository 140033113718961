// React
import React, { useState } from 'react'
// MainStem - UI
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  ContentLoaderTable,
  FormattedCurrency,
  InputText,
  Label,
  PageTitle,
  theme,
  toast
} from '@mainstem/react-mainstem'
// MainStem - API
import { MainStemApi, MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetails } from 'api-new'
// Icons
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons'

const PageProductsScrape = () => {
  // View State - Data
  const [error, setError] = useState<boolean>(false)
  const [productData, setProductData] = useState<
    MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetails | undefined
  >(undefined)
  // View State - Inputs
  const [url, setURL] = useState<string | undefined>(undefined)
  // View State - Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)

  const handleGetData = () => {
    if (!url) {
      toast.error('Please enter a URL')
      return
    }

    setLoading(true)
    setProductData(undefined)
    setError(false)

    const apiRequest = {
      url
    }

    MainStemApi.api
      .productsScrape(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setProductData(apiResponse.data.product)
        } else {
          toast.error('Product data could not be scraped successfuly.')
          setError(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <PageTitle
        icon={faCloudArrowDown}
        subtitle='Here you can enter a URL of a product and we will use AI to try to scrape the products name, price, SKU and primary image.'
        title='Product Scrape'
      />
      <br />
      <Card>
        <CardBody topPadding>
          <InputText
            appendButton={
              <Button
                color='fancy'
                icon={faCloudArrowDown}
                loading={loading}
                onClick={() => {
                  handleGetData()
                }}
              >
                Scrape Data
              </Button>
            }
            disabled={loading}
            label='URL'
            onChange={(newValue) => {
              setURL(newValue)
            }}
            onEnter={() => {
              handleGetData()
            }}
            placeholder='https://www.example.com/product/123456'
            value={url}
          />
        </CardBody>
      </Card>
      {loading === true && (
        <>
          <br />
          <Card>
            <CardHeader title={<CardHeaderTitle>Product Scraping In Progress...</CardHeaderTitle>} />
            <CardBody>
              <ContentLoaderTable />
            </CardBody>
          </Card>
        </>
      )}
      {productData !== undefined && (
        <>
          <br />
          <Card>
            <CardHeader title={<CardHeaderTitle>Scrape Results</CardHeaderTitle>} />
            <CardBody>
              <Label>Product Name:</Label>
              <br />
              {productData.name ? productData.name : <i>N/A</i>}
              <br />
              <br />
              <Label>Product SKU:</Label>
              <br />
              {productData.sku ? productData.sku : <i>N/A</i>}
              <br />
              <br />
              <Label>Product Price:</Label>
              <br />
              {productData.price ? <FormattedCurrency value={productData.price} /> : <i>N/A</i>}
              <br />
              <br />
              <Label>Product Main Image URL:</Label>
              <br />
              {productData.mainImage?.url ? (
                <a href={productData.mainImage.url} rel='noopener noreferrer' target='_blank'>
                  {productData.mainImage.url}
                </a>
              ) : (
                <i>N/A</i>
              )}
            </CardBody>
          </Card>
        </>
      )}
      {error === true && (
        <>
          <br />
          <Card>
            <CardHeader title={<CardHeaderTitle>Product Scraping Error</CardHeaderTitle>} />
            <CardBody>
              <Alert color='danger' title='Product Scrape Failed'>
                <p>It looks like the product scrape for the URL you provided did not work successfuly.</p>
              </Alert>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default PageProductsScrape
