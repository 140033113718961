// React
import React, { Fragment, useCallback, useEffect, useState } from 'react'
// Routing
import { Link, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  ButtonGroup,
  FormattedCurrency,
  FormattedNumber,
  Label,
  Loader,
  Tabs,
  Tab,
  theme,
  Grid,
  GridItem,
  Alert,
  Switch,
  ProfileTitle,
  ProfileWrapper,
  ProfileCard,
  InputText
} from '@mainstem/react-mainstem'
// DEPRECATE : ReactStrap
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Progress, Row } from 'reactstrap'
// DEPRECATE : MainStem
import {
  BarChart,
  FileUpload,
  LineChart,
  PrettySwitch,
  PrettyTable,
  ActivityLogTable,
  InputCurrency
} from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDollarSign,
  faExternalLink,
  faImage,
  faImagePolaroid,
  faPhone,
  faPlus,
  faSave,
  faSearch,
  faUniversity,
  faUser
} from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { NotDone, SelectAccountSize, SelectPricingType, SelectProgramType } from 'components'
import { NotesList } from 'components/NotesList'
import ValueMetrics from 'components/ValueMetrics'
import MakePaymentsOrders from 'components/Payments/make-order-payments'
import MakePaymentsInvoices from 'components/Payments/make-invoice-payments'
import PaymentMethods from 'components/Payments/payment-methods'
// Local - Table Definitions
import { columnsOrders, optionsOrders } from './tables/table-orders'
import { columnsLocations, optionsLocations } from './tables/table-locations'
import { columnsUsers, optionsUsers } from './tables/table-users'
// Local - Components
import ShipmentsList from './components/shipments-list'
import Terms from './components/terms'
import IntegrationLogsTable from './components/IntegrationLogsTable'
// Components from other pages
import PageCatalogsList from 'pages/catalogs/list'
import { columnsIntegrations } from './tables/table-integrations'
import {
  MainStemApi,
  MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsLocationAllowablePaymentOptions,
  MainStemAPIControllersAdminCustomersDetailsAllowablePaymentOptions,
  MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetails,
  MainStemAPIControllersAdminCustomersLocationsListAPIResponseLocationDetails,
  MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetails,
  MainStemAPIControllersAdminCustomersUsersListAPIResponseUserDetails
} from 'api-new'

const PageCustomersDetails: React.FC = () => {
  // Routing
  const { id } = useParams()
  // View State - Loading
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  // View State - Data
  const [activityLogs, setActivityLogs] = useState([])
  const [allowedPaymentOptions, setAllowedPaymentOptions] = useState<
    MainStemAPIControllersAdminCustomersDetailsAllowablePaymentOptions | undefined
  >(undefined)
  const [locations, setLocations] = useState<
    MainStemAPIControllersAdminCustomersLocationsListAPIResponseLocationDetails[]
  >([])
  const [locationsAllowablePaymentOptions, setLocationsAllowablePaymentOptions] = useState<
    MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsLocationAllowablePaymentOptions[]
  >([])
  const [orders, setOrders] = useState<MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetails[]>([])
  const [organizationDetails, setOrganizationDetails] = useState<
    MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetails | undefined
  >(undefined)
  const [permissions, setPermissions] = useState([])
  const [users, setUsers] = useState<MainStemAPIControllersAdminCustomersUsersListAPIResponseUserDetails[]>([])
  // View State - Modals
  const [showLogoFileUpload, setShowLogoFileUpload] = useState(false)
  const [showPowerBIFileUpload, setShowPowerBIFileUpload] = useState(false)
  // View State - Counts
  const [countCatalogs, setCountCatalogs] = useState(0)
  const [countNotes, setCountNotes] = useState(0)
  // View State
  const [showAllLocationOptions, setShowAllLocationOptions] = useState(false)

  const toggleCustomField = (documentID, documentType, fieldName, fieldValue) => {
    const apiRequest = {
      documentID: documentID,
      documentType: documentType,
      fieldName: fieldName,
      fieldValue: fieldValue
    }
    MainStemApi.api.customFieldsCreateOrUpdate(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        const copyOfAllowedPaymentOptions = { ...allowedPaymentOptions }
        if (fieldName === 'AllowACH' && documentType === 'Customer') {
          copyOfAllowedPaymentOptions.ach = fieldValue === 'true'
          setAllowedPaymentOptions(copyOfAllowedPaymentOptions)
        } else {
          loadLocationAllowablePaymentOptions()
        }
      }
    })
  }

  const loadDetails = useCallback(() => {
    setLoading(true)
    const apiRequest = {
      id
    }
    MainStemApi.api.customersDetails(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setOrganizationDetails(apiResponse.data.organization)
        setAllowedPaymentOptions(apiResponse.data.allowedPaymentOptions)
        setLoading(false)
      }
    })
  }, [id])

  const loadLocationsList = useCallback(() => {
    const apiRequest = {
      customerID: id
    }
    MainStemApi.api.customersLocationsList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLocations(apiResponse.data.locations || [])
      }
    })
  }, [id])

  const loadUsersList = useCallback(() => {
    const apiRequest = {
      organizationId: id
    }
    MainStemApi.api.customersUsersList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setUsers(apiResponse.data.users || [])
      }
    })
  }, [id])

  const loadOrdersList = useCallback(() => {
    const apiRequest = {
      organizationId: id
    }
    MainStemApi.api.customersOrdersList(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setOrders(apiResponse.data.orders || [])
      }
    })
  }, [id])

  const loadLocationAllowablePaymentOptions = useCallback(() => {
    const apiRequest = {
      customerID: id
    }
    MainStemApi.api.paymentMethodsLocationsAllowablePayments(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        setLocationsAllowablePaymentOptions(apiResponse.data.locationPaymentOptions || [])
      }
    })
  }, [id])

  const handleDisableAllLocations = (id, typeOfAllowable) => {
    const apiRequest = {
      customerID: id,
      type: typeOfAllowable
    }

    MainStemApi.api.paymentMethodsLocationsDisableAllowablePayment(apiRequest).then((apiResponse) => {
      if (apiResponse.data.wasSuccessful) {
        loadLocationAllowablePaymentOptions()
      }
    })
  }

  const loadPermissions = useCallback(() => {
    setPermissions([])
    console.log(permissions)
  }, [permissions])

  const handleSave = () => {
    if (!organizationDetails) {
      return
    }

    setLoadingSave(true)

    const apiRequest = {
      pricingType: organizationDetails.pricingType,
      accountSize: organizationDetails.accountSize,
      allowCreditCards: organizationDetails.allowCreditCards,
      allowOrderRecommendations: organizationDetails.allowOrderRecommendations,
      allowPunchOutInMainNav: organizationDetails.allowPunchOutInMainNav,
      allowTermPayments: organizationDetails.allowTermPayments,
      dateGoLive: organizationDetails.dateGoLive,
      dateTrialExpires: organizationDetails.dateTrialExpires,
      email: organizationDetails.email,
      id,
      internalTeamsChannelId: organizationDetails.internalTeamsChannelId,
      maxNumberOfLocations: organizationDetails.maxNumberOfLocations,
      maxNumberOfUsers: organizationDetails.maxNumberOfUsers,
      monthlySpendBaseline: organizationDetails.monthlySpendBaseline,
      monthlySpendCapturable: organizationDetails.monthlySpendCapturable,
      monthlySpendTotal: organizationDetails.monthlySpendTotal,
      name: organizationDetails.name,
      phone: organizationDetails.phone,
      programType: organizationDetails.programType,
      saasFee: organizationDetails.saasFee,
      saasFeeEnabled: organizationDetails.saasFeeEnabled,
      sideKickEnabled: organizationDetails.sideKickEnabled,
      sideKickIndex: organizationDetails.sideKickIndex,
      sideKickPrivateIndexEnabled: organizationDetails.sideKickPrivateIndexEnabled,
      sideKickURL: organizationDetails.sideKickURL,
      termDays: organizationDetails.termDays,
      termsCreditLimit: organizationDetails.termsCreditLimit
    }

    MainStemApi.api
      .customersUpdate(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          toast.success('Customer details successfully updated!')
          loadDetails()
        }
      })
      .finally(() => {
        setLoadingSave(false)
      })
  }

  // Fires if the ID in the URL changes
  useEffect(() => {
    loadDetails()
    loadLocationsList()
    loadUsersList()
    loadOrdersList()
    loadPermissions()
    loadLocationAllowablePaymentOptions()
  }, [id])

  return (
    <>
      {loading || !organizationDetails ? (
        <Loader />
      ) : (
        <>
          <Fragment>
            <ProfileTitle
              actions={
                <>
                  <ButtonGroup>
                    <Button
                      href={`https://qbo.intuit.com/app/customerdetail?nameId=${
                        organizationDetails.quickbooksId ? organizationDetails.quickbooksId : '8532'
                      }`}
                    >
                      <div>
                        <FontAwesomeIcon icon={faExternalLink} />
                        <span>&nbsp;Open In&nbsp;</span>
                        <img alt='QuickBooks Logo' src={theme.logos.quickbooks} style={{ height: '15px' }} />
                      </div>
                    </Button>
                  </ButtonGroup>
                </>
              }
              icons={faUser}
              loading={loading}
              logo={organizationDetails.imgURL_Logo ? organizationDetails.imgURL_Logo : theme.images.notAvailable}
              name={`Customer #${id} - ${organizationDetails.name}`}
              type='Customer'
            />
            <ProfileWrapper>
              <Grid>
                <GridItem colSpan={4}>
                  <ProfileCard icon={faImagePolaroid} title={'Logo'}>
                    <div className='img-thumbnail text-center'>
                      <img
                        alt='Customer Logo'
                        src={
                          !organizationDetails.imgURL_Logo ? theme.images.notAvailable : organizationDetails.imgURL_Logo
                        }
                        style={{ maxHeight: 250, maxWidth: '100%' }}
                      />
                    </div>
                    <Button
                      block={true}
                      color='fancy'
                      onClick={() => {
                        setShowLogoFileUpload(true)
                      }}
                    >
                      <FontAwesomeIcon icon={faImage} />
                      &nbsp;Upload New Image
                    </Button>
                    {showLogoFileUpload && (
                      <FileUpload
                        id={id}
                        onClose={() => {
                          setShowLogoFileUpload(false)
                        }}
                        onError={() => {
                          toast.error('Failed to upload new logo')
                        }}
                        onSuccess={() => {
                          toast.success('Successfully uploaded new logo!')
                          loadDetails()
                        }}
                        title='Upload Customer Logo'
                        type='CustomerLogo'
                      />
                    )}
                  </ProfileCard>
                </GridItem>
                <GridItem colSpan={4}>
                  <ProfileCard icon={faPhone} title={'Zoho'}>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col className='align-self-center text-left'>
                            <h6 className='m-0'>MainStem Zoho - Settings</h6>
                          </Col>
                          <Col className='align-self-center text-left' md='auto'>
                            <Button color='secondary' disabled loading={loadingSave} onClick={handleSave}>
                              <FontAwesomeIcon icon={faSave} />
                              &nbsp;Save Changes
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <NotDone />
                      </CardBody>
                    </Card>
                  </ProfileCard>
                </GridItem>
                <GridItem colSpan={4}>
                  <ProfileCard icon={faDollarSign} title={'QuickBooks'}>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col className='align-self-center text-left'>
                            <h6 className='m-0'>MainStem QuickBooks - Settings</h6>
                          </Col>
                          <Col className='align-self-center text-left' md='auto'>
                            <Button color='secondary' loading={loadingSave} onClick={handleSave}>
                              <FontAwesomeIcon icon={faSave} />
                              &nbsp;Save Changes
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <FormGroup>
                          <Label>
                            <img alt='QuickBooks Logo' src={theme.logos.quickbooks} style={{ maxHeight: 16 }} /> Company
                            ID
                          </Label>
                          <Input
                            defaultValue={organizationDetails.quickbooksId}
                            onChange={({ target }) => {
                              setOrganizationDetails({
                                ...organizationDetails,
                                quickbooksId: target.value
                              })
                            }}
                            type='text'
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </ProfileCard>
                </GridItem>
              </Grid>
              <br />
              <Tabs tabs2>
                <Tab tabKey='details' title='Details'>
                  <div className='p-3'>
                    <Row>
                      <Col md={4}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col className='align-self-center text-left'>
                                <h6 className='m-0'>MainStem Terms - Settings</h6>
                              </Col>
                              <Col className='align-self-center text-left' md='auto'>
                                <Button color='secondary' loading={loadingSave} onClick={handleSave}>
                                  <FontAwesomeIcon icon={faSave} />
                                  &nbsp;Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody>
                            <PrettySwitch
                              isChecked={organizationDetails.allowTermPayments}
                              onChange={({ target }) => {
                                setOrganizationDetails({
                                  ...organizationDetails,
                                  allowTermPayments: target.checked
                                })
                              }}
                            >
                              (Terms) Can this customer pay with a line of credit?
                            </PrettySwitch>
                            {organizationDetails.allowTermPayments && (
                              <Fragment>
                                <hr />
                                <FormGroup>
                                  <Label>Term Days</Label>
                                  <Input
                                    defaultValue={organizationDetails.termDays}
                                    onChange={({ target }) => {
                                      setOrganizationDetails((previous) => {
                                        return {
                                          ...previous,
                                          termDays: parseInt(target.value) || 0
                                        }
                                      })
                                    }}
                                    type='number'
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label>Credit Limit</Label>
                                  <InputCurrency
                                    defaultValue={organizationDetails.termsCreditLimit}
                                    onChange={(value) => {
                                      setOrganizationDetails((previous) => {
                                        return {
                                          ...previous,
                                          termsCreditLimit: value
                                        }
                                      })
                                    }}
                                  />
                                </FormGroup>
                                <Row>
                                  <Col md={4}>
                                    Limit:
                                    <br />
                                    <FormattedCurrency value={organizationDetails.termsCreditLimit || 0} />
                                  </Col>
                                  <Col md={4}>
                                    Due:
                                    <br />
                                    <FormattedCurrency value={organizationDetails.termsDetails?.due || 0} />
                                  </Col>
                                  <Col md={4}>
                                    Available:
                                    <br />
                                    <FormattedCurrency value={organizationDetails.termsDetails?.available || 0} />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    {organizationDetails.termsDetails?.available &&
                                      organizationDetails.termsCreditLimit && (
                                        <>
                                          <Progress
                                            color='success'
                                            value={
                                              organizationDetails.termsDetails.available /
                                                organizationDetails.termsCreditLimit || 0
                                            }
                                          >
                                            {Math.round(
                                              organizationDetails.termsDetails.available /
                                                organizationDetails.termsCreditLimit
                                            ) || 0}
                                            % To Limit
                                          </Progress>
                                        </>
                                      )}
                                  </Col>
                                </Row>
                              </Fragment>
                            )}
                          </CardBody>
                        </Card>
                        <br />
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col className='align-self-center text-left'>
                                <h6 className='m-0'>MainStem SaaS - Settings</h6>
                              </Col>
                              <Col className='align-self-center text-left' md='auto'>
                                <Button
                                  color='secondary'
                                  loading={loadingSave}
                                  onClick={() => {
                                    handleSave()
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSave} />
                                  &nbsp;Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Switch
                              isChecked={organizationDetails.saasFeeEnabled || false}
                              offText='SaaS Fee Is Disabled'
                              onChange={() => {
                                setOrganizationDetails({
                                  ...organizationDetails,
                                  saasFeeEnabled: !organizationDetails.saasFeeEnabled
                                })
                              }}
                              onText='SaaS Fee Is Enabled'
                            />
                            <hr />
                            <FormGroup>
                              <Label>Monthly SaaS Fee</Label>
                              <InputCurrency
                                defaultValue={organizationDetails.saasFee}
                                onChange={(newValue) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    saasFee: newValue
                                  })
                                }}
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                        <br />
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col className='align-self-center text-left'>
                                <h6 className='m-0'>MainStem SideKick - Settings</h6>
                              </Col>
                              <Col className='align-self-center text-left' md='auto'>
                                <Button
                                  color='secondary'
                                  loading={loadingSave}
                                  onClick={() => {
                                    handleSave()
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSave} />
                                  &nbsp;Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Switch
                              isChecked={organizationDetails.sideKickEnabled || false}
                              offText='SideKick Is Disabled'
                              onChange={() => {
                                setOrganizationDetails({
                                  ...organizationDetails,
                                  sideKickEnabled: !organizationDetails.sideKickEnabled
                                })
                              }}
                              onText='SideKick Is Enabled'
                            />
                            <Switch
                              isChecked={organizationDetails.sideKickPrivateIndexEnabled || false}
                              offText='SideKick Private Index Is Disabled'
                              onChange={() => {
                                setOrganizationDetails({
                                  ...organizationDetails,
                                  sideKickPrivateIndexEnabled: !organizationDetails.sideKickPrivateIndexEnabled
                                })
                              }}
                              onText='SideKick Private Index Is Enabled'
                            />
                            <hr />
                            <FormGroup>
                              <Label>SideKick AI API URL</Label>
                              <InputText
                                onChange={(newValue) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    sideKickURL: newValue
                                  })
                                }}
                                value={organizationDetails.sideKickURL}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>SideKick AI Index Name</Label>
                              <InputText
                                onChange={(newValue) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    sideKickIndex: newValue
                                  })
                                }}
                                value={organizationDetails.sideKickIndex}
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                        <br />
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col className='align-self-center text-left'>
                                <h6 className='m-0'>MainStem Product - Settings</h6>
                              </Col>
                              <Col className='align-self-center text-left' md='auto'>
                                <Button
                                  color='secondary'
                                  loading={loadingSave}
                                  onClick={() => {
                                    handleSave()
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSave} />
                                  &nbsp;Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <FormGroup>
                              <Label>Pricing Type</Label>
                              <SelectPricingType
                                defaultValue={organizationDetails.pricingType}
                                onSelected={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    pricingType: value
                                  })
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Account Size</Label>
                              <SelectAccountSize
                                defaultValue={organizationDetails.accountSize}
                                onSelected={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    accountSize: value
                                  })
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Package Type</Label>
                              <SelectProgramType
                                defaultValue={organizationDetails.programType}
                                onSelected={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    programType: value,
                                    maxNumberOfLocations:
                                      value === 'Standard' ? 1 : value === 'Professional' ? 4 : 1000,
                                    maxNumberOfUsers: value === 'Standard' ? 1 : value === 'Professional' ? 10 : 1000
                                  })
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Can Pay With Credit Card</Label>
                              <Switch
                                isChecked={organizationDetails.allowCreditCards || false}
                                offText='Customer Can NOT Pay With Credit Card'
                                onChange={() => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    allowCreditCards: !organizationDetails.allowCreditCards
                                  })
                                }}
                                onText='Customer Can Pay With Credit Card'
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Allow Direct PunchOut Purchasing</Label>
                              <Switch
                                isChecked={organizationDetails.allowPunchOutInMainNav || false}
                                offText='Direct PunchOut Purchasing Is Disabled'
                                onChange={() => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    allowPunchOutInMainNav: !organizationDetails.allowPunchOutInMainNav
                                  })
                                }}
                                onText='Direct PunchOut Purchasing Is Enabled'
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>(M.O.R.E.) MainStem Order Recommendation Engine</Label>
                              <Switch
                                isChecked={organizationDetails.allowOrderRecommendations || false}
                                offText='Order Recommendation Engine Is Disabled'
                                onChange={() => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    allowOrderRecommendations: !organizationDetails.allowOrderRecommendations
                                  })
                                }}
                                onText='Order Recommendation Engine Is Enabled'
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Maximum Locations</Label>
                              <Input
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    maxNumberOfLocations: parseInt(target.value)
                                  })
                                }}
                                type='text'
                                value={organizationDetails.maxNumberOfLocations}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Maximum Users</Label>
                              <Input
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    maxNumberOfUsers: parseInt(target.value)
                                  })
                                }}
                                type='text'
                                value={organizationDetails.maxNumberOfUsers}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>VIP Teams Channel ID</Label>
                              <Input
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    internalTeamsChannelId: target.value
                                  })
                                }}
                                type='text'
                                value={organizationDetails.internalTeamsChannelId}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Date Trial Expires</Label>
                              <Input
                                defaultValue={
                                  organizationDetails.dateTrialExpires &&
                                  new Date(organizationDetails.dateTrialExpires).toISOString().substr(0, 10)
                                }
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    dateTrialExpires: target.value
                                  })
                                }}
                                type='date'
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Go Live Date</Label>
                              <Input
                                defaultValue={
                                  organizationDetails.dateGoLive &&
                                  new Date(organizationDetails.dateGoLive).toISOString().substr(0, 10)
                                }
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    dateGoLive: target.value
                                  })
                                }}
                                type='date'
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Monthly Spend - Total</Label>
                              <br />
                              <small>The total amount of spend a customer does per month, holstically.</small>
                              <InputCurrency
                                defaultValue={organizationDetails.monthlySpendTotal}
                                onChange={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    monthlySpendTotal: value
                                  })
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Monthly Spend - Capturable</Label>
                              <br />
                              <small>The total amount of spend we think we can capture per month.</small>
                              <InputCurrency
                                defaultValue={organizationDetails.monthlySpendCapturable}
                                onChange={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    monthlySpendCapturable: value
                                  })
                                }}
                              />
                              {organizationDetails.monthlySpendTotal &&
                                organizationDetails.monthlySpendTotal > 0 &&
                                organizationDetails.monthlySpendCapturable &&
                                organizationDetails.monthlySpendCapturable > 0 && (
                                  <small>
                                    &nbsp;(&nbsp;
                                    <FormattedNumber
                                      decimalPlaces={0}
                                      value={
                                        (organizationDetails.monthlySpendCapturable /
                                          organizationDetails.monthlySpendTotal) *
                                        100
                                      }
                                    />
                                    % of total spend&nbsp;)
                                  </small>
                                )}
                            </FormGroup>
                            <FormGroup>
                              <Label>Monthly Spend - Baseline</Label>
                              <br />
                              <small>If the customer spends less than this per month we alert our team.</small>
                              <InputCurrency
                                defaultValue={organizationDetails.monthlySpendBaseline}
                                onChange={(value) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    monthlySpendBaseline: value
                                  })
                                }}
                              />
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={8}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col className='align-self-center text-left'>Customer Details</Col>
                              <Col className='align-self-center text-left' md='auto'>
                                <Button color='secondary' loading={loadingSave} onClick={handleSave}>
                                  <FontAwesomeIcon icon={faSave} />
                                  &nbsp;Save Changes
                                </Button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <FormGroup>
                              <Label>Customer Name:</Label>
                              <Input
                                defaultValue={organizationDetails.name}
                                onChange={({ target }) => {
                                  setOrganizationDetails({
                                    ...organizationDetails,
                                    name: target.value
                                  })
                                }}
                                type='text'
                              />
                            </FormGroup>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Email:</Label>
                                  <Input
                                    defaultValue={organizationDetails.email}
                                    onChange={({ target }) => {
                                      setOrganizationDetails({
                                        ...organizationDetails,
                                        email: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Phone:</Label>
                                  <Input
                                    defaultValue={organizationDetails.phone}
                                    onChange={({ target }) => {
                                      setOrganizationDetails({
                                        ...organizationDetails,
                                        phone: target.value
                                      })
                                    }}
                                    type='text'
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <br />
                        <ValueMetrics customerId={id} locationIds={undefined} />
                        <br />
                        <Card>
                          <CardHeader>
                            <h5 className='m-0'>Sales History</h5>
                          </CardHeader>
                          <CardBody>
                            <LineChart
                              currency
                              data={organizationDetails.salesHistory?.map((result) => {
                                return {
                                  name: result.label,
                                  value: result.value
                                }
                              })}
                            />
                          </CardBody>
                        </Card>
                        <br />
                        <Card>
                          <CardHeader>
                            <h5 className='m-0'>Sales By Product Line</h5>
                          </CardHeader>
                          <CardBody>
                            <BarChart
                              currency
                              data={organizationDetails.salesProductLines?.map((result) => {
                                return {
                                  name: result.label,
                                  value: result.value
                                }
                              })}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab length={locations.length} tabKey='locations' title='Locations'>
                  <PrettyTable
                    columns={columnsLocations}
                    customButtons={
                      <Link to={`/locations/create/${id}`}>
                        <Button>
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp;Create New Location
                        </Button>
                      </Link>
                    }
                    data={locations}
                    options={optionsLocations}
                    title='Locations'
                  />
                </Tab>
                <Tab length={orders.length} tabKey='orders' title='Orders'>
                  <PrettyTable
                    columns={columnsOrders({ router: {}, filterFunc: () => {} })}
                    data={orders || []}
                    options={optionsOrders}
                    title='Sales Orders'
                  />
                </Tab>

                <Tab tabKey='billing' title='Billing'>
                  <Card>
                    <CardHeader>Customer Billing</CardHeader>
                    <CardBody>
                      <Tabs>
                        <Tab tabKey='allowed payment options' title='Allowed Payment Options'>
                          <Grid>
                            <GridItem key='achOptionGrid' sm={6}>
                              {allowedPaymentOptions?.ach ? (
                                <>
                                  <Alert
                                    actions={
                                      <>
                                        <Button
                                          color='danger'
                                          onClick={() => {
                                            toggleCustomField(id, 'Customer', 'AllowACH', 'false')
                                          }}
                                        >
                                          Disable ACH
                                        </Button>
                                        <Button
                                          color='info'
                                          onClick={() => {
                                            setShowAllLocationOptions(!showAllLocationOptions)
                                          }}
                                        >
                                          {showAllLocationOptions ? 'Hide' : 'Show'} All Locations
                                        </Button>
                                      </>
                                    }
                                    color='success'
                                    icon={faUniversity}
                                  >
                                    <strong>ACH</strong>&nbsp; - Enabled
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <Alert
                                    actions={
                                      <>
                                        <Button
                                          color='info'
                                          onClick={() => {
                                            toggleCustomField(id, 'Customer', 'AllowACH', 'true')
                                          }}
                                        >
                                          Enable ACH
                                        </Button>
                                        <Button
                                          color='info'
                                          onClick={() => {
                                            setShowAllLocationOptions(!showAllLocationOptions)
                                          }}
                                        >
                                          {showAllLocationOptions ? 'Hide' : 'Show'} All Locations
                                        </Button>
                                      </>
                                    }
                                    color='danger'
                                    icon={faUniversity}
                                  >
                                    <strong>ACH</strong>&nbsp; - Disabled
                                  </Alert>
                                </>
                              )}
                              {showAllLocationOptions && (
                                <>
                                  <br />
                                  <Card>
                                    <CardHeader>
                                      <Button
                                        color='info'
                                        onClick={() => {
                                          handleDisableAllLocations(id, 'ACH')
                                        }}
                                      >
                                        Disable All Locations
                                      </Button>
                                    </CardHeader>
                                    <CardBody>
                                      <Grid>
                                        {locationsAllowablePaymentOptions.map((option, optionIndex) => {
                                          return (
                                            <>
                                              <GridItem key={`ach-location-${optionIndex}-id`} sm={6}>
                                                <Link to={'/locations/details/' + option.locationID}>
                                                  <Button color='primary' icon={faSearch}>
                                                    {option.locationID}
                                                    &nbsp;
                                                    {option.name}
                                                  </Button>
                                                </Link>
                                              </GridItem>
                                              <GridItem key={`ach-location-${optionIndex}-achOption`} sm={6}>
                                                <>
                                                  {option.paymentOptions?.ach ? (
                                                    <>
                                                      <Alert
                                                        actions={
                                                          <>
                                                            <Button
                                                              color='danger'
                                                              onClick={() => {
                                                                toggleCustomField(
                                                                  option.locationID,
                                                                  'Location',
                                                                  'AllowACH',
                                                                  'false'
                                                                )
                                                              }}
                                                            >
                                                              Disable ACH
                                                            </Button>
                                                          </>
                                                        }
                                                        color='success'
                                                        icon={faUniversity}
                                                      >
                                                        <strong>ACH</strong>&nbsp; - Enabled
                                                      </Alert>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Alert
                                                        actions={
                                                          <>
                                                            <Button
                                                              color='info'
                                                              onClick={() => {
                                                                toggleCustomField(
                                                                  option.locationID,
                                                                  'Location',
                                                                  'AllowACH',
                                                                  'true'
                                                                )
                                                              }}
                                                            >
                                                              Enable ACH
                                                            </Button>
                                                          </>
                                                        }
                                                        color='danger'
                                                        icon={faUniversity}
                                                      >
                                                        <strong>ACH</strong>&nbsp; - Disabled
                                                      </Alert>
                                                    </>
                                                  )}
                                                </>
                                              </GridItem>
                                            </>
                                          )
                                        })}
                                      </Grid>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                            </GridItem>
                          </Grid>
                        </Tab>

                        <Tab tabKey='terms' title='Terms'>
                          <Terms reload={undefined} setReload={undefined} />
                        </Tab>

                        <Tab tabKey='apply order payments' title='Apply Order Payments'>
                          <MakePaymentsOrders customerID={id} locationIDs={undefined} />
                        </Tab>

                        <Tab tabKey='apply invoice payments' title='Apply Invoice Payments'>
                          <MakePaymentsInvoices customerID={id} locationIDs={undefined} />
                        </Tab>

                        <Tab tabKey='payment methods' title='Payment Methods'>
                          <PaymentMethods customerID={id} locationIDs={undefined} />
                        </Tab>
                      </Tabs>
                    </CardBody>
                  </Card>
                </Tab>

                <Tab tabKey='shipping' title='Shipping'>
                  <ShipmentsList />
                </Tab>

                <Tab length={users.length ? users.length : 0} tabKey='users & permissions' title='Users & Permissions'>
                  <PrettyTable
                    columns={columnsUsers}
                    customButtons={
                      <React.Fragment>
                        <Link to='/admin/users/create/'>
                          <Button block color='primary'>
                            <FontAwesomeIcon icon={faUser} />
                            &nbsp;Create User
                          </Button>
                        </Link>
                      </React.Fragment>
                    }
                    data={users}
                    options={optionsUsers}
                    title='Users'
                  />
                </Tab>

                <Tab length={countCatalogs} tabKey='catalogs' title='Catalogs'>
                  <PageCatalogsList locationIDs={undefined} setCatalogCount={setCountCatalogs} />
                </Tab>

                <Tab tabKey='integrations' title='Integrations'>
                  <Tabs>
                    <Tab tabKey='integration settings' title='Integration Settings'>
                      <PrettyTable
                        columns={columnsIntegrations}
                        customButtons={
                          <Fragment>
                            <Button
                              onClick={() => {
                                setShowPowerBIFileUpload(true)
                              }}
                            >
                              <FontAwesomeIcon icon={faImage} />
                              &nbsp;Upload New PowerBI File
                            </Button>
                          </Fragment>
                        }
                        data={organizationDetails.integrations || []}
                        title='Integration Settings'
                      />
                      {showPowerBIFileUpload && (
                        <FileUpload
                          id={id}
                          onClose={() => {
                            setShowPowerBIFileUpload(false)
                          }}
                          onError={() => {
                            toast.error('Failed to upload new PowerBI file')
                          }}
                          onSuccess={() => {
                            toast.success('Successfully uploaded new PowerBI file!')
                            loadDetails()
                          }}
                          title='Upload Customer PowerBI File'
                          type='PowerBI'
                        />
                      )}
                    </Tab>

                    <Tab tabKey='logs' title='Logs'>
                      <IntegrationLogsTable customerID={id} locationID={undefined} />
                    </Tab>
                  </Tabs>
                </Tab>
                <Tab length={countNotes} tabKey='notes' title='Notes'>
                  <NotesList
                    allowPrivate
                    documentID={id}
                    documentType='customer'
                    onActionApplied={() => {
                      // console.log('Action applied!', row, rowIndex)
                      // Since we use Invoice notes for fulfillment Note now.
                    }}
                    setNoteCount={setCountNotes}
                    title='Customer Notes'
                  />
                </Tab>

                <Tab length={activityLogs.length ? activityLogs.length : 0} tabKey='activity log' title='Activity Log'>
                  {id && (
                    <ActivityLogTable
                      documentID={id}
                      documentType='Customer'
                      setActivityLogs={setActivityLogs}
                      title='Customer Activity Logs'
                    />
                  )}
                </Tab>
              </Tabs>
            </ProfileWrapper>
          </Fragment>
        </>
      )}
    </>
  )
}

export default PageCustomersDetails
