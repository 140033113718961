// React
import { lazy } from 'react'
// Routing
import { Route, Switch } from 'react-router-dom'
// Global - Pages - Accounting
import PageAccountingDashboard from 'pages/accounting/dashboard'
// Global - Pages - Accounting - Bills
import PageAccountingBillsList from 'pages/accounting/bills/list'
import PageAccountingBillsDetails from 'pages/accounting/bills/details'
// Global - Pages - Accounting - QuickBooks
import PageAccountingQuickBooks from 'pages/accounting/quickbooks'
import PageConnectionQuickBooks from 'pages/accounting/quickbooks/connector'
// Global - Pages - Admin
import PageAdminDashboard from 'pages/admin/dashboard'
// Global - Pages - Admin - Attributes
import PageAttributeList from 'pages/admin/attributes/list'
import PageAttributesDetails from 'pages/admin/attributes/details'
import PageAttributeValuesDetails from 'pages/admin/attributes/attribute-value-details'
// Global - Pages - Admin - Carriers
import PageAdminCarrierDetails from 'pages/admin/carrier/details'
import PageAdminCarrierUpdate from 'pages/admin/carrier/update'
import PageAdminCarrierCreate from 'pages/admin/carrier/create'
import PageAdminCarrierList from 'pages/admin/carrier/list'
// Global - Pages - Admin - Collection - Category
import PageAdminCollectionCategoryCreate from 'pages/admin/collection/category/create'
import PageAdminCollectionCategoryDashboard from 'pages/admin/collection/category/dashboard'
import PageAdminCollectionCategoryDetails from 'pages/admin/collection/category/details'
import PageAdminCollectionCategoryUpdate from 'pages/admin/collection/category/update'
// Global - Pages - Admin - Collection - Classification
import PageAdminCollectionClassificationCreate from 'pages/admin/collection/classification/create'
import PageAdminCollectionClassificationDashboard from 'pages/admin/collection/classification/dashboard'
import PageAdminCollectionClassificationDetails from 'pages/admin/collection/classification/details'
import PageAdminCollectionClassificationUpdate from 'pages/admin/collection/classification/update'
// Global - Pages - Admin - Collection - Sub-Category
import PageAdminCollectionSubCategoryCreate from 'pages/admin/collection/sub-category/create'
import PageAdminCollectionSubCategoryDashboard from 'pages/admin/collection/sub-category/dashboard'
import PageAdminCollectionSubCategoryDetails from 'pages/admin/collection/sub-category/details'
import PageAdminCollectionSubCategoryUpdate from 'pages/admin/collection/sub-category/update'
// Global - Pages - Admin - Feed
import PageAdminFeedCreate from 'pages/admin/feed/create'
import PageAdminFeedDashboard from 'pages/admin/feed/dashboard'
import PageAdminFeedDetails from 'pages/admin/feed/details'
import PageAdminFeedUpdate from 'pages/admin/feed/update'
// Global - Pages - Admin - Parent Product Types
import PageAdminParentProductTypeCreate from 'pages/admin/parent-product-type/create'
import PageAdminParentProductTypeDashboard from 'pages/admin/parent-product-type/dashboard'
import PageAdminParentProductTypeDetails from 'pages/admin/parent-product-type/details'
import PageAdminParentProductTypeUpdate from 'pages/admin/parent-product-type/update'
// Global - Pages - Admin - Product Tags
import PageProductTagsList from 'pages/admin/product-tags/list'
import PageProductTagsDetails from 'pages/admin/product-tags/details'
// Global - Pages - Admin - UNSPSC
import PageAdminUNSPSCDashboard from 'pages/admin/unspsc/dashboard'
import PageAdminUNSPSCSegmentDashboard from 'pages/admin/unspsc/segment/dashboard'
import PageAdminUNSPSCSegmentUpdate from 'pages/admin/unspsc/segment/update'
import PageAdminUNSPSCSegmentDetails from 'pages/admin/unspsc/segment/details'
import PageAdminUNSPSCSegmentCreate from 'pages/admin/unspsc/segment/create'
// Global - Pages - Customers
import PageCustomersCreate from 'pages/customers/create'
import PageCustomersList from 'pages/customers/list'
import PageCustomersDetails from 'pages/customers/details'
// Global - Pages - Dashboard
import PageDashboard from 'pages/dashboard'
// Global - Pages - Locations
import PageLocationsCreate from 'pages/locations/create'
import PageLocationDetails from 'pages/locations/details'
// Global - Pages - Products
import PageProductsList from 'pages/products/dashboard'
import PageProductsCreate from 'pages/products/create'
import PageProductsManagement from 'pages/products/management'
import PageProductsScrape from 'pages/products/scrape'
// Global - Pages - Products - Child
import PageProductChildCreate from 'pages/products/child/create'
import PageProductChildDetails from 'pages/products/child/details'
import PageProductChildUpdate from 'pages/products/child/update'
// Global - Pages - Products - Parent
import PageProductsParentCreate from 'pages/products/parent/create'
import PageProductsParentDetails from 'pages/products/parent/details'
import PageProductsParentUpdate from 'pages/products/parent/update'
// Global - Pages - Purchase Orders
import PagePurchaseOrdersList from 'pages/purchase-orders/list'
import PagePurchaseOrdersDetails from 'pages/purchase-orders/details'
// Global - Pages - Requisitions
import PageRequisitionList from 'pages/requisitions/list'
import PageRequisitionDetails from 'pages/requisitions/details'
// Global - Pages - Returns
import PageReturnsList from 'pages/returns/list'
import PageReturnDetails from 'pages/returns/details'
import PageReturnCreate from 'pages/returns/create'
// Global - Pages - Suppliers
import PageSuppliersList from 'pages/suppliers/list'
import PageSuppliersDetails from 'pages/suppliers/details'
import PageSuppliersCreate from 'pages/suppliers/create'
import PageSuppliersUpdate from 'pages/suppliers/update'
// Global - Pages - Users
import PageUsersList from 'pages/admin/users/list'
import PageUserCreate from 'pages/admin/users/create'
import PageUserDetails from 'pages/admin/users/details'
import PageUserUpdate from 'pages/admin/users/update'
//// DONE

// import PageCatalogsList from 'pages/catalogs/list'
import PageCatalogsDetails from 'pages/catalogs/details'
import PageCatalogsBrowser from 'pages/catalogs/browser'

/* Integration Pages */
import PageIntegrations from 'pages/admin/integrations/list'
import PageHubSpotLogs from 'pages/admin/integrations/hubspot-logs'
import PageMainStemLogs from 'pages/admin/integrations/mainstem-logs'

/* Affiliates Pages */
import PageAffiliatesList from 'pages/admin/affiliates/list'
import PageAffiliatesDetails from 'pages/admin/affiliates/details'

/* Customer Pricing Pages */
import PageCustomerPricingList from 'pages/admin/customer-pricing/list'

/* Permission Pages */
import PagePermissionGroupsList from 'pages/permissionsGroup/list/permissions-group-list'
import PagePermissionGroupsDetails from 'pages/permissionsGroup/details/permissions-group-details'
import PagePermissionGroupsUpdate from 'pages/permissionsGroup/update/permissions-group-update'
import PagePermissionsList from 'pages/admin/permissions/list'
// Punch Outs
import PageInfrastructure from 'pages/infrastructure'
import PageFulfillmentTracker from 'pages/fulfillment/tracker'

const PageInvoiceInquiryDetails = lazy(() => import('pages/invoice-inquiry/details'))

export const Routes = () => (
  <Switch>
    {/* Index / Root / Home Page */}
    <Route exact path='/'>
      <PageDashboard />
    </Route>
    {/* Accounting */}
    <Route exact path='/accounting'>
      <PageAccountingDashboard />
    </Route>
    {/* Accounting - Bills */}
    <Route exact path='/accounting/bills/details/:uuid'>
      <PageAccountingBillsDetails />
    </Route>
    <Route exact path='/accounting/bills/list'>
      <PageAccountingBillsList />
    </Route>
    {/* Accounting - QuickBooks */}
    <Route exact path='/accounting/quickbooks'>
      <PageAccountingQuickBooks />
    </Route>
    <Route exact path='/accounting/quickbooks/connector'>
      <PageConnectionQuickBooks />
    </Route>
    {/* Admin */}
    <Route exact path='/admin'>
      <PageAdminDashboard />
    </Route>
    {/* Admin - Users */}
    <Route exact path='/admin/users/list'>
      <PageUsersList />
    </Route>
    <Route exact path='/admin/users/create'>
      <PageUserCreate />
    </Route>
    <Route exact path='/admin/users/details/:uuid'>
      <PageUserDetails />
    </Route>
    <Route exact path='/admin/users/update/:uuid'>
      <PageUserUpdate />
    </Route>
    {/* Admin - Carriers */}
    <Route exact path='/admin/carriers/list'>
      <PageAdminCarrierList />
    </Route>
    <Route exact path='/admin/carriers/create'>
      <PageAdminCarrierCreate />
    </Route>
    <Route exact path='/admin/carriers/details/:id'>
      <PageAdminCarrierDetails />
    </Route>
    <Route exact path='/admin/carriers/update/:id'>
      <PageAdminCarrierUpdate />
    </Route>
    {/* Admin - Collection - Categories */}
    <Route exact path='/admin/collection/category/create'>
      <PageAdminCollectionCategoryCreate />
    </Route>
    <Route exact path='/admin/collection/category/dashboard'>
      <PageAdminCollectionCategoryDashboard />
    </Route>
    <Route exact path='/admin/collection/category/details/:id'>
      <PageAdminCollectionCategoryDetails />
    </Route>
    <Route exact path='/admin/collection/category/update/:id'>
      <PageAdminCollectionCategoryUpdate />
    </Route>
    {/* Admin - Collection - SubCategories */}
    <Route exact path='/admin/collection/sub-category/create/:categoryId'>
      <PageAdminCollectionSubCategoryCreate />
    </Route>
    <Route exact path='/admin/collection/sub-category/dashboard'>
      <PageAdminCollectionSubCategoryDashboard />
    </Route>
    <Route exact path='/admin/collection/sub-category/details/:id'>
      <PageAdminCollectionSubCategoryDetails />
    </Route>
    <Route exact path='/admin/collection/sub-category/update/:id'>
      <PageAdminCollectionSubCategoryUpdate />
    </Route>
    {/* Admin - Collection - Classifications */}
    <Route exact path='/admin/collection/classification/create/:subCategoryId'>
      <PageAdminCollectionClassificationCreate />
    </Route>
    <Route exact path='/admin/collection/classification/dashboard'>
      <PageAdminCollectionClassificationDashboard />
    </Route>
    <Route exact path='/admin/collection/classification/details/:id'>
      <PageAdminCollectionClassificationDetails />
    </Route>
    <Route exact path='/admin/collection/classification/update/:id'>
      <PageAdminCollectionClassificationUpdate />
    </Route>
    {/* Admin - Feed */}
    <Route exact path='/admin/feed/create'>
      <PageAdminFeedCreate />
    </Route>
    <Route exact path='/admin/feed/dashboard'>
      <PageAdminFeedDashboard />
    </Route>
    <Route exact path='/admin/feed/details/:id'>
      <PageAdminFeedDetails />
    </Route>
    <Route exact path='/admin/feed/update/:id'>
      <PageAdminFeedUpdate />
    </Route>
    {/* Admin - UNSPSC */}
    <Route exact path='/admin/unspsc/dashboard'>
      <PageAdminUNSPSCDashboard />
    </Route>
    {/* Admin - UNSPSC - Segment */}
    <Route exact path='/admin/unspsc/segment/create'>
      <PageAdminUNSPSCSegmentCreate />
    </Route>
    <Route exact path='/admin/unspsc/segment/dashboard'>
      <PageAdminUNSPSCSegmentDashboard />
    </Route>
    <Route exact path='/admin/unspsc/segment/details/:code'>
      <PageAdminUNSPSCSegmentDetails />
    </Route>
    <Route exact path='/admin/unspsc/segment/update/:code'>
      <PageAdminUNSPSCSegmentUpdate />
    </Route>
    {/* Admin - UNSPSC - Family */}
    {/* Admin - UNSPSC - Class */}
    {/* Admin - UNSPSC - Commodity */}
    {/* Customers */}
    <Route exact path='/customers/create'>
      <PageCustomersCreate />
    </Route>
    <Route exact path='/customers/list'>
      <PageCustomersList />
    </Route>
    <Route exact path='/customers/details/:id'>
      <PageCustomersDetails />
    </Route>
    {/* Catalogs */}
    <Route path='/catalogs/details/:id'>
      <PageCatalogsDetails />
    </Route>
    <Route path='/catalogs/browser/:id?'>
      <PageCatalogsBrowser />
    </Route>
    <Route path='/shop/:id?'>
      <PageCatalogsBrowser />
    </Route>
    {/* Locations */}
    <Route exact path='/locations/create/:customerId'>
      <PageLocationsCreate />
    </Route>
    <Route exact path='/locations/details/:id'>
      <PageLocationDetails />
    </Route>
    {/* Parent Product Types */}
    <Route exact path='/admin/parent-product-type/dashboard'>
      <PageAdminParentProductTypeDashboard />
    </Route>
    <Route exact path='/admin/parent-product-type/create'>
      <PageAdminParentProductTypeCreate />
    </Route>
    <Route exact path='/admin/parent-product-type/details/:id'>
      <PageAdminParentProductTypeDetails />
    </Route>
    <Route exact path='/admin/parent-product-type/update/:id'>
      <PageAdminParentProductTypeUpdate />
    </Route>
    {/* Products - Child */}
    <Route exact path='/products/child/create/:supplierId?'>
      <PageProductChildCreate />
    </Route>
    <Route exact path='/products/child/details/:id'>
      <PageProductChildDetails />
    </Route>
    <Route exact path='/products/child/update/:id'>
      <PageProductChildUpdate />
    </Route>
    {/* Products - Parent */}
    <Route exact path='/products/parent/create'>
      <PageProductsParentCreate />
    </Route>
    <Route exact path='/products/parent/details/:id'>
      <PageProductsParentDetails />
    </Route>
    <Route exact path='/products/parent/update/:id'>
      <PageProductsParentUpdate />
    </Route>
    {/* Products */}
    <Route exact path='/products/create/:supplierId?'>
      <PageProductsCreate />
    </Route>
    <Route exact path='/products/list'>
      <PageProductsList />
    </Route>
    <Route exact path='/products/management'>
      <PageProductsManagement />
    </Route>
    <Route exact path='/products/scrape'>
      <PageProductsScrape />
    </Route>
    {/* Requisitions */}
    <Route exact path='/requisitions/details/:uuid/'>
      <PageRequisitionDetails />
    </Route>
    <Route exact path='/requisitions/list'>
      <PageRequisitionList />
    </Route>
    {/* Suppliers */}
    <Route exact path='/suppliers/create'>
      <PageSuppliersCreate />
    </Route>
    <Route exact path='/suppliers/list'>
      <PageSuppliersList />
    </Route>
    <Route exact path='/suppliers/details/:id'>
      <PageSuppliersDetails />
    </Route>
    <Route exact path='/suppliers/update/:id'>
      <PageSuppliersUpdate />
    </Route>
    {/* Purchase Orders */}
    <Route exact path='/purchase-orders/list'>
      <PagePurchaseOrdersList />
    </Route>
    {/* Invoices */}
    <Route exact path='/purchase-orders/details/:id'>
      <PagePurchaseOrdersDetails />
    </Route>
    {/* Fulfillment Tracker */}
    <Route exact path='/fulfillment/tracker'>
      <PageFulfillmentTracker />
    </Route>
    {/* Returns */}
    <Route exact path='/returns/list'>
      <PageReturnsList />
    </Route>
    <Route exact path='/returns/details/:id'>
      <PageReturnDetails />
    </Route>
    <Route exact path='/returns/create/:id'>
      <PageReturnCreate />
    </Route>
    {/* Permission Management */}
    <Route exact path='/permission-groups/list'>
      <PagePermissionGroupsList />
    </Route>
    <Route exact path='/permission-groups/details/:id'>
      <PagePermissionGroupsDetails />
    </Route>
    <Route exact path='/permission-groups/update/:id'>
      <PagePermissionGroupsUpdate />
    </Route>
    {/* Integration Pages */}
    <Route exact path='/admin/integration/list'>
      <PageIntegrations />
    </Route>
    <Route exact path='/admin/integration/logs/hubspot'>
      <PageHubSpotLogs />
    </Route>
    <Route exact path='/admin/integration/logs/mainstem'>
      <PageMainStemLogs />
    </Route>
    {/* Affiliates Pages */}
    <Route exact path='/admin/affiliates/list'>
      <PageAffiliatesList />
    </Route>
    <Route exact path='/admin/affiliates/details/:id'>
      <PageAffiliatesDetails />
    </Route>
    {/* Attributes Pages */}
    <Route exact path='/admin/attributes/list'>
      <PageAttributeList />
    </Route>
    <Route exact path='/admin/attributes/details/:id'>
      <PageAttributesDetails />
    </Route>
    <Route exact path='/admin/attribute-values/details/:id'>
      <PageAttributeValuesDetails />
    </Route>
    {/* Customer Pricing Pages */}
    <Route exact path='/admin/customer-pricing/list'>
      <PageCustomerPricingList />
    </Route>
    {/* Product Tags Pages */}
    <Route exact path='/admin/tags/list'>
      <PageProductTagsList />
    </Route>
    <Route exact path='/admin/tags/details/:id'>
      <PageProductTagsDetails />
    </Route>
    {/* Permissions Pages */}
    <Route exact path='/admin/permissions/list'>
      <PagePermissionsList />
    </Route>
    {/* Infrastructure Pages */}
    <Route exact path='/infrastructure'>
      <PageInfrastructure />
    </Route>
    {/* Invoice Inquiry */}
    <Route exact path='/invoice-inquiry/details/:id'>
      <PageInvoiceInquiryDetails />
    </Route>
  </Switch>
)
