/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MainStemAPIControllersAdminAccountingDashboardInventoryValueAPIRequest {
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardInventoryValueAPIResponse {
  errorMessage?: string
  flattenedInventory?: MainStemAPIControllersAdminAccountingDashboardInventoryValueInventoryValueDetail[]
  inventory?: object[]
  valueByType?: Record<string, number>
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardInventoryValueInventoryValueDetail {
  /** @format int32 */
  amountAvailable?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  productID?: string
  productName?: string
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellWholesale?: number
  /** @format double */
  productRetailPrice?: number
  productSKU?: string
  productType?: string
  /** @format double */
  productWholesalePrice?: number
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersAdminAccountingDashboardInvoicesAPIRequest {
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardInvoicesAPIResponse {
  errorMessage?: string
  invoices?: object[]
  invoicesDetails?: MainStemAPIControllersAdminAccountingDashboardInvoicesInvoiceDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardInvoicesInvoiceDetail {
  locationID?: string
  locationName?: string
  /** @format double */
  discount?: number
  /** @format double */
  lineItemTotal?: number
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  fulfillmentMethodID?: number
  referenceNumber?: string
  /** @format double */
  salesTax?: number
  /** @format date-time */
  shipDate?: string
  /** @format double */
  shippingCost?: number
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersAdminAccountingDashboardMarkAsSettledAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemSharpModelsBaseResponse {
  message?: string
  wasSuccessful?: boolean
}

export type MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest = object

export interface MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIResponse {
  invoiceMissingTaxDocuments?: MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsOrderMissingTaxDocumentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsOrderMissingTaxDocumentDetail {
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  orderUUID?: string
  /** @format int32 */
  customerLocationID?: number
  customerLocationName?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardOrdersShippedAPIRequest {
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardOrdersShippedAPIResponse {
  errorMessage?: string
  ordersShipped?: object[]
  shipmentDetails?: MainStemAPIControllersAdminAccountingDashboardOrdersShippedShipmentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardOrdersShippedShipmentDetail {
  accountManager?: string
  /** @format int32 */
  businessPartnerID?: number
  businessPartnerName?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  orderID?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  totalCustomPackaging?: string
  totalEquipment?: string
  /** @format double */
  totalInvoice?: number
  totalLighting?: string
  totalMiscellaneous?: string
  totalNutrients?: string
  totalPackaging?: string
  totalRetailAccessories?: string
  totalService?: string
  totalStockPackaging?: string
  totalTraceability?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardPaymentsMadeAPIRequest {
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardPaymentsMadeAPIResponse {
  errorMessage?: string
  paymentsDetails?: MainStemAPIControllersAdminAccountingDashboardPaymentsMadePaymentDetail[]
  paymentsMade?: object[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardPaymentsMadePaymentDetail {
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  amount?: string
  /** @format int32 */
  businessPartnerID?: number
  businessPartnerName?: string
  cardType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  invoiceID?: number
  type?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardPostShipmentChangesAPIRequest {
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
}

export interface MainStemAPIControllersAdminAccountingDashboardPostShipmentChangesAPIResponse {
  errorMessage?: string
  postShipmentChanges?: object[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardUnsettledPaymentsAPIResponse {
  payments?: MainStemAPIControllersAdminAccountingDashboardUnsettledPaymentsAPIResponsePaymentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardUnsettledPaymentsAPIResponsePaymentDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemSharpHelpersLocationDetails
  orderID?: string
  orderUUID?: string
  status?: string
  type?: string
}

export interface MainStemSharpHelpersLocationDetails {
  /** @format int32 */
  id?: number
  uuid?: string
  name?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  country?: string
  zip?: string
  logoURL?: string
  headerURL?: string
  phone?: string
  email?: string
  /** @format date-time */
  dateCreated?: string
  allowCashPayments?: boolean
  allowTermPayments?: boolean
  /** @format double */
  storeCredits?: number
  terms?: MainStemSharpHelpersLocationDetailsTermsDetails
  shipTo?: MainStemSharpHelpersLocationDetailsAddressDetails
  billTo?: MainStemSharpHelpersLocationDetailsAddressDetails
  addresses?: MainStemSharpHelpersLocationDetailsAddressDetails[]
  tags?: string[]
}

export interface MainStemSharpHelpersLocationDetailsTermsDetails {
  /** @format double */
  available?: number
  /** @format double */
  balance?: number
  /** @format double */
  creditLimit?: number
  /** @format int32 */
  days?: number
  /** @format double */
  due?: number
  error?: string
}

export interface MainStemSharpHelpersLocationDetailsAddressDetails {
  name?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface MainStemSharpHelpersDefaultPermissionsRequest {
  /** @format int32 */
  customerID?: number
  userId?: string
  application?: 'Admin' | 'Purchasing' | 'Supplier' | 'Ecommerce'
}

export interface MainStemAPIControllersAdminLocationsNotificationEmailsAddAPIRequest {
  /** @format int32 */
  locationID?: number
  notificationType?: string
  notificationEmail?: string
}

export interface MainStemAPIControllersAdminAddressBookCreateAPIRequest {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationId?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminAddressBookDeleteAPIRequest {
  /** @format int32 */
  addressId?: number
}

export interface MainStemAPIControllersAdminAddressBookListAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersAdminAddressBookListAPIResponse {
  addresses?: MainStemAPIControllersAdminAddressBookListAPIResponseAddressDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAddressBookListAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersGlobalAddressBookUpdateAPIRequest {
  address1?: string
  address2?: string
  /** @format int32 */
  addressId?: number
  city?: string
  country?: string
  name?: string
  state?: string
  zip?: string
  shared?: boolean
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemSharpDataModelsAffiliateProgram {
  affiliate?: MainStemSharpDataModelsAffiliate
  /** @format int32 */
  affiliateID?: number
  appSettings?: string
  customCSS?: string
  customerPriceList?: MainStemSharpDataModelsCustomerPriceList
  /** @format int32 */
  customerPriceListID?: number
  domain?: string
  emailStylesSettings?: string
  favicon?: MainStemSharpDataModelsFile
  /** @format int32 */
  faviconFileID?: number
  freeShippingEnabled?: boolean
  /** @format double */
  freeShippingLimit?: number
  /** @format double */
  freeShippingPercent?: number
  /** @format int32 */
  id?: number
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  menu?: MainStemSharpDataModelsMenu
  /** @format int32 */
  menuID?: number
  name?: string
  type?: string
  uiVersion?: string
  usesAPI?: boolean
  usesPriceList?: boolean
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemSharpDataModelsBusinessPartner {
  accountManagers?: MainStemSharpDataModelsApplicationUser[]
  address1?: string
  address2?: string
  /** @format double */
  advertisingBudgetDaily?: number
  /** @format double */
  advertisingBudgetMonthly?: number
  allowCashPayments?: boolean
  allowDelivery?: boolean
  allowFullIntegrationSync?: boolean
  allowPayQwickPayments?: boolean
  anonymousDataOptOut?: boolean
  approvalRuleset?: 'Tier' | 'Workflow'
  approvalWorkflows?: MainStemSharpDataModelsLocationApprovalWorkflow[]
  authorizeNetCustomerID?: string
  avalaraID?: string
  billToAddress1?: string
  billToAddress2?: string
  billToAddressIsResidential?: boolean
  billToCity?: string
  billToCountry?: string
  billToName?: string
  billToState?: string
  billToZip?: string
  budgets?: MainStemSharpDataModelsLocationBudget[]
  businessPartnerGroups?: MainStemSharpDataModelsBusinessPartnerGroup[]
  businessPartnerIntegrationSettings?: MainStemSharpDataModelsIntegrationSetting[]
  canBuy?: boolean
  canSell?: boolean
  city?: string
  contacts?: MainStemSharpDataModelsContact[]
  country?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  daysToFulfill?: number
  defaultAddressIsResidential?: boolean
  defaultAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  defaultAffiliateProgramID?: number
  defaultBillToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultBillToAddressID?: number
  defaultPhysicalAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultPhysicalAddressID?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  defaultShipToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultShipToAddressID?: number
  departments?: MainStemSharpDataModelsDepartment[]
  description?: string
  doNotCall?: boolean
  email?: string
  enableSendInvoiceEmails?: boolean
  enableSendSupplierOrderEmails?: boolean
  federalBusinessNumber?: string
  /** @format double */
  freeShippingLimit?: number
  /** @format double */
  freeShippingPercent?: number
  freightContactEmail?: string
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactPhone?: string
  freightLiftGateRequired?: boolean
  freightNotes?: string
  freightShippingRequired?: boolean
  glCode?: string
  hasBandedShipping?: boolean
  headerImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  headerImageID?: number
  hubSpot_CompanyID?: string
  /** @format int32 */
  id?: number
  integrationAPIKey?: string
  integrationSettings?: string
  integrationType?: string
  integrationUrl?: string
  isBrand?: boolean
  isDeleted?: boolean
  /** @format double */
  latitude?: number
  localBusinessNumber?: string
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  logoPDF?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoPDFID?: number
  /** @format double */
  longitude?: number
  manualOrderAcceptanceRequired?: boolean
  mapIcon?: MainStemSharpDataModelsFile
  /** @format int32 */
  mapIconID?: number
  /** @format double */
  minimumOrderAmount?: number
  name?: string
  news?: MainStemSharpDataModelsBusinessPartnerNews[]
  notificationEmailNewOrder?: string
  notificationEmailReviewRequest?: string
  notificationEmails?: string
  notificationEmailShipmentUpdate?: string
  parentBusinessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  parentBusinessPartnerID?: number
  paymentAgreement?: 'DueUponReceipt' | 'PrePay' | 'Net15' | 'Net30' | 'Net45' | 'Net30_110' | 'Net30_210'
  payQwickEid?: string
  phone?: string
  phoneExtension?: string
  previousPriorLeadStatus?: string
  priorLeadStatus?: string
  privateSupplierDomain?: string
  privateSupplierOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateSupplierOrganizationID?: number
  productFinancialDimensions?: MainStemSharpDataModelsLocationProductFinancialDimension[]
  quickbooks_ClientID?: string
  quickbooks_ClientSecret?: string
  quickBooksID?: string
  quickBooksVendorID?: string
  refundPolicy?: string
  requiresManualProcessing?: boolean
  requiresPrePayment?: boolean
  resellerPermit?: MainStemSharpDataModelsFile
  /** @format date-time */
  resellerPermitExpires?: string
  /** @format int32 */
  resellerPermitID?: number
  /** @format date-time */
  resellerPermitIssued?: string
  rfqCategories?: MainStemSharpDataModelsRFQCategory[]
  sage_DepartmentId?: string
  sage_LocationId?: string
  sage_TransactionType?: string
  sage_TransactionTypeApproval?: string
  sage_TransactionTypeInvoice?: string
  salesPersonID?: string
  sendInvoiceEmailsAddresses?: string
  sendSupplierNotificationAcceptance?: boolean
  sendSupplierNotificationShipmentTracking?: boolean
  sendSupplierOrderEmailsAddresses?: string
  shipToAddress1?: string
  shipToAddress2?: string
  shipToAddressIsResidential?: boolean
  shipToAppointmentRequired?: boolean
  shipToCity?: string
  shipToCountry?: string
  shipToDriverAssistanceRequired?: boolean
  shipToEquipmentLength?: string
  shipToEquipmentType?: string
  shipToForkliftOnSite?: boolean
  shipToGatedFacility?: boolean
  shipToInsideDelivery?: boolean
  shipToName?: string
  shipToState?: string
  shipToZip?: string
  shortCodeIdentifier?: string
  snowflake_AccountId?: string
  snowflake_Password?: string
  snowflake_Username?: string
  socialMediaFacebook?: string
  socialMediaInstagram?: string
  socialMediaLinkedIn?: string
  socialMediaTwitter?: string
  socialMediaYouTube?: string
  squareFootage?: string
  state?: string
  supplierFreightShippingAccountNumber?: string
  supplierFreightShippingCarrier?: string
  /** @format double */
  supplierFreightShippingFreeMinimum?: number
  supplierParcelShippingAccountNumber?: string
  supplierParcelShippingCarrier?: string
  /** @format double */
  supplierParcelShippingFreeMinimum?: number
  supplierPaymentTerms?: string
  /** @format double */
  supplierPaymentTermsCreditLimit?: number
  /** @format int32 */
  supplierPaymentTermsDays?: number
  /** @format int32 */
  supplierPaymentTermsDiscountDays?: number
  /** @format double */
  supplierPaymentTermsDiscountPercent?: number
  /** @format double */
  supplierPaymentTermsPercent?: number
  /** @format double */
  supplierPaymentTermsPrePaymentPercent?: number
  /** @format int32 */
  supplierShippingFreightWeightLimit?: number
  /** @format int32 */
  supplierShippingMaxBundleWeight?: number
  taxExempt?: boolean
  tech?: MainStemSharpDataModelsCustomerTech[]
  termsAndConditions?: string
  tier?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
  viewWholesalePricing?: boolean
  website?: string
  zip?: string
}

export interface MainStemSharpDataModelsAffiliate {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsCustomerPriceList {
  affiliatePrograms?: MainStemSharpDataModelsAffiliateProgram[]
  customerPriceListLineItems?: MainStemSharpDataModelsCustomerPriceListLineItem[]
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsFile {
  altText?: string
  cloudFileType?: 'csv' | 'txt' | 'html' | 'png' | 'jpg' | 'gif' | 'json' | 'pdf' | 'xls' | 'pbix' | 'jfif' | 'jpeg'
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  documentID?: number
  documentIDString?: string
  documentType?: string
  fileExtension?: string
  fileLocation?: string
  fileName?: string
  /** @format int64 */
  fileSize?: number
  /** @format int32 */
  id?: number
  originalFileName?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  userIDCreatedBy?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemSharpDataModelsMenu {
  algolia_APIKeySearch?: string
  algolia_AppId?: string
  algolia_IndexName?: string
  algolia_IndexNameSorted?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsApplicationUser {
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  dashboards?: MainStemSharpDataModelsDashboard[]
  /** @format date-time */
  dateCreated?: string
  defaultCatalog?: MainStemSharpDataModelsProductList
  /** @format int32 */
  defaultCatalogID?: number
  departments?: MainStemSharpDataModelsDepartment[]
  friendlyName?: string
  isDeleted?: boolean
  locationApprovalWorkflows?: MainStemSharpDataModelsLocationApprovalWorkflow[]
  /** @format date-time */
  passwordResetExpires?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  passwordResetUUID?: string
  permissionGroup?: MainStemSharpDataModelsPermissionGroup
  /** @format int32 */
  permissionGroupID?: number
  permissions?: MainStemSharpDataModelsUserPermissions[]
  profileImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  profileImageID?: number
  quickBooksID?: string
  sage_EmployeeId?: string
  sideKickEnabled?: boolean
  sideKickTermsAccepted?: boolean
  title?: string
  type?: string
  email?: string
  emailConfirmed?: boolean
  passwordHash?: string
  securityStamp?: string
  phoneNumber?: string
  phoneNumberConfirmed?: boolean
  twoFactorEnabled?: boolean
  /** @format date-time */
  lockoutEndDateUtc?: string
  lockoutEnabled?: boolean
  /** @format int32 */
  accessFailedCount?: number
  roles?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserRole[]
  claims?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserClaim[]
  logins?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserLogin[]
  id?: string
  userName?: string
}

export interface MainStemSharpDataModelsLocationApprovalWorkflow {
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  customerLocation?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  customerLocationID?: number
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  /** @format int32 */
  numberOfRequiredApprovers?: number
  users?: MainStemSharpDataModelsApplicationUser[]
}

export interface MainStemSharpDataModelsLocationBudget {
  /** @format double */
  budget?: number
  /** @format int32 */
  id?: number
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemSharpDataModelsBusinessPartnerGroup {
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  type?: 'None' | 'LocationType'
}

export interface MainStemSharpDataModelsIntegrationSetting {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
  integrationType?: string
  settings?: string
  status?: string
}

export interface MainStemSharpDataModelsContact {
  altEmail?: string
  altPhone?: string
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  email?: string
  firstName?: string
  /** @format int32 */
  id?: number
  lastName?: string
  nickName?: string
  phone?: string
  phoneExtension?: string
  title?: string
}

export interface MainStemSharpDataModelsBusinessPartnerAddress {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  isResidential?: boolean
  /** @format double */
  latitude?: number
  /** @format double */
  longitude?: number
  name?: string
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  shared?: boolean
  state?: string
  zip?: string
}

export interface MainStemSharpDataModelsDepartment {
  customerLocation?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  customerLocationID?: number
  /** @format int32 */
  id?: number
  name?: string
  users?: MainStemSharpDataModelsApplicationUser[]
  isDeleted?: boolean
}

export interface MainStemSharpDataModelsBusinessPartnerNews {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  content?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  title?: string
  url?: string
}

export interface MainStemSharpDataModelsOrganization {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  allowCreditCards?: boolean
  allowOrderRecommendations?: boolean
  allowPunchOutInMainNav?: boolean
  allowTermPayments?: boolean
  customFields?: MainStemSharpDataModelsCustomerCustomField[]
  dataPackageEnabled?: boolean
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateGoLive?: string
  /** @format date-time */
  dateTrialExpires?: string
  email?: string
  filePDFLogo?: MainStemSharpDataModelsFile
  /** @format int32 */
  filePDFLogoID?: number
  headerInformation?: string
  hubSpot_CompanyID?: string
  /** @format int32 */
  id?: number
  internalTeamsChannelId?: string
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  /** @format int32 */
  maxNumberOfLocations?: number
  /** @format int32 */
  maxNumberOfUsers?: number
  /** @format double */
  monthlySpendBaseline?: number
  /** @format double */
  monthlySpendCapturable?: number
  /** @format double */
  monthlySpendTotal?: number
  name?: string
  payQwick_AccountId?: string
  phone?: string
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  privateSupplierNewOrderEmailNotificationCC?: string
  privateSupplierNewOrderEmailNotificationTemplate?: string
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format double */
  saaSFee?: number
  saaSFeeEnabled?: boolean
  sideKickEnabled?: boolean
  sideKickIndex?: string
  sideKickPrivateIndexEnabled?: boolean
  sideKickURL?: string
  slack_Channel_InvoiceShipped?: string
  slack_Channel_OrderApproved?: string
  slack_Channel_OrderSubmittedForApproval?: string
  slack_Channel_PaymentProcessed?: string
  snowflake_AccountId?: string
  snowflake_Password?: string
  snowflake_Username?: string
  /** @format int32 */
  termDays?: number
  termsAndConditions?: string
  /** @format double */
  termsCreditLimit?: number
  titleInvoice?: string
  titlePurchaseOrder?: string
  titleRequisition?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
  zohoCompanyID?: string
}

export interface MainStemSharpDataModelsLocationProductFinancialDimension {
  financialDimension?: MainStemSharpDataModelsFinancialDimension
  /** @format int32 */
  financialDimensionID: number
  /** @format int32 */
  id?: number
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemSharpDataModelsRFQCategory {
  /** @format int32 */
  id?: number
  name?: string
  rfQs?: MainStemSharpDataModelsRFQ[]
  suppliers?: MainStemSharpDataModelsBusinessPartner[]
}

export interface MainStemSharpDataModelsCustomerTech {
  customerLocations?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  id?: number
  logo?: string
  name?: string
  type?: string
}

export interface MainStemSharpDataModelsCustomerPriceListLineItem {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  customerPriceList?: MainStemSharpDataModelsCustomerPriceList
  /** @format int32 */
  customerPriceListID?: number
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
}

export interface MainStemSharpDataModelsOrderApprovals {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  approvalWorkflow?: MainStemSharpDataModelsLocationApprovalWorkflow
  /** @format int32 */
  approvalWorkflowID?: number
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  /** @format int32 */
  id?: number
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  sequence?: number
  userDecidedBy?: MainStemSharpDataModelsApplicationUser
  userIDDecidedBy?: string
}

export interface MainStemSharpDataModelsDashboard {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  order?: number
  rows?: MainStemSharpDataModelsDashboardRow[]
  user?: MainStemSharpDataModelsApplicationUser
  userID?: string
}

export interface MainStemSharpDataModelsProductList {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
  productListItems?: MainStemSharpDataModelsProductListItem[]
  productListUsers?: MainStemSharpDataModelsProductListUser[]
}

export interface MainStemSharpDataModelsPermissionGroup {
  /** @format int32 */
  id?: number
  name?: string
  organization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  organizationID?: number
}

export interface MainStemSharpDataModelsUserPermissions {
  /** @format int32 */
  id?: number
  permission?: MainStemSharpDataModelsPermission
  /** @format int32 */
  permissionID?: number
  user?: MainStemSharpDataModelsApplicationUser
  userID?: string
  value?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserRole {
  userId?: string
  roleId?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserClaim {
  /** @format int32 */
  id?: number
  userId?: string
  claimType?: string
  claimValue?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserLogin {
  loginProvider?: string
  providerKey?: string
  userId?: string
}

export interface MainStemSharpDataModelsProduct {
  advertisingBids?: MainStemSharpDataModelsAdvertisingBid[]
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  attributeValues?: MainStemSharpDataModelsAttributeValue[]
  brand?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  brandID?: number
  brandNew?: MainStemSharpDataModelsBrand
  /** @format int32 */
  brandNewID?: number
  collectionClassifications?: MainStemSharpDataModelsCollectionClassification[]
  /** @format double */
  customerRetailPrice?: number
  customsDescription?: string
  customsHarmonizedTariffCodes?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedBackInStock?: string
  /** @format date-time */
  dateLastUpdated?: string
  /** @format date-time */
  datePriceLastUpdated?: string
  /** @format int32 */
  daysToFulfill?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  distributorID?: string
  dropShipBusinessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  dropShipBusinessPartnerID?: number
  dropShippedItem?: boolean
  externalId?: string
  externalSource?: string
  /** @format double */
  flatShippingCost?: number
  freeShipping?: boolean
  freightShippingRequired?: boolean
  hasMaximumPurchaseRequirement?: boolean
  hasMinimumPurchaseRequirement?: boolean
  hasVolumePricing?: boolean
  /** @format double */
  hazmatFee?: number
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  isApproved?: boolean
  isClean?: boolean
  isCustomizable?: boolean
  isCustomProduct?: boolean
  isDeleted?: boolean
  isNonCancellable?: boolean
  isNonReturnable?: boolean
  isOutOfStock?: boolean
  isPunchOutGenerated?: boolean
  isRFQGenerated?: boolean
  isShippable?: boolean
  /** @format double */
  length?: number
  mainImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  mainImageID?: number
  manufacturer?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  manufacturerBusinessPartnerID?: number
  manufacturerNew?: MainStemSharpDataModelsManufacturer
  /** @format int32 */
  manufacturerNewID?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  menuItems?: MainStemSharpDataModelsMenuItem[]
  /** @format int32 */
  minimumPurchasableQuantity?: number
  name?: string
  parentProduct?: MainStemSharpDataModelsProduct
  /** @format int32 */
  parentProductID?: number
  parentProductNew?: MainStemSharpDataModelsParentProduct
  /** @format int32 */
  parentProductNewID?: number
  potentialImages?: string[]
  potentialImagesAsString?: string
  /** @format double */
  priceMSRP?: number
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  privateProductOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateProductOrganizationID?: number
  productGroups?: MainStemSharpDataModelsProductGroup[]
  productImages?: MainStemSharpDataModelsFile[]
  quickBooksID?: string
  showInStore?: boolean
  skuInternal?: string
  skuManufacturer?: string
  smallText?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  supplierPartNumber?: string
  taxCode?: string
  taxExempt?: boolean
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  unspscClass?: MainStemSharpDataModelsUNSPSCClass
  /** @format int64 */
  unspscClassID?: number
  unspscCommodity?: MainStemSharpDataModelsUNSPSCCommodity
  /** @format int64 */
  unspscCommodityID?: number
  unspscFamily?: MainStemSharpDataModelsUNSPSCFamily
  /** @format int64 */
  unspscFamilyID?: number
  unspscSegment?: MainStemSharpDataModelsUNSPSCSegment
  /** @format int64 */
  unspscSegmentID?: number
  variantID?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemSharpDataModelsCustomerCustomField {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  dataModel?:
    | 'User'
    | 'Location'
    | 'Supplier'
    | 'Order'
    | 'Invoice'
    | 'Shipment'
    | 'Product'
    | 'Department'
    | 'OrderSupplier'
  fieldType?: 'ShortText' | 'LongText' | 'Number' | 'Date' | 'Boolean' | 'Currency'
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  name?: string
  showOnPDF?: boolean
  values?: MainStemSharpDataModelsCustomerCustomFieldValue[]
}

export interface MainStemSharpDataModelsFinancialDimension {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  label?: string
  /** @format int32 */
  rankOrder?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
}

export interface MainStemSharpDataModelsRFQ {
  addressBillTo?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  addressBillToID?: number
  addressShipTo?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  addressShipToID?: number
  categories?: MainStemSharpDataModelsRFQCategory[]
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDeliveryDueBy?: string
  /** @format date-time */
  dateDueBy?: string
  /** @format date-time */
  dateInitiated?: string
  /** @format date-time */
  dateRFQAwardActual?: string
  /** @format date-time */
  dateRFQAwardExpected?: string
  description?: string
  /** @format int32 */
  id?: number
  isPublic?: boolean
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID?: number
  preferredPaymentMethods?: string[]
  preferredPaymentMethodsAsString?: string
  rfqStatus?: string
  status?: 'Cancelled' | 'Draft' | 'InProgress' | 'Awarded' | 'PartiallyAwarded' | 'Ordered' | 'Finalized'
  title?: string
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedByID?: string
  userFinalizedBy?: MainStemSharpDataModelsApplicationUser
  userFinalizedByID?: string
  userInitiatedBy?: MainStemSharpDataModelsApplicationUser
  userIntiatedByID?: string
  userInitiatedByID?: string
  wantsHelp?: boolean
}

export interface MainStemSharpDataModelsOrder {
  /** @format double */
  actualShippingCost?: number
  affiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  affiliateProgramID?: number
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  billToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  billToAddress1?: string
  billToAddress2?: string
  /** @format int32 */
  billToAddressID?: number
  billToCity?: string
  billToCountry?: string
  billToName?: string
  billToState?: string
  billToZip?: string
  customerAccountSizeAtTimeOfApproval?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  customerGLCode?: string
  /** @format int32 */
  customerIntegrationSessionID?: number
  customerPONumber?: string
  customerPricingTypeAtTimeOfApproval?: 'Retail' | 'Wholesale' | 'Enterprise'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateOfLastAction?: string
  /** @format date-time */
  datePickedAndPacked?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShipped?: string
  /** @format date-time */
  dateSubmittedForApproval?: string
  /** @format double */
  discount?: number
  email?: string
  /** @format int32 */
  id?: number
  invoices?: MainStemSharpDataModelsFulfillmentMethod[]
  isDeleted?: boolean
  needsElf?: boolean
  orderGeneratedBy?: string
  orderStatus?:
    | 'Open'
    | 'Approved'
    | 'CantFulfill'
    | 'Cancelled'
    | 'Return'
    | 'Fulfillment'
    | 'Shipping'
    | 'Closed'
    | 'Complete'
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  parentOrder?: MainStemSharpDataModelsOrder
  /** @format int32 */
  parentOrderID?: number
  paymentStatus?: string
  phase?: 'Requisition' | 'Submission' | 'Approval' | 'Processing' | 'Shipping' | 'Completed' | 'Cancelled' | 'Rejected'
  phone?: string
  purchaseOrders?: MainStemSharpDataModelsPurchaseOrder[]
  quickBooksID?: string
  requestedShippingType?: string
  requisitionStatus?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  sage_OrderID?: string
  sage_RequisitionID?: string
  salesPersonID?: string
  /** @format double */
  salesTax?: number
  /** @format date-time */
  salesTaxAsOfDate?: string
  savedPaymentMethod?: string
  /** @format double */
  shippingCost?: number
  shippingMethod?: string
  shippingNote?: string
  shipToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  shipToAddress1?: string
  shipToAddress2?: string
  /** @format int32 */
  shipToAddressID?: number
  shipToCity?: string
  shipToCountry?: string
  shipToName?: string
  shipToState?: string
  shipToZip?: string
  status?: string
  suppliers?: MainStemSharpDataModelsOrderSupplier[]
  /** @format int32 */
  termLedgerID?: number
  type?: string
  userApprovedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userIDApprovedBy?: string
  userIDCreatedBy?: string
  userIDSubmittedForApproval?: string
  userSubmittedForApproval?: MainStemSharpDataModelsApplicationUser
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemSharpDataModelsDashboardRow {
  dashboard?: MainStemSharpDataModelsDashboard
  /** @format int32 */
  dashboardID?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  order?: number
  panels?: MainStemSharpDataModelsDashboardPanel[]
}

export interface MainStemSharpDataModelsProductListItem {
  /** @format int32 */
  id?: number
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
  productList?: MainStemSharpDataModelsProductList
  /** @format int32 */
  productListID?: number
}

export interface MainStemSharpDataModelsProductListUser {
  /** @format int32 */
  id?: number
  productList?: MainStemSharpDataModelsProductList
  /** @format int32 */
  productListID?: number
  user?: MainStemSharpDataModelsApplicationUser
  userID?: string
}

export interface MainStemSharpDataModelsPermission {
  application?: 'Admin' | 'Purchasing' | 'Supplier' | 'Ecommerce'
  applicationAppliedTo?: string
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  rank?: number
  shortCode?: string
  isDeleted?: boolean
}

export interface MainStemSharpDataModelsAdvertisingBid {
  /** @format double */
  bidAsHighAs?: number
  /** @format double */
  bidAsLowAs?: number
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  id?: number
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
}

export interface MainStemSharpDataModelsAttributeValue {
  attribute?: MainStemSharpDataModelsAttribute
  /** @format int32 */
  attributeID?: number
  /** @format int32 */
  id?: number
  products?: MainStemSharpDataModelsProduct[]
  value?: string
}

export interface MainStemSharpDataModelsBrand {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  website?: string
}

export interface MainStemSharpDataModelsCollectionClassification {
  collectionSubCategory?: MainStemSharpDataModelsCollectionSubCategory
  /** @format int32 */
  collectionSubCategoryID?: number
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  oldMenuId?: number
  parentProducts?: MainStemSharpDataModelsParentProduct[]
}

export interface MainStemSharpDataModelsManufacturer {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  website?: string
}

export interface MainStemSharpDataModelsMenuItem {
  amazonFeedProductType?: string
  configurators?: MainStemSharpDataModelsConfigurator[]
  description?: string
  externalURL?: string
  icon?: string
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageFileID?: number
  isActive?: boolean
  isQuickHit?: boolean
  menu?: MainStemSharpDataModelsMenu
  /** @format int32 */
  menuID?: number
  metaDescription?: string
  metaKeywords?: string
  name?: string
  /** @format int32 */
  order?: number
  parentMenuItem?: MainStemSharpDataModelsMenuItem
  /** @format int32 */
  parentMenuItemID?: number
  position?: string
  privateMenuItemOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateMenuItemOrganizationID?: number
  products?: MainStemSharpDataModelsProduct[]
  quickHitName?: string
  seoH1?: string
  seoSlug?: string
  titleTags?: string
}

export interface MainStemSharpDataModelsParentProduct {
  children?: MainStemSharpDataModelsProduct[]
  classifications?: MainStemSharpDataModelsCollectionClassification[]
  description?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  mainImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  mainImageID?: number
  name?: string
  /** @format int32 */
  oldProductID?: number
  parentProductType?: MainStemSharpDataModelsParentProductType
  /** @format int32 */
  parentProductTypeID?: number
  showInStore?: boolean
  type?: string
}

export interface MainStemSharpDataModelsProductGroup {
  /** @format int32 */
  id?: number
  name?: string
  parentProductGroup?: MainStemSharpDataModelsProductGroup
  /** @format int32 */
  parentProductGroupID?: number
  products?: MainStemSharpDataModelsProduct[]
}

export interface MainStemSharpDataModelsUNSPSCClass {
  /** @format int64 */
  code?: number
  commodities?: MainStemSharpDataModelsUNSPSCCommodity[]
  definition?: string
  family?: MainStemSharpDataModelsUNSPSCFamily
  /** @format int64 */
  familyID?: number
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCCommodity {
  class?: MainStemSharpDataModelsUNSPSCClass
  /** @format int64 */
  classID?: number
  /** @format int64 */
  code?: number
  definition?: string
  products?: MainStemSharpDataModelsProduct[]
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCFamily {
  classes?: MainStemSharpDataModelsUNSPSCClass[]
  /** @format int64 */
  code?: number
  definition?: string
  segment?: MainStemSharpDataModelsUNSPSCSegment
  /** @format int64 */
  segmentID?: number
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCSegment {
  /** @format int64 */
  code?: number
  definition?: string
  families?: MainStemSharpDataModelsUNSPSCFamily[]
  title?: string
}

export interface MainStemSharpDataModelsCustomerCustomFieldValue {
  customField?: MainStemSharpDataModelsCustomerCustomField
  /** @format int32 */
  customFieldID?: number
  /** @format int32 */
  documentID?: number
  documentIDString?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  value?: string
}

export interface MainStemSharpDataModelsFulfillmentMethod {
  acceptanceStatus?: 'Cancelled' | 'Pending' | 'Accepted' | 'Rejected'
  acknowledgedBy?: string
  acknowledgedByUser?: MainStemSharpDataModelsApplicationUser
  acknowledgedByUserId?: string
  acknowledgementType?: 'MainStemPixel' | 'SupplierCoreOrderDetails' | 'Integration'
  avalaraID?: string
  avalaraTaxBreakDown?: AvalaraAvaTaxRestClientTransactionSummary[]
  /** @format int32 */
  customerIntegrationSessionID?: number
  customerQuickBooksBillID?: string
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateDeliveryRangeEnd?: string
  /** @format date-time */
  dateDeliveryRangeStart?: string
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  datePickedOn?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateRejected?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  dropShipUniqueID?: string
  dropShipVendor?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  dropShipVendorID?: number
  /** @format double */
  fees?: number
  fulfillmentMethodLineItems?: MainStemSharpDataModelsFulfillmentMethodLineItem[]
  hasProblem?: boolean
  /** @format int32 */
  id?: number
  integrationType?: string
  isFreightShipping?: boolean
  notes?: string
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  paymentProcessingFee?: number
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  quickBooksID?: string
  quickBooksInvoiceState?: 'Open' | 'Partial' | 'Closed' | 'Ignore'
  quickBooksItemReceiptID?: string
  quickBooksPaymentIDs?: string
  quickBooksPaymentLinks?: string[]
  quickBooksPurchaseOrderID?: string
  sage_InvoiceID?: string
  /** @format double */
  salesTax?: number
  shipmentType?: string
  /** @format double */
  shippingCost?: number
  subType?: 'Shipment' | 'WillCall' | 'Delivery' | 'Freight'
  /** @format double */
  supplierSalesTax?: number
  /** @format double */
  supplierShippingCost?: number
  taxBreakdownJSON?: string
  type?: 'DirectToCustomer' | 'DropShip' | 'BackOrder'
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemSharpDataModelsPurchaseOrder {
  /** @format int32 */
  customerIntegrationSessionID?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReleased?: string
  /** @format date-time */
  dateSalesTaxCalculated?: string
  /** @format date-time */
  dateShippingCalculated?: string
  discounts?: MainStemSharpDataModelsPurchaseOrderDiscount[]
  fees?: MainStemSharpDataModelsPurchaseOrderFee[]
  freightCollect?: boolean
  fulfillmentMethods?: MainStemSharpDataModelsFulfillmentMethod[]
  /** @format int32 */
  id?: number
  lineItems?: MainStemSharpDataModelsPurchaseOrderLineItem[]
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  salesTaxAmount?: number
  salesTaxDescription?: string
  /** @format double */
  shippingAmount?: number
  shippingCarrier?: string
  shippingDescription?: string
  shippingMethod?: string
  status?: 'Draft' | 'Released' | 'Cancelled'
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemSharpDataModelsOrderSupplier {
  /** @format int32 */
  id?: number
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
}

export interface MainStemSharpDataModelsDashboardPanel {
  dashboardRow?: MainStemSharpDataModelsDashboardRow
  /** @format int32 */
  dashboardRowID?: number
  /** @format int32 */
  id?: number
  widgets?: MainStemSharpDataModelsDashboardPanelWidgets[]
  /** @format int32 */
  width?: number
}

export interface MainStemSharpDataModelsAttribute {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsCollectionSubCategory {
  classifications?: MainStemSharpDataModelsCollectionClassification[]
  collectionCategory?: MainStemSharpDataModelsCollectionCategory
  /** @format int32 */
  collectionCategoryID?: number
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsConfigurator {
  attribute?: MainStemSharpDataModelsAttribute
  /** @format int32 */
  attributeID?: number
  configuratorOptions?: MainStemSharpDataModelsConfiguratorOption[]
  /** @format int32 */
  id?: number
  menuItem?: MainStemSharpDataModelsMenuItem
  /** @format int32 */
  menuItemID?: number
  /** @format int32 */
  order?: number
  type?: 'checkbox' | 'radio' | 'select' | 'image' | 'range'
}

export interface MainStemSharpDataModelsParentProductType {
  /** @format int32 */
  id?: number
  name?: string
  parentProducts?: MainStemSharpDataModelsParentProduct[]
}

export interface AvalaraAvaTaxRestClientTransactionSummary {
  country?: string
  region?: string
  jurisType?: 'Country' | 'State' | 'County' | 'City' | 'Special'
  jurisCode?: string
  jurisName?: string
  /** @format int32 */
  taxAuthorityType?: number
  stateAssignedNo?: string
  taxType?: string
  taxSubType?: string
  taxName?: string
  taxGroup?: string
  rateType?:
    | 'ReducedA'
    | 'ReducedB'
    | 'Food'
    | 'General'
    | 'IncreasedStandard'
    | 'LinenRental'
    | 'Medical'
    | 'Construction'
    | 'Parking'
    | 'SuperReduced'
    | 'ReducedR'
    | 'Standard'
    | 'Leasing'
    | 'LeasingLow'
    | 'Services'
    | 'Zero'
  rateTypeCode?: string
  /** @format double */
  taxable?: number
  /** @format double */
  rate?: number
  /** @format double */
  tax?: number
  /** @format double */
  taxCalculated?: number
  /** @format double */
  nonTaxable?: number
  /** @format double */
  exemption?: number
}

export interface MainStemSharpDataModelsFulfillmentMethodLineItem {
  allocations?: MainStemSharpDataModelsInvoiceLineItemAllocation[]
  fulfillmentMethod?: MainStemSharpDataModelsFulfillmentMethod
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  id?: number
  orderLineItem?: MainStemSharpDataModelsOrderLineItem
  /** @format int32 */
  orderLineItemID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  supplierPricePerItemActual?: number
  /** @format double */
  supplierPricePerItemExpected?: number
  /** @format double */
  taxAmount?: number
  taxJarJSON?: string
  /** @format double */
  taxRate?: number
}

export interface MainStemSharpDataModelsPurchaseOrderDiscount {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  notes?: string
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  reason?: string
}

export interface MainStemSharpDataModelsPurchaseOrderFee {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  notes?: string
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  reason?: string
}

export interface MainStemSharpDataModelsPurchaseOrderLineItem {
  /** @format double */
  costPerItem?: number
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discountsPerItem?: number
  /** @format double */
  feesPerItem?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTaxPerItem?: number
  /** @format double */
  shippingPerItem?: number
}

export interface MainStemSharpDataModelsDashboardPanelWidgets {
  dashboardPanel?: MainStemSharpDataModelsDashboardPanel
  /** @format int32 */
  dashboardPanelID?: number
  dashboardWidget?: MainStemSharpDataModelsDashboardWidget
  /** @format int32 */
  dashboardWidgetID?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  order?: number
}

export interface MainStemSharpDataModelsCollectionCategory {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageFileID?: number
  name?: string
  subCategories?: MainStemSharpDataModelsCollectionSubCategory[]
}

export interface MainStemSharpDataModelsConfiguratorOption {
  attributeValue?: MainStemSharpDataModelsAttributeValue
  /** @format int32 */
  attributeValueID?: number
  configurator?: MainStemSharpDataModelsConfigurator
  /** @format int32 */
  configuratorID?: number
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageID?: number
  name?: string
  /** @format int32 */
  order?: number
  value?: string
}

export interface MainStemSharpDataModelsInvoiceLineItemAllocation {
  /** @format date-time */
  dateCreated?: string
  department?: MainStemSharpDataModelsDepartment
  /** @format int32 */
  departmentID?: number
  /** @format int32 */
  id?: number
  invoiceLineItem?: MainStemSharpDataModelsFulfillmentMethodLineItem
  /** @format int32 */
  invoiceLineItemID?: number
  isDeleted?: boolean
  /** @format int32 */
  quantity?: number
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedByID?: string
}

export interface MainStemSharpDataModelsOrderLineItem {
  /** @format double */
  discountPerItem?: number
  distributorProductID?: string
  /** @format int32 */
  id?: number
  note?: string
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  pricePerItem?: number
  pricePerItemReason?: string
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
  /** @format int32 */
  quantity?: number
  rfqBidLineItem?: MainStemSharpDataModelsRFQBidLineItem
  /** @format int32 */
  rfqBidLineItemID?: number
  /** @format double */
  supplierPricePerItem?: number
}

export interface MainStemSharpDataModelsDashboardWidget {
  description?: string
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageID?: number
  name?: string
  panels?: MainStemSharpDataModelsDashboardPanelWidgets[]
  type?: 'Marketplace' | 'Finance' | 'Approvals' | 'Organization' | 'Procurement' | 'Shipments'
}

export interface MainStemSharpDataModelsRFQBidLineItem {
  awardStatus?: 'Lost' | 'Undecided' | 'Won' | 'Withdrawn' | 'PartialWin' | 'PartialLoss' | 'PartialDecisionsMade'
  bidAwardStatus?: string
  /** @format double */
  costSalesTax?: number
  /** @format double */
  costShipping?: number
  generatedProduct?: MainStemSharpDataModelsProduct
  /** @format int32 */
  generatedProductID?: number
  /** @format int32 */
  id?: number
  isFreight?: boolean
  /** @format int32 */
  leadTimeInDays?: number
  lineType?: string
  name?: string
  note?: string
  priceOption?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  rfqBid?: MainStemSharpDataModelsRFQBid
  /** @format int32 */
  rfqBidID?: number
  rfqLineItem?: MainStemSharpDataModelsRFQLineItem
  /** @format int32 */
  rfqLineItemID?: number
  sku?: string
}

export interface MainStemSharpDataModelsRFQBid {
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  awardStatus?: 'Lost' | 'Undecided' | 'Won' | 'Withdrawn' | 'PartialWin' | 'PartialLoss' | 'PartialDecisionsMade'
  bidAwardStatus?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedDelivery?: string
  generatedOrder?: MainStemSharpDataModelsOrder
  /** @format int32 */
  generatedOrderID?: number
  /** @format int32 */
  id?: number
  lineItems?: MainStemSharpDataModelsRFQBidLineItem[]
  preferredPaymentMethods?: string[]
  preferredPaymentMethodsAsString?: string
  prefp?: string
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  rfqSupplier?: MainStemSharpDataModelsRFQSupplier
  /** @format int32 */
  rfqSupplierID?: number
}

export interface MainStemSharpDataModelsRFQLineItem {
  awardedBid?: MainStemSharpDataModelsRFQBid
  /** @format int32 */
  awardedBidID?: number
  /** @format int32 */
  id?: number
  name?: string
  note?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  sku?: string
}

export interface MainStemSharpDataModelsRFQSupplier {
  /** @format date-time */
  dateInviteAccepted?: string
  /** @format date-time */
  dateInvited?: string
  /** @format int32 */
  id?: number
  invitationType?: 'Direct' | 'Suggested' | 'Opportunity'
  inviteAccepted?: boolean
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  rfqSupplierInvitationType?: string
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminAffiliatesCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersAdminAffiliatesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminAffiliatesDetailsAPIResponse {
  affiliate?: MainStemAPIControllersAdminAffiliatesDetailsAPIResponseAffiliateDetails
  affiliatePrograms?: MainStemAPIControllersAdminAffiliatesDetailsAPIResponseAffiliateProgramDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesDetailsAPIResponseAffiliateDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAffiliatesDetailsAPIResponseAffiliateProgramDetails {
  domain?: string
  /** @format int32 */
  id?: number
  name?: string
  type?: string
  uiVersion?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminAffiliatesListAPIResponse {
  affiliates?: MainStemAPIControllersAdminAffiliatesListAffiliateDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesListAffiliateDetails {
  /** @format int32 */
  affiliateProgramsCount?: number
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAffiliatesProgramsCreateAPIRequest {
  /** @format int32 */
  affiliateID?: number
  domain?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersAdminAffiliatesProgramsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesProgramsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminAffiliatesProgramsDetailsAPIResponse {
  affiliate?: MainStemAPIControllersAdminAffiliatesProgramsDetailsAPIResponseAffiliateDetails
  appSettings?: string
  customCSS?: string
  /** @format int32 */
  customerPriceListId?: number
  domain?: string
  emailStyleSettings?: string
  favicon?: string
  /** @format int32 */
  id?: number
  logo?: string
  /** @format int32 */
  menuId?: number
  name?: string
  type?: string
  uiVersion?: string
  usesAPI?: boolean
  usesPriceList?: boolean
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesProgramsDetailsAPIResponseAffiliateDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAffiliatesProgramsListAPIResponse {
  affiliatePrograms?: MainStemAPIControllersAdminAffiliatesProgramsListAPIResponseAffiliateProgramDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesProgramsListAPIResponseAffiliateProgramDetails {
  affiliate?: MainStemAPIControllersAdminAffiliatesProgramsListAPIResponseAffiliateProgramDetailsAffiliateDetails
  /** @format int32 */
  affiliateID?: number
  domain?: string
  /** @format int32 */
  id?: number
  name?: string
  type?: string
  uiVersion?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminAffiliatesProgramsListAPIResponseAffiliateProgramDetailsAffiliateDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAffiliatesProgramsUpdateAPIRequest {
  appSettings?: string
  customCSS?: string
  /** @format int32 */
  customerPriceListId?: number
  domain?: string
  emailStyleSettings?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  menuId?: number
  name?: string
  type?: string
  uiVersion?: string
  usesAPI?: boolean
  usesPriceList?: boolean
}

export interface MainStemAPIControllersAdminAffiliatesUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockCreateAPIRequest {
  amazonMerchantId: string
  amazonMerchantName: string
  reason: string
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockCreateAPIResponse {
  /** @format int32 */
  id: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIRequest {
  /** @format int32 */
  id: number
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIResponse {
  amazonMerchantBlock: MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIResponseAmazonMerchantBlockDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIResponseAmazonMerchantBlockDetails {
  amazonMerchantId: string
  amazonMerchantName: string
  /** @format int32 */
  id: number
  reason: string
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponse {
  amazonMerchantBlocks: MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponseAmazonMerchantBlockDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponseAmazonMerchantBlockDetails {
  amazonMerchantId: string
  amazonMerchantName: string
  /** @format int32 */
  id: number
  isDeleted: boolean
  reason: string
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockUpdateAPIRequest {
  amazonMerchantId: string
  amazonMerchantName: string
  /** @format int32 */
  id: number
  isDeleted: boolean
  reason: string
}

export interface MainStemAPIControllersAdminAmazonMerchantBlockUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAmazonOrderLogAPIResponse {
  logs: MainStemAPIControllersAdminAmazonOrderLogAPIResponseAmazonOrderLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAmazonOrderLogAPIResponseAmazonOrderLogDetails {
  amazonAPIGwId?: string
  amazonRequestId?: string
  amazonRId?: string
  amazonTraceId?: string
  dateCreated?: string
  /** @format int32 */
  invoiceId?: number
  /** @format int32 */
  orderId?: number
  partitionKey?: string
  requestJSON?: string
  responseJSON?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
}

export interface MainStemAPIControllersAdminOrdersApplyPaymentAPIRequest {
  /** @format int32 */
  orderID?: number
  /** @format double */
  amountToApply?: number
  /** @format double */
  orderAppliedAmount?: number
  invoiceDistribution?: MainStemAPIControllersAdminOrdersApplyPaymentAPIRequestInvoicePaymentDistribution[]
  paymentMethod?: MainStemAPIControllersAdminOrdersApplyPaymentAPIRequestPaymentDetails
}

export interface MainStemAPIControllersAdminOrdersApplyPaymentAPIRequestInvoicePaymentDistribution {
  /** @format double */
  amount?: number
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  invoiceID?: number
}

export interface MainStemAPIControllersAdminOrdersApplyPaymentAPIRequestPaymentDetails {
  accountNumber?: string
  bankName?: string
  customerTaxID?: string
  isACH?: boolean
  /** @format int32 */
  paymentMethodID?: number
  routingNumber?: string
  type?: string
  checkNumber?: string
  note?: string
}

export interface MainStemAPIControllersAdminOrdersApplyPaymentAPIResponse {
  error?: string
  /** @format int32 */
  id?: number
  paymentSuccessful?: boolean
  status?: 'Open' | 'Approved' | 'Denied' | 'Voided'
  invoices?: MainStemAPIControllersAdminOrdersApplyPaymentAPIResponsePaymentResult[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersApplyPaymentAPIResponsePaymentResult {
  wasSuccessful?: boolean
  /** @format double */
  amount?: number
  error?: string
  uuid?: string
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersAdminAttributesCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersAdminAttributesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributesDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminAttributesDetailsAPIResponse {
  attributeValues?: MainStemAPIControllersAdminAttributesDetailsAPIResponseAttributeValueDetails[]
  /** @format int32 */
  id?: number
  name?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributesDetailsAPIResponseAttributeValueDetails {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  productsUsing?: number
  /** @format int32 */
  suppliersUsing?: number
}

export interface MainStemAPIControllersAdminAttributesRemoveAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributesRemoveAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributesGetValuesAPIRequest {
  /** @format int32 */
  attributeId?: number
}

export interface MainStemAPIControllersAdminAttributesGetValuesAPIResponse {
  attributeValues?: MainStemAPIControllersAdminAttributesGetValuesAPIResponseProductAttributeValueDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributesGetValuesAPIResponseProductAttributeValueDetails {
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersAdminAttributesListAPIResponse {
  attributes?: MainStemAPIControllersAdminAttributesListProductAttributeDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributesListProductAttributeDetails {
  /** @format int32 */
  attributeValues?: number
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  productsUsingAttribute?: number
  /** @format int32 */
  suppliersUsingAttribute?: number
}

export interface MainStemAPIControllersAdminAttributesUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributesUpdateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueAddProductAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminAttributeValueAddProductAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueCreateAPIRequest {
  /** @format int32 */
  attributeId?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributeValueCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminAttributeValueDetailsAPIResponse {
  attribute?: MainStemAPIControllersAdminAttributeValueDetailsAPIResponseAttribute
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemAPIControllersAdminAttributeValueDetailsAPIResponseAttributeValueProducts[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueDetailsAPIResponseAttribute {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributeValueDetailsAPIResponseAttributeValueProducts {
  /** @format int32 */
  id?: number
  name?: string
  isPrivate?: boolean
  ownerName?: string
}

export interface MainStemAPIControllersAdminAttributeValueListAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminAttributeValueListAPIResponse {
  attributeValues?: MainStemAPIControllersAdminAttributeValueListAPIResponseAttributeValues[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueListAPIResponseAttributeValues {
  /** @format int32 */
  attributeId?: number
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributeValueBulkAppendAPIResponse {
  errorMessage?: string
  importFailures?: MainStemAPIControllersAdminAttributeValueBulkAppendFailedImportItem[]
  /** @format int32 */
  totalAdded?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueBulkAppendFailedImportItem {
  attribute?: string
  errorMessage?: string
  /** @format int32 */
  productId?: number
  value?: string
}

export interface MainStemAPIControllersAdminAttributeValueBulkReplaceAPIResponse {
  addedItems?: MainStemAPIControllersAdminAttributeValueBulkReplaceImportItem[]
  errorMessage?: string
  importFailures?: MainStemAPIControllersAdminAttributeValueBulkReplaceFailedImportItem[]
  removedItems?: MainStemAPIControllersAdminAttributeValueBulkReplaceImportItem[]
  /** @format int32 */
  totalAdded?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueBulkReplaceImportItem {
  attribute?: string
  /** @format int32 */
  productId?: number
  value?: string
}

export interface MainStemAPIControllersAdminAttributeValueBulkReplaceFailedImportItem {
  attribute?: string
  errorMessage?: string
  /** @format int32 */
  productId?: number
  value?: string
}

export interface MainStemAPIControllersAdminAttributeValueRemoveAPIRequest {
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersAdminAttributeValueRemoveAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueRemoveProductAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminAttributeValueRemoveProductAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAttributeValueUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminAttributeValueUpdateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIHelpersAuthorizationCurrentUser {
  username?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIHelpersAuthorizationRequest {
  password?: string
  username?: string
}

export interface MainStemAPIHelpersAuthorizationResponse {
  appType?: string
  appURL?: string
  name?: string
  urlLogo?: string
  error?: string
  token?: string
  role?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsAddBillLineItemAPIRequest {
  /** @format int32 */
  billID?: number
  /** @format int32 */
  fulfillmentMethodLineItemID?: number
  name?: string
  /** @format double */
  price?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsAddBillLineItemAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsDeleteBillLineItemAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminBillsDeleteBillLineItemAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsDetailsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersAdminBillsDetailsAPIResponse {
  bill?: MainStemAPIControllersAdminBillsDetailsAPIResponseBillDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsDetailsAPIResponseBillDetails {
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentUUID?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  invoiceDateDue?: string
  /** @format date-time */
  invoiceDateIssued?: string
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  invoiceUploads?: MainStemAPIControllersAdminBillsDetailsAPIResponseUploadedInvoiceDocument[]
  lineItems?: MainStemAPIControllersAdminBillsDetailsAPIResponseBillLineItemDetail[]
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  statusOfImport?: number
  statusOfImportAsString?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  unMatchedFulfillmentMethodLineItems?: MainStemAPIControllersAdminBillsDetailsAPIResponseFulfillmentMethodLineItemDetail[]
  uuid?: string
}

export interface MainStemAPIControllersAdminBillsDetailsAPIResponseUploadedInvoiceDocument {
  /** @format date-time */
  dateCompleted?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fileResultsID?: number
  /** @format int32 */
  fileUploadedID?: number
  /** @format int32 */
  id?: number
  requestFileLocation?: string
  requestFileName?: string
  responseFileLocation?: string
  responseFileName?: string
  status?: 'Failed' | 'Open' | 'Processing' | 'Completed' | 'CompletedWithErrors'
  type?:
    | 'BulkProductUpload'
    | 'InvoiceBillUpload'
    | 'ProductSync'
    | 'BulkMenuItemUpload'
    | 'BulkMenuProductUploadAppend'
    | 'BulkMenuProductUploadReplace'
    | 'StaplesBulkUpload'
  userIDCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminBillsDetailsAPIResponseBillLineItemDetail {
  /** @format int32 */
  fulfillmentMethodLineItemID?: number
  fulfillmentMethodProductName?: string
  /** @format int32 */
  id?: number
  /** @format double */
  lineTotal?: number
  name?: string
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsDetailsAPIResponseFulfillmentMethodLineItemDetail {
  /** @format int32 */
  id?: number
  name?: string
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export type MainStemAPIControllersAdminBillsListAPIRequest = object

export interface MainStemAPIControllersAdminBillsListAPIResponse {
  bills?: MainStemAPIControllersAdminBillsListAPIResponseBillDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsListAPIResponseBillDetails {
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentUUID?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  invoiceDateDue?: string
  /** @format date-time */
  invoiceDateIssued?: string
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  invoiceUploads?: MainStemAPIControllersAdminBillsListAPIResponseUploadedInvoiceDocument[]
  lineItems?: MainStemAPIControllersAdminBillsListAPIResponseBillLineItemDetail[]
  msInvoiceID?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  statusOfImport?: number
  statusOfImportAsString?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  unLinkedBillLineItems?: MainStemAPIControllersAdminBillsListAPIResponseBillLineItemDetail[]
  unMatchedFulfillmentMethodLineItems?: MainStemAPIControllersAdminBillsListAPIResponseFulfillmentMethodLineItemDetail[]
  uuid?: string
}

export interface MainStemAPIControllersAdminBillsListAPIResponseUploadedInvoiceDocument {
  /** @format date-time */
  dateCompleted?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fileResultsID?: number
  /** @format int32 */
  fileUploadedID?: number
  /** @format int32 */
  id?: number
  requestFileLocation?: string
  requestFileName?: string
  responseFileLocation?: string
  responseFileName?: string
  status?: 'Failed' | 'Open' | 'Processing' | 'Completed' | 'CompletedWithErrors'
  type?:
    | 'BulkProductUpload'
    | 'InvoiceBillUpload'
    | 'ProductSync'
    | 'BulkMenuItemUpload'
    | 'BulkMenuProductUploadAppend'
    | 'BulkMenuProductUploadReplace'
    | 'StaplesBulkUpload'
  userIDCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminBillsListAPIResponseBillLineItemDetail {
  /** @format int32 */
  fulfillmentMethodLineItemID?: number
  fulfillmentMethodProductName?: string
  /** @format int32 */
  id?: number
  /** @format double */
  lineTotal?: number
  name?: string
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsListAPIResponseFulfillmentMethodLineItemDetail {
  /** @format int32 */
  id?: number
  name?: string
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsUpdateLineItemAPIRequest {
  /** @format int32 */
  fulfillmentMethodLineItemID?: number
  /** @format int32 */
  id?: number
  name?: string
  /** @format double */
  price?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsUpdateLineItemAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCarriersCreateAPIRequest {
  fullName?: string
  name?: string
  trackingUrl?: string
}

export interface MainStemAPIControllersAdminCarriersCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCarriersDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCarriersDetailsAPIResponse {
  carrier?: MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCarriersDetailsAPIResponseCarrierDetails {
  fullName?: string
  /** @format int32 */
  id?: number
  image?: string
  name?: string
  trackingUrl?: string
}

export type MainStemAPIControllersAdminCarriersListAPIRequest = object

export interface MainStemAPIControllersAdminCarriersListAPIResponse {
  carriers?: MainStemAPIControllersAdminCarriersListAPIResponseCarrierDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCarriersListAPIResponseCarrierDetails {
  fullName?: string
  /** @format int32 */
  id?: number
  image?: string
  name?: string
  trackingUrl?: string
}

export interface MainStemAPIControllersAdminCarriersUpdateAPIRequest {
  fullName?: string
  /** @format int32 */
  id?: number
  name?: string
  trackingUrl?: string
}

export interface MainStemAPIControllersAdminCarriersUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsCreateAPIRequest {
  /** @format int32 */
  locationID?: number
  name?: string
}

export interface MainStemAPIControllersAdminCatalogsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsAPIRequest {
  /** @format int32 */
  catalogID?: number
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsAPIResponse {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  /** @format int32 */
  locationID?: number
  name?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsDetailsLocationsAddAPIRequest {
  /** @format int32 */
  catalogID?: number
  locationIds?: number[]
}

export interface MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIRequest {
  /** @format int32 */
  catalogID?: number
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIResponse {
  locations?: MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCatalogsDetailsLocationsRemoveAPIRequest {
  /** @format int32 */
  catalogID?: number
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsProductsAddAPIRequest {
  catalogIds?: number[]
  productIds?: number[]
}

export interface MainStemAPIControllersAdminCatalogsDetailsProductsListAPIRequest {
  /** @format int32 */
  catalogID?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsProductsListAPIResponse {
  products?: MainStemAPIControllersAdminCatalogsDetailsProductsListAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsDetailsProductsListAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  imgURL?: string
  name?: string
}

export interface MainStemAPIControllersAdminCatalogsDetailsProductsRemoveAPIRequest {
  /** @format int32 */
  catalogID?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsUsersAddAPIRequest {
  /** @format int32 */
  catalogID?: number
  userIds?: string[]
}

export interface MainStemAPIControllersAdminCatalogsDetailsUsersListAPIRequest {
  /** @format int32 */
  catalogID?: number
}

export interface MainStemAPIControllersAdminCatalogsDetailsUsersListAPIResponse {
  users?: MainStemAPIControllersAdminCatalogsDetailsUsersListAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsDetailsUsersListAPIResponseUserDetails {
  role?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminCatalogsDetailsUsersRemoveAPIRequest {
  /** @format int32 */
  catalogID?: number
  userId?: string
}

export interface MainStemAPIControllersAdminCatalogsDuplicateAPIRequest {
  /** @format int32 */
  catalogID?: number
}

export interface MainStemAPIControllersAdminCatalogsDuplicateAPIResponse {
  /** @format int32 */
  catalogID?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsListAPIRequest {
  /** @format int32 */
  customerID?: number
  locationIDs?: number[]
}

export interface MainStemAPIControllersAdminCatalogsListAPIResponse {
  productLists?: MainStemAPIControllersAdminCatalogsListAPIResponseProductListDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCatalogsListAPIResponseProductListDetails {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
  /** @format int32 */
  productCount?: number
  /** @format int32 */
  userCount?: number
  userRelated?: boolean
}

export interface MainStemAPIControllersAdminCatalogsUpdateAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
}

export interface MainStemAPIControllersAdminChangeLogListAPIRequest {
  documentId?: string
  documentType?: 'Requisition' | 'PurchaseOrder' | 'Invoice' | 'Shipment' | 'Payment'
}

export interface MainStemAPIControllersAdminChangeLogListAPIResponse {
  changeLogs?: MainStemSharpHelpersFactoriesChangeLogsModelsChangeLogRecord[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpHelpersFactoriesChangeLogsModelsChangeLogRecord {
  content?: string
  documentID?: string
  documentType?: 'Requisition' | 'PurchaseOrder' | 'Invoice' | 'Shipment' | 'Payment'
  env?: string
  eTag?: AzureETag
  partitionKey?: string
  reason?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
  userIDCreatedBy?: string
}

export type AzureETag = object

export interface MainStemAPIControllersAdminPaymentsCheckPaymentStatusAPIRequest {
  /** @format int32 */
  paymentTransactionID?: number
}

export interface MainStemAPIControllersAdminPaymentsCheckPaymentStatusAPIResponse {
  status?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionCategoryCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersAdminCollectionCategoryCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionCategoryDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponse {
  category?: MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  subCategories?: MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetailsCollectionSubCategoryDetails[]
}

export interface MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponseCollectionCategoryDetailsCollectionSubCategoryDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export type MainStemAPIControllersAdminCollectionCategoryListAPIRequest = object

export interface MainStemAPIControllersAdminCollectionCategoryListAPIResponse {
  categories?: MainStemAPIControllersAdminCollectionCategoryListAPIResponseCollectionCategoryDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionCategoryListAPIResponseCollectionCategoryDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersAdminCollectionCategoryUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionCategoryUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionClassificationCreateAPIRequest {
  name?: string
  /** @format int32 */
  subCategoryId?: number
}

export interface MainStemAPIControllersAdminCollectionClassificationCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponse {
  classification?: MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetails {
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsProductDetails[]
  subCategory?: MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsSubCategoryDetails
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsProductDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsSubCategoryDetails {
  category?: MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsSubCategoryDetailsCategoryDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponseCollectionClassificationDetailsSubCategoryDetailsCategoryDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationListAPIRequest {
  /** @format int32 */
  subCategoryId?: number
}

export interface MainStemAPIControllersAdminCollectionClassificationListAPIResponse {
  classifications?: MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetails {
  /** @format int32 */
  id?: number
  name?: string
  subCategory?: MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetailsCollectionSubCategoryDetails
}

export interface MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetailsCollectionSubCategoryDetails {
  category?: MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetailsCollectionSubCategoryDetailsCollectionCategoryDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationListAPIResponseCollectionClassificationDetailsCollectionSubCategoryDetailsCollectionCategoryDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionClassificationUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionSubCategoryCreateAPIRequest {
  /** @format int32 */
  categoryId?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponse {
  subCategory?: MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetails {
  category?: MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetailsCollectionCategoryDetails
  classifications?: MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetailsCollectionClassificationDetails[]
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetailsCollectionCategoryDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponseCollectionSubCategoryDetailsCollectionClassificationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryListAPIRequest {
  /** @format int32 */
  categoryId?: number
}

export interface MainStemAPIControllersAdminCollectionSubCategoryListAPIResponse {
  subCategories?: MainStemAPIControllersAdminCollectionSubCategoryListAPIResponseCollectionSubCategoryDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCollectionSubCategoryListAPIResponseCollectionSubCategoryDetails {
  category?: MainStemAPIControllersAdminCollectionSubCategoryListAPIResponseCollectionSubCategoryDetailsCollectionCategoryDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryListAPIResponseCollectionSubCategoryDetailsCollectionCategoryDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCollectionSubCategoryUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminConversionDashboardAPIRequest {
  /** @format date-time */
  dateEnd?: string
  /** @format date-time */
  dateStart?: string
}

export interface MainStemAPIControllersAdminConversionDashboardAPIResponse {
  dataExport?: MainStemAPIControllersAdminConversionDashboardAPIResponseExportData[]
  dataMonthly?: MainStemAPIControllersAdminConversionDashboardAPIResponseMonthlyData[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminConversionDashboardAPIResponseExportData {
  /** @format double */
  actual?: number
  /** @format double */
  actualPrivate?: number
  /** @format double */
  actualVerified?: number
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateEnd?: string
  /** @format date-time */
  dateStart?: string
  /** @format double */
  forecast?: number
  /** @format double */
  monthlyBaseline?: number
  /** @format double */
  monthlyCapturable?: number
  /** @format double */
  monthlyProForma?: number
  /** @format double */
  monthlyTotal?: number
  type?: string
}

export interface MainStemAPIControllersAdminConversionDashboardAPIResponseMonthlyData {
  customer?: MainStemAPIControllersAdminConversionDashboardAPIResponseCustomerDetails
  data?: MainStemAPIControllersAdminConversionDashboardAPIResponseMonthlyDataDataDetails[]
}

export interface MainStemAPIControllersAdminConversionDashboardAPIResponseCustomerDetails {
  /** @format date-time */
  dateGoLive?: string
  /** @format int32 */
  id?: number
  /** @format double */
  monthlyBaseline?: number
  /** @format double */
  monthlyCapturable?: number
  /** @format double */
  monthlyProForma?: number
  /** @format double */
  monthlyTotal?: number
  name?: string
  type?: string
}

export interface MainStemAPIControllersAdminConversionDashboardAPIResponseMonthlyDataDataDetails {
  /** @format double */
  approved?: number
  /** @format date-time */
  dateEnd?: string
  /** @format date-time */
  dateStart?: string
  /** @format double */
  proforma?: number
  /** @format double */
  promisedBy?: number
  reason?: string
  /** @format double */
  shipped?: number
  /** @format double */
  shippedPrivate?: number
  /** @format double */
  shippedVerified?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingAddFreightShippingInvoiceAPIRequest {
  /** @format int32 */
  invoiceID?: number
  /** @format double */
  shippingAmountApplied?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingAddFreightShippingInvoiceAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIRequest {
  /** @format int32 */
  customerID?: number
  locationIDs?: number[]
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponse {
  invoices?: MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponseInvoiceDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponseInvoiceDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateShipped?: string
  /** @format double */
  discount?: number
  /** @format date-time */
  dueAsOf?: string
  hasTermsApplied?: boolean
  /** @format int32 */
  id?: number
  invoiceId?: string
  /** @format double */
  invoiceSubTotal?: number
  invoiceTermsIsDue?: boolean
  /** @format double */
  invoiceTotal?: number
  location?: MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponseInvoiceDetailsLocationDetails
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format int32 */
  termLedgerID?: number
  /** @format double */
  totalDue?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalReturned?: number
  userApprovedBy?: string
  userRelated?: boolean
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponseInvoiceDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIRequest {
  /** @format int32 */
  customerID?: number
  locationIDs?: number[]
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponse {
  orders?: MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format double */
  discount?: number
  hasTermsApplied?: boolean
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  /** @format double */
  totalDue?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalReturned?: number
  userApprovedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsArchiveAPIRequest {
  /** @format int32 */
  paymentMethodID?: number
  value?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsSoftDeleteAPIRequest {
  /** @format int32 */
  paymentMethodID?: number
  value?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingAddLineAPIRequest {
  /** @format int32 */
  businessPartnerId?: number
  /** @format int32 */
  customerPriceListID?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminCustomerPricingAddLineAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponse {
  errorMessage?: string
  importFailures?: MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponsePriceListRecord[]
  successItems?: MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponsePriceListCSV[]
  /** @format int32 */
  totalAdded?: number
  /** @format int32 */
  totalUpdated?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponsePriceListRecord {
  isNew?: boolean
  unableToFindBusinessPartner?: boolean
  unableToFindProduct?: boolean
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  customerPriceListID?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponsePriceListCSV {
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  customerPriceListID?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersAdminCustomerPricingCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersAdminCustomerPricingCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponse {
  affiliatePrograms?: MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseAffiliateProgramDetails[]
  customerPriceListLineItems?: MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetails[]
  name?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseAffiliateProgramDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetails {
  businessPartner?: MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetailsBusinessPartnerDetails
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetailsProductDetails
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetailsBusinessPartnerDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponseCustomerPriceListLineItemDetailsProductDetails {
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCustomerPricingUpdateLineAdminCustomerPricingLineItemUpdateControllerAPIRequest {
  /** @format int32 */
  customerPriceListLineItemID?: number
  /** @format double */
  price?: number
}

export interface MainStemAPIControllersAdminCustomerPricingListAPIResponse {
  customerPriceLists?: MainStemAPIControllersAdminCustomerPricingListCustomerPriceListDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomerPricingListCustomerPriceListDetails {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  productsCustomPricing?: number
  /** @format int32 */
  suppliersCustomPricing?: number
}

export interface MainStemAPIControllersAdminCustomerPricingRemoveLineAdminCustomerPricingRemoveLineItemControllerAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCustomersCreateAPIRequest {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  email?: string
  name?: string
  phone?: string
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  zohoCompanyID?: string
}

export interface MainStemAPIControllersAdminCustomersCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIResponse {
  allowedPaymentOptions?: MainStemAPIControllersAdminCustomersDetailsAllowablePaymentOptions
  organization?: MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersDetailsAllowablePaymentOptions {
  ach?: boolean
  creditCard?: boolean
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetails {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  allowCreditCards?: boolean
  allowOrderRecommendations?: boolean
  allowPunchOutInMainNav?: boolean
  allowTermPayments?: boolean
  /** @format double */
  available?: number
  /** @format double */
  balance?: number
  /** @format double */
  creditLimit?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateGoLive?: string
  /** @format date-time */
  dateTrialExpires?: string
  /** @format double */
  due?: number
  email?: string
  imgURL_Logo?: string
  integrations?: MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsIntegrationSettings[]
  internalTeamsChannelId?: string
  /** @format int32 */
  maxNumberOfLocations?: number
  /** @format int32 */
  maxNumberOfUsers?: number
  /** @format double */
  monthlySpendBaseline?: number
  /** @format double */
  monthlySpendCapturable?: number
  /** @format double */
  monthlySpendTotal?: number
  name?: string
  phone?: string
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  quickbooksId?: string
  /** @format double */
  saasFee?: number
  saasFeeEnabled?: boolean
  salesHistory?: MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsSalesHistoryDetails[]
  salesProductLines?: MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsSalesProductLinesDetails[]
  sideKickEnabled?: boolean
  sideKickIndex?: string
  sideKickPrivateIndexEnabled?: boolean
  sideKickURL?: string
  /** @format int32 */
  termDays?: number
  /** @format double */
  termsCreditLimit?: number
  termsDetails?: MainStemAPIControllersAdminCustomersBillingTermsTermsTotalAPIResponse
  zohoCompanyID?: string
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsIntegrationSettings {
  /** @format date-time */
  dateLastUpdated?: string
  enabled?: boolean
  /** @format int32 */
  id?: number
  name?: string
  settings?: string
  type?: string
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsSalesHistoryDetails {
  label?: string
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersAdminCustomersDetailsAPIResponseCustomerDetailsSalesProductLinesDetails {
  label?: string
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersAdminCustomersBillingTermsTermsTotalAPIResponse {
  /** @format double */
  available?: number
  /** @format double */
  balance?: number
  /** @format double */
  creditLimit?: number
  /** @format int32 */
  days?: number
  /** @format double */
  due?: number
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
}

export interface MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponse {
  logs?: MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponseIntegrationLog[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponseIntegrationLog {
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  customerName?: string
  locationName?: string
  apiCallName?: string
  type?: string
  successful?: boolean
  request?: string
  response?: string
  /** @format int32 */
  customerIntegrationSettingID?: number
  /** @format int32 */
  customerIntegrationSessionID?: number
}

export interface MainStemAPIControllersAdminCustomersListAPIResponse {
  organizations?: MainStemAPIControllersAdminCustomersListAPIResponseOrganizationDetails[]
  organizationTotals?: MainStemAPIControllersAdminCustomersListAPIResponseOrganizationAggregateDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersListAPIResponseOrganizationDetails {
  /** @format double */
  creditLimit?: number
  /** @format int32 */
  id?: number
  name?: string
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format double */
  termsTotal?: number
}

export interface MainStemAPIControllersAdminCustomersListAPIResponseOrganizationAggregateDetails {
  /** @format int32 */
  count?: number
  program?: string
}

export interface MainStemAPIControllersAdminCustomersLocationsListAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminCustomersLocationsListAPIResponse {
  error?: string
  locations?: MainStemAPIControllersAdminCustomersLocationsListAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersLocationsListAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  imgURL_Logo?: string
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminCustomersOrdersListAPIRequest {
  /** @format int32 */
  organizationId?: number
}

export interface MainStemAPIControllersAdminCustomersOrdersListAPIResponse {
  error?: string
  orders?: MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetails {
  createdOn?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetailsLocationDetails
  manager?: string
  paymentStatus?: string
  status?: string
  /** @format double */
  totalAmount?: number
  uuid?: string
}

export interface MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetailsLocationDetails {
  address?: MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetailsLocationDetailsAddressDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminCustomersOrdersListAPIResponseOrderDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminCustomersUpdateAPIRequest {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  allowCreditCards?: boolean
  allowOrderRecommendations?: boolean
  allowPunchOutInMainNav?: boolean
  allowTermPayments?: boolean
  /** @format date-time */
  dateGoLive?: string
  /** @format date-time */
  dateTrialExpires?: string
  email?: string
  /** @format int32 */
  id?: number
  internalTeamsChannelId?: string
  /** @format int32 */
  maxNumberOfLocations?: number
  /** @format int32 */
  maxNumberOfUsers?: number
  /** @format double */
  monthlySpendBaseline?: number
  /** @format double */
  monthlySpendCapturable?: number
  /** @format double */
  monthlySpendTotal?: number
  name?: string
  phone?: string
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format double */
  saasFee?: number
  saasFeeEnabled?: boolean
  sideKickEnabled?: boolean
  sideKickIndex?: string
  sideKickPrivateIndexEnabled?: boolean
  sideKickURL?: string
  /** @format int32 */
  termDays?: number
  /** @format double */
  termsCreditLimit?: number
  zohoCompanyID?: string
}

export interface MainStemAPIControllersAdminCustomersUsersListAPIRequest {
  /** @format int32 */
  organizationId?: number
}

export interface MainStemAPIControllersAdminCustomersUsersListAPIResponse {
  users?: MainStemAPIControllersAdminCustomersUsersListAPIResponseUserDetails[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersUsersListAPIResponseUserDetails {
  friendlyName?: string
  role?: string
  username?: string
  uuid?: string
  customerPermissionsRole?: string
}

export interface MainStemAPIControllersAdminCustomerPricingExportLinesCSVAdminCustomPricingItemsExportToCSVControllerAPIRequest {
  /** @format int32 */
  customPriceListID?: number
}

export interface MainStemAPIControllersAdminDashboardApprovedUnshippedAPIResponse {
  /** @format double */
  amount?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminDashboardLast5OrdersAPIResponse {
  last5Orders?: MainStemAPIControllersAdminDashboardLast5OrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminDashboardLast5OrdersAPIResponseOrderDetails {
  /** @format double */
  amount?: number
  customer?: string
  /** @format int32 */
  id?: number
  platform?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminDashboardSalesByCustomerAPIResponse {
  sales?: MainStemAPIControllersAdminDashboardSalesByCustomerAPIResponseCustomerSalesDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminDashboardSalesByCustomerAPIResponseCustomerSalesDetails {
  customer?: string
  /** @format double */
  sales?: number
}

export interface MainStemAPIControllersAdminDashboardSalesByPlatformAPIResponse {
  platformSales?: MainStemAPIControllersAdminDashboardSalesByPlatformAPIResponsePlatformSalesDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminDashboardSalesByPlatformAPIResponsePlatformSalesDetails {
  platform?: string
  /** @format double */
  sales?: number
}

export interface MainStemAPIControllersAdminDashboardUnchargedFreightAPIResponse {
  /** @format int32 */
  numberOfOrders?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFeedCreateAPIRequest {
  /** @format int32 */
  customerId?: number
  description?: string
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedToId?: string
}

export interface MainStemAPIControllersAdminFeedCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFeedDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminFeedDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFeedDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminFeedDetailsAPIResponse {
  feed?: MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetails {
  customer?: MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetailsFeedStreamCustomerDetails
  /** @format date-time */
  dateCreated?: string
  description?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedTo?: MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails
  userCreatedBy?: MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails
}

export interface MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetailsFeedStreamCustomerDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails {
  id?: string
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersAdminFeedListAPIResponse {
  feeds?: MainStemAPIControllersAdminFeedListAPIResponseFeedDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFeedListAPIResponseFeedDetails {
  customer?: MainStemAPIControllersAdminFeedListAPIResponseFeedDetailsFeedStreamCustomerDetails
  /** @format date-time */
  dateCreated?: string
  description?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedTo?: MainStemAPIControllersAdminFeedListAPIResponseFeedDetailsFeedStreamUserDetails
  userCreatedBy?: MainStemAPIControllersAdminFeedListAPIResponseFeedDetailsFeedStreamUserDetails
}

export interface MainStemAPIControllersAdminFeedListAPIResponseFeedDetailsFeedStreamCustomerDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminFeedListAPIResponseFeedDetailsFeedStreamUserDetails {
  id?: string
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersAdminFeedUpdateAPIRequest {
  /** @format int32 */
  customerId?: number
  description?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedToId?: string
}

export interface MainStemAPIControllersAdminFeedUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFileUploadsListAPIRequest {
  type:
    | 'BulkProductUpload'
    | 'InvoiceBillUpload'
    | 'ProductSync'
    | 'BulkMenuItemUpload'
    | 'BulkMenuProductUploadAppend'
    | 'BulkMenuProductUploadReplace'
    | 'StaplesBulkUpload'
}

export interface MainStemAPIControllersAdminFileUploadsListAPIResponse {
  fileUploads: MainStemAPIControllersAdminFileUploadsListAPIResponseFileUploadDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFileUploadsListAPIResponseFileUploadDetails {
  /** @format date-time */
  dateCompleted?: string
  /** @format date-time */
  dateCreated: string
  fileResults?: string
  fileUploaded?: string
  /** @format int32 */
  id: number
  status: 'Failed' | 'Open' | 'Processing' | 'Completed' | 'CompletedWithErrors'
}

export interface MainStemSharpHelpersPaginatePaginationRequest {
  filters?: Record<string, MainStemSharpHelpersPaginateFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  sortField?: string
  sortOrder?: string
  exportResults?: boolean
}

export interface MainStemSharpHelpersPaginateFilterDetail {
  caseSensitive?: boolean
  comparator?: string
  filterType?: string
  filterVal?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerDetailsAPIRequest {
  /** @format int32 */
  fulfillmentID?: number
}

export interface MainStemAPIControllersAdminFulfillmentTrackerDetailsAPIResponse {
  fulfillment?: MainStemAPIControllersAdminFulfillmentTrackerDetailsTrackedFulfillment
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerDetailsTrackedFulfillment {
  commaSeparatedTrackingNumbers?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  fulfillmentDateNotified?: string
  /** @format int32 */
  fulfillmentId?: number
  fulfillmentOrderNumber?: string
  fulfillmentStatus?: string
  hasOrganization?: boolean
  hasProblem?: boolean
  hasTrackingDetails?: boolean
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  isFreightShipping?: boolean
  isFulfilled?: boolean
  isPrivateSupplier?: boolean
  isTermsOrder?: boolean
  lastInvoiceNote?: string
  lastInvoiceNoteCreatedBy?: string
  /** @format date-time */
  lastInvoiceNoteDate?: string
  loading: boolean
  notes?: string
  notificationRequired?: boolean
  notified?: boolean
  /** @format int32 */
  orderId: number
  orderUUID: string
  /** @format int32 */
  organizationId?: number
  organizationName?: string
  owner: MainStemAPIControllersAdminFulfillmentTrackerDetailsBusinessPartnerDetail
  owner_contact_email?: string
  owner_contact_phone?: string
  paymentStatus?: string
  /** @format date-time */
  shipmentDate?: string
  shipmentType?: string
  subType?: string
  supplier: MainStemAPIControllersAdminFulfillmentTrackerDetailsBusinessPartnerDetail
  supplier_contact_email?: string
  supplier_contact_phone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  termLedgerId?: number
  type?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerDetailsBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerListAPIRequest {
  allPages?: boolean
  sortField?: string
  sortOrder?: string
  filters?: Record<string, MainStemSharpHelpersPaginateFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  exportResults?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerListAPIResponse {
  fulfillments?: MainStemAPIControllersAdminFulfillmentTrackerListTrackedFulfillment[]
  /** @format int32 */
  resultsTotalCount?: number
  /** @format int32 */
  totalFulfilled?: number
  /** @format int32 */
  totalFulfillments?: number
  /** @format int32 */
  totalNoProblems?: number
  /** @format int32 */
  totalProblems?: number
  /** @format int32 */
  totalUnfulfilled?: number
  /** @format int32 */
  totalUniqueOrders?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerListTrackedFulfillment {
  commaSeparatedTrackingNumbers?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
  customerPricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  customerProgramType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateRejected?: string
  /** @format int32 */
  fulfillmentId?: number
  fulfillmentOrderNumber?: string
  fulfillmentStatus?: string
  hasOrganization?: boolean
  hasProblem?: boolean
  hasShippingCost?: boolean
  hasSupplierOrderNumber?: boolean
  hasTrackingDetails?: boolean
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  isAccepted?: boolean
  isAcknowledged?: boolean
  isFreightShipping?: boolean
  isFulfilled?: boolean
  isPending?: boolean
  isPrivateSupplier?: boolean
  isRejected?: boolean
  isRFQOrder?: boolean
  isTermsOrder?: boolean
  lastInvoiceNote?: string
  lastInvoiceNoteCreatedBy?: string
  /** @format date-time */
  lastInvoiceNoteDate?: string
  loading: boolean
  notes?: string
  notificationRequired?: boolean
  notified?: boolean
  /** @format int32 */
  orderId?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  orderUUID?: string
  /** @format int32 */
  organizationId?: number
  organizationName?: string
  owner?: MainStemAPIControllersAdminFulfillmentTrackerListBusinessPartnerDetail
  owner_contact_email?: string
  owner_contact_phone?: string
  paymentMethods?: string
  paymentStatus?: string
  paymentStatusComplete?: boolean
  pricingType?: string
  programType?: string
  /** @format date-time */
  shipmentDate?: string
  shipmentType?: string
  subType?: string
  supplier?: MainStemAPIControllersAdminFulfillmentTrackerListBusinessPartnerDetail
  supplier_contact_email?: string
  supplier_contact_phone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  supplierRequiresManualProcessing?: boolean
  supplierRequiresPrePayment?: boolean
  /** @format int32 */
  termLedgerId?: number
  type?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerListBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
  requiresManualProcessing?: boolean
  requiresPrePayment?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUpdateAPIRequest {
  /** @format int32 */
  businessPartnerId?: number
  businessPartnerName?: string
  commaSeparatedTrackingNumbers?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  fulfillmentDateNotified?: string
  /** @format int32 */
  fulfillmentId?: number
  fulfillmentOrderNumber?: string
  /** @format int32 */
  fulfillmentPartnerId?: number
  fulfillmentPartnerName?: string
  hasProblem?: boolean
  /** @format double */
  invoiceTotal?: number
  isFulfilled?: boolean
  notes?: string
  /** @format int32 */
  orderId?: number
  /** @format date-time */
  shipmentDate?: string
  trackingNumbers?: string[]
  type?: string
}

export interface MainStemAPIControllersAdminGetQuoteAPIResponse {
  author?: string
  quote?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersImpersonationAPIRequest {
  username?: string
}

export interface MainStemAPIControllersAdminUsersImpersonationAPIResponse {
  impersonationUrl?: string
  impersonationUrls?: MainStemAPIControllersAdminUsersImpersonationImpersonationUrl[]
  purchasingBaseUrl?: string
  supplierBaseUrl?: string
  token?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersImpersonationImpersonationUrl {
  name?: string
  url?: string
}

export interface MainStemAPIControllersAdminInfrastructureQueuesListAPIResponse {
  queues?: MainStemAPIControllersAdminInfrastructureQueuesListServiceBusQueueDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInfrastructureQueuesListServiceBusQueueDetail {
  /** @format date-time */
  accessedAt?: string
  /** @format int32 */
  activeMessageCount?: number
  /** @format date-time */
  createdAt?: string
  /** @format int32 */
  deadLetterMessageCount?: number
  name?: string
  /** @format int32 */
  scheduledMessageCount?: number
  /** @format int64 */
  totalSizeInBytes?: number
  /** @format int32 */
  totalMessageCount?: number
  /** @format int32 */
  transferDeadLetterMessageCount?: number
  /** @format int32 */
  transferMessageCount?: number
  /** @format date-time */
  updatedAt?: string
}

export interface MainStemAPIControllersAdminInfrastructureWebJobsListAPIResponse {
  webJobs?: MainStemAPIControllersAdminInfrastructureWebJobsListWebJobDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInfrastructureWebJobsListWebJobDetail {
  id?: string
  name?: string
  type?: string
  location?: string
  properties?: MainStemAPIControllersAdminInfrastructureWebJobsListWebJobProperties
}

export interface MainStemAPIControllersAdminInfrastructureWebJobsListWebJobProperties {
  latest_Run?: MainStemAPIControllersAdminInfrastructureWebJobsListWebJobLastestRunDetail
  history_Url?: string
  scheduler_Logs_Url?: string
  name?: string
  run_Command?: string
  url?: string
  extra_Info_Url?: string
  type?: string
  error?: string
  using_Sdk?: string
  settings?: MainStemAPIControllersAdminInfrastructureWebJobsListWebJobSettings
}

export interface MainStemAPIControllersAdminInfrastructureWebJobsListWebJobLastestRunDetail {
  id?: string
  name?: string
  status?: string
  start_Time?: string
  end_Time?: string
  duration?: string
  output_Url?: string
  error_Url?: string
  url?: string
  job_Name?: string
  trigger?: string
}

export interface MainStemAPIControllersAdminInfrastructureWebJobsListWebJobSettings {
  schedule?: string
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponse {
  logs: MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponseAmazonOrderLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponseAmazonOrderLogDetails {
  dateCreated?: string
  /** @format int32 */
  invoiceId?: number
  /** @format int32 */
  orderId?: number
  partitionKey?: string
  requestId?: string
  requestJSON?: string
  responseJSON?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequest {
  address?: MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequestAddressDetails
  lineItems?: MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequestLineItemDetails[]
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequestAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequestLineItemDetails {
  /** @format int32 */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponse {
  lineItems?: MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponseLineItemDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponseLineItemDetails {
  /** @format double */
  cost: number
  /** @format date-time */
  dateEstimatedDelivery?: string
  shipmentMethod: string
  sku: string
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreateAPIRequest {
  /** @format int32 */
  businessPartnerId?: number
  links?: MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreatePaymentLink[]
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreatePaymentLink {
  /** @format double */
  amount?: number
  /** @format int32 */
  fulfillmentId?: number
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  paymentTransactionId?: number
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreateAPIResponse {
  error?: string
  paymentLinks?: MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreatePaymentLinks[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreatePaymentLinks {
  /** @format double */
  amount?: number
  /** @format int32 */
  bulkInvoicePaymentId?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fulfillmentId?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  paymentTransactionId?: number
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkDeleteAPIRequest {
  /** @format int32 */
  invoiceLinkID?: number
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkUpdateAPIRequest {
  /** @format int32 */
  invoiceLinkID?: number
  /** @format double */
  paymentAmount?: number
}

export interface MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoiceProblemsCreateAPIRequest {
  content?: string
  subject?: string
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersGlobalMessageCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoiceProblemsListAPIRequest {
  invoiceIds?: number[]
}

export interface MainStemAPIControllersAdminInvoiceProblemsListAPIResponse {
  problems?: MainStemAPIControllersAdminInvoiceProblemsListAPIResponseInvoiceProblemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoiceProblemsListAPIResponseInvoiceProblemDetail {
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceID?: number
  /** @format date-time */
  dateCreated?: string
  createdBy?: string
  createdByID?: string
  subject?: string
  status?: string
  message?: string
}

export interface MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIRequest {
  invoiceIds?: number[]
}

export interface MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIResponse {
  problems?: MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIResponseInvoiceProblemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIResponseInvoiceProblemDetail {
  createdBy?: string
  createdByID?: string
  /** @format int32 */
  createdByLocationID?: number
  createdByLocationName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  message?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  status?: string
  subject?: string
  type?: string
  /** @format int32 */
  unreadCount?: number
}

export interface MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIRequest {
  /** @format int32 */
  id?: number
  status?: string
}

export interface MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIResponse {
  problem?: MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIResponseInvoiceProblemDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIResponseInvoiceProblemDetail {
  message?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  createdBy?: string
  createdByID?: string
  status?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
}

export type MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIRequest = object

export interface MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIResponse {
  invoices?: MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIResponseInvoiceForImportDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIResponseInvoiceForImportDetail {
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentUUID?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  dateNotified?: string
  /** @format double */
  invoiceTotal?: number
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  statusOfImport?: number
  statusOfImportAsString?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  customerLocationID?: number
  customerLocationName?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
}

export interface MainStemAPIControllersAdminInvoicesProcessDropshipAPIRequest {
  manualOverride?: boolean
  /** @format int32 */
  orderID?: number
}

export interface MainStemAPIControllersAdminInvoicesDetailsAPIRequest {
  /** @format int32 */
  invoiceID?: number
}

export interface MainStemAPIControllersAdminInvoicesDetailsAPIResponse {
  invoice?: MainStemAPIControllersAdminInvoicesListTrackedInvoice
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoicesListTrackedInvoice {
  commaSeparatedTrackingNumbers?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
  customerPricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  customerProgramType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format int32 */
  customerTermDays?: number
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateRejected?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format int32 */
  daysToPaymentDue?: number
  /** @format int32 */
  daysToPromisedBy?: number
  fulfillmentOrderNumber?: string
  fulfillmentStatus?: string
  hasCustomProducts?: boolean
  hasOrganization?: boolean
  hasProblem?: boolean
  hasShippingCost?: boolean
  hasSupplierOrderNumber?: boolean
  hasTrackingDetails?: boolean
  /** @format int32 */
  invoiceID?: number
  /** @format double */
  invoiceTotal?: number
  isAccepted?: boolean
  isAcknowledged?: boolean
  isAwaitingAcceptGt48Hours?: boolean
  isAwaitingAckGt48Hours?: boolean
  isFreightShipping?: boolean
  isFulfilled?: boolean
  isNeedsShippingInfo?: boolean
  isNotified?: boolean
  isPending?: boolean
  isPrivateSupplier?: boolean
  isPromisedDatePassed?: boolean
  isRejected?: boolean
  isRFQOrder?: boolean
  isTermsOrder?: boolean
  lastInvoiceNote?: string
  lastInvoiceNoteCreatedBy?: string
  /** @format date-time */
  lastInvoiceNoteDate?: string
  notes?: string
  notificationRequired?: boolean
  /** @format int32 */
  orderID?: number
  orderInvoiceID?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  orderUUID?: string
  /** @format int32 */
  organizationID?: number
  organizationName?: string
  owner?: MainStemAPIControllersAdminInvoicesListBusinessPartnerDetail
  ownerContactEmail?: string
  ownerContactPhone?: string
  /** @format date-time */
  paymentDateDue?: string
  paymentMethods?: string
  paymentStatus?: string
  paymentStatusComplete?: boolean
  /** @format int32 */
  problemCount?: number
  /** @format int32 */
  progress?: number
  shipmentType?: string
  subType?: string
  supplier?: MainStemAPIControllersAdminInvoicesListBusinessPartnerDetail
  supplierAcceptanceEnabled?: boolean
  supplierContactEmail?: string
  supplierContactPhone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  supplierPaymentAgreement?: 'DueUponReceipt' | 'PrePay' | 'Net15' | 'Net30' | 'Net45' | 'Net30_110' | 'Net30_210'
  supplierRequiresManualProcessing?: boolean
  supplierRequiresPrePayment?: boolean
  /** @format int32 */
  termLedgerID?: number
  trackingDetails?: MainStemAPIControllersAdminInvoicesListInvoiceTrackingDetails[]
  type?: string
  /** @format int32 */
  unResolvedProblemCount?: number
}

export interface MainStemAPIControllersAdminInvoicesListBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
  requiresManualProcessing?: boolean
  requiresPrePayment?: boolean
}

export interface MainStemAPIControllersAdminInvoicesListInvoiceTrackingDetails {
  carrier?: string
  trackingNumber?: string
}

export interface MainStemAPIControllersAdminInvoicesListAPIRequest {
  allPages?: boolean
  sortField?: string
  sortOrder?: string
  filters?: Record<string, MainStemSharpHelpersPaginateFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  exportResults?: boolean
}

export interface MainStemAPIControllersAdminInvoicesListAPIResponse {
  /** @format int32 */
  filteredCount?: number
  invoices?: MainStemAPIControllersAdminInvoicesListTrackedInvoice[]
  /** @format int32 */
  totalCount?: number
  totals?: MainStemAPIControllersAdminInvoicesListTotalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminInvoicesListTotalDetails {
  /** @format int32 */
  count?: number
  title?: string
}

export interface MainStemAPIControllersAdminInvoicesUpdateAPIRequest {
  /** @format int32 */
  businessPartnerId?: number
  businessPartnerName?: string
  commaSeparatedTrackingNumbers?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateNotified?: string
  fulfillmentOrderNumber?: string
  hasProblem?: boolean
  /** @format int32 */
  invoiceID?: number
  /** @format int32 */
  invoicePartnerId?: number
  invoicePartnerName?: string
  /** @format double */
  invoiceTotal?: number
  isFulfilled?: boolean
  notes?: string
  /** @format int32 */
  orderId?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipmentCost?: number
  /** @format date-time */
  shipmentDate?: string
  /** @format double */
  supplierSalesTax?: number
  /** @format double */
  supplierShippingCost?: number
  trackingNumbers?: string[]
  type?: string
}

export interface MainStemAPIControllersAdminInvoicesUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsAPIResponse {
  locationPaymentOptions?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsLocationAllowablePaymentOptions[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsLocationAllowablePaymentOptions {
  /** @format int32 */
  locationID?: number
  name?: string
  imgUrl?: string
  paymentOptions?: MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions
}

export interface MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions {
  ach?: boolean
  creditCard?: boolean
}

export interface MainStemAPIControllersAdminLocationsCreateAPIRequest {
  address1?: string
  address2?: string
  businessType?: string
  city?: string
  country?: string
  /** @format int32 */
  customerId?: number
  email?: string
  name?: string
  phone?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminLocationsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponse {
  allowedPaymentOptions?: MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions
  location?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetails {
  addressBillTo?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsAddressDetails
  addressPhysical?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsAddressDetails
  addressShipTo?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsAddressDetails
  customer?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsCustomerDetails
  email?: string
  exemptionDetails?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsExemptionDetails
  freightContactEmail?: string
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactPhone?: string
  /** @format int32 */
  id?: number
  imgURL_Logo?: string
  name?: string
  notificationEmailNewOrders?: string[]
  notificationEmailReviewRequest?: string[]
  notificationEmails?: string[]
  notificationEmailShipmentUpdate?: string[]
  phone?: string
  quickbooksId?: string
  salesHistory?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsSalesHistoryDetails[]
  salesProductLines?: MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsSalesProductLinesDetails[]
  shippingAppointmentRequired?: boolean
  shippingDriverAssistanceRequired?: boolean
  shippingEquipmentLength?: string
  shippingEquipmentType?: string
  shippingForkliftOnSite?: boolean
  shippingGatedFacility?: boolean
  shippingInsideDelivery?: boolean
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsCustomerDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsExemptionDetails {
  exemptionHasExpired?: boolean
  hasResellerPermitExpiration?: boolean
  hasResellerPermitFile?: boolean
  hasResellerPermitIssued?: boolean
  /** @format date-time */
  resellerPermitExpiration?: string
  /** @format int32 */
  resellerPermitFileID?: number
  /** @format date-time */
  resellerPermitIssued?: string
  resellerPermitUrl?: string
  taxExempt?: boolean
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsSalesHistoryDetails {
  label?: string
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersAdminLocationsDetailsAPIResponseLocationDetailsSalesProductLinesDetails {
  label?: string
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersAdminLocationsListAPIResponse {
  locations?: MainStemAPIControllersAdminLocationsListAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsListAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  isCustomer?: boolean
  isSupplier?: boolean
  logoURL?: string
  name?: string
  state?: string
  tags?: string[]
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersAdminLocationsListOfOrganizationAPIRequest {
  /** @format int32 */
  customerID?: number
  uuid?: string
}

export interface MainStemAPIControllersAdminLocationsListOfOrganizationAPIResponse {
  locations?: MainStemAPIControllersAdminLocationsListOfOrganizationAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsListOfOrganizationAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  tags?: string[]
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersAdminLocationsOrdersListAPIRequest {
  /** @format int32 */
  customerId?: number
  locationIds?: number[]
}

export interface MainStemAPIControllersAdminLocationsOrdersListAPIResponse {
  error?: string
  orders?: MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetails {
  createdOn?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetailsLocationDetails
  manager?: string
  paymentStatus?: string
  status?: string
  /** @format double */
  totalAmount?: number
  uuid?: string
}

export interface MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetailsLocationDetails {
  address?: MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetailsLocationDetailsAddressDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminLocationsOrdersListAPIResponseOrderDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminLocationsUpdateAPIRequest {
  email?: string
  /** @format int32 */
  id?: number
  name?: string
  phone?: string
  exemptionDetails?: MainStemAPIControllersAdminLocationsUpdateAPIRequestExemptionDetails
  shippingAppointmentRequired?: boolean
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactEmail?: string
  freightContactPhone?: string
  shippingDriverAssistanceRequired?: boolean
  shippingForkliftOnSite?: boolean
  shippingInsideDelivery?: boolean
  shippingGatedFacility?: boolean
  shippingEquipmentType?: string
  shippingEquipmentLength?: string
}

export interface MainStemAPIControllersAdminLocationsUpdateAPIRequestExemptionDetails {
  /** @format date-time */
  resellerPermitExpiration?: string
  /** @format int32 */
  resellerPermitFileID?: number
  /** @format date-time */
  resellerPermitIssued?: string
  resellerPermitUrl?: string
  taxExempt?: boolean
}

export interface MainStemAPIControllersAdminLocationsUpdateAddressAPIRequest {
  addressType?: 'Physical' | 'Billing' | 'Shipping'
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  addressId?: number
}

export interface MainStemAPIControllersAdminLocationsUsersListAPIRequest {
  /** @format int32 */
  customerId?: number
  locationIds?: number[]
}

export interface MainStemAPIControllersAdminLocationsUsersListAPIResponse {
  users?: MainStemAPIControllersAdminLocationsUsersListAPIResponseUserDetails[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminLocationsUsersListAPIResponseUserDetails {
  friendlyName?: string
  role?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminLogsMainStemLogsAPIRequest {
  tableName?: string
  /** @format date-time */
  since?: string
}

export interface MainStemAPIControllersAdminLogsMainStemLogsAPIResponse {
  logs?: MainStemSharpModelsLoggingMainStemLogEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsLoggingMainStemLogEntry {
  request?: object
  response?: object
  partitionKey?: string
  rowKey?: string
  sessionId?: string
  message?: string
  /** @format date-time */
  timestamp?: string
  environment?: 'None' | 'Local' | 'Dev' | 'Demo' | 'Prod'
  userId?: string
  breadcrumb?: string
  level?: 'Debug' | 'Error' | 'Fatal' | 'Info' | 'Verbose' | 'Warning'
  data?: object
  stackTrace?: string
  note?: string
  error?: string
  status?: 'None' | 'Fail' | 'Partial' | 'Success'
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  paymentMethodID?: number
  payments?: MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIRequestPaymentDetails[]
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  bankName?: string
  customerTaxId?: string
  /** @format int32 */
  fulfillmentId?: number
  invoiceId?: string
  isACH?: boolean
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
  checkNumber?: string
  note?: string
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIResponse {
  invoices?: MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIResponseInvoiceDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIResponseInvoiceDetails {
  /** @format double */
  amount?: number
  error?: string
  /** @format int32 */
  fulfillmentId?: number
  invoiceId?: string
  /** @format int32 */
  orderId?: number
  uuid?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIRequest {
  /** @format int32 */
  paymentMethodId?: number
  payments?: MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIRequestPaymentDetails[]
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  bankName?: string
  customerTaxId?: string
  isACH?: boolean
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
  checkNumber?: string
  note?: string
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIResponse {
  invoices?: MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIResponseInvoiceDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIResponseInvoiceDetails {
  wasSuccessful?: boolean
  /** @format double */
  amount?: number
  error?: string
  uuid?: string
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersAdminReportingMarginExceptionAPIRequest {
  /** @format date-time */
  startDateFilter?: string
  /** @format date-time */
  endDateFilter?: string
}

export interface MainStemAPIControllersAdminReportingMarginExceptionAPIResponse {
  marginLineDetails?: MainStemAPIControllersAdminReportingMarginExceptionMarginLineDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminReportingMarginExceptionMarginLineDetail {
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  invoiceID?: number
  /** @format date-time */
  orderDateApproved?: string
  /** @format date-time */
  invoiceDateShipped?: string
  /** @format double */
  invoiceShippingCost?: number
  /** @format double */
  supplierShippingCost?: number
  /** @format int32 */
  customerID?: number
  customerName?: string
  locationName?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  orderLineItemQuantity?: number
  /** @format double */
  orderLineItemPricePerItem?: number
  /** @format double */
  supplierPricePerItemActual?: number
  /** @format double */
  supplierPricePerItemExpected?: number
  /** @format double */
  markUp?: number
  /** @format double */
  margin?: number
  /** @format int32 */
  productID?: number
  productName?: string
  productType?: string
  orderType?: string
  customerAccountType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format double */
  shippingCostDifference?: number
  /** @format double */
  expectedVsActualCostDifferencePerItem?: number
  /** @format double */
  markupDifferencePerItem?: number
}

export interface MainStemAPIControllersAdminOrdersCommunicationAPIRequest {
  /** @format int32 */
  orderID?: number
  fulfillmentMethodIds?: number[]
}

export interface MainStemAPIControllersAdminOrdersCommunicationAPIResponse {
  communications?: MainStemAPIControllersAdminOrdersCommunicationOrderCommunicationData[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersCommunicationOrderCommunicationData {
  actionResolved?: boolean
  actionResolvedByEmail?: string
  actionResolvedByFriendlyName?: string
  actionResolvedByID?: string
  /** @format int32 */
  businessPartnerID?: number
  businessPartnerName?: string
  comment?: string
  createdByUserEmail?: string
  createdByUserFriendlyName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateModified?: string
  /** @format date-time */
  dateResolved?: string
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentMethodUUID?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  /** @format int32 */
  orderID?: number
  requiresAction?: boolean
  userCreatedByID?: string
}

export interface MainStemAPIControllersAdminOrdersDuplicateAPIRequest {
  orderUUID?: string
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersAdminOrdersDuplicateAPIResponse {
  orderUUID?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersInvoiceItemsDeleteAPIRequest {
  /** @format int32 */
  invoiceItemID?: number
}

export interface MainStemAPIControllersAdminOrdersInvoiceItemsUpdateAPIRequest {
  /** @format int32 */
  invoiceItemID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  supplierPricePerItemActual?: number
}

export interface MainStemAPIControllersAdminOrderLineItemCreateAPIRequest {
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format int32 */
  productId?: number
  /** @format int32 */
  quantity?: number
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  isAdmin?: boolean
}

export interface MainStemAPIControllersAdminOrderLineItemDeleteAPIRequest {
  /** @format int32 */
  orderLineItemId?: number
}

export interface MainStemAPIControllersAdminOrderLineItemUpdateAPIRequest {
  /** @format int32 */
  orderLineItemId?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  price?: number
}

export interface MainStemAPIControllersAdminOrdersCancelAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIRequest {
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponse {
  customerIntegration?: MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerOrderIntegration
  orderGeneration?: MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderGenerationDetails
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  billTo?: MainStemAPIControllersAdminOrdersDetailsAPIResponseBillToDetails
  customerGLCode?: string
  /** @format int32 */
  customerID?: number
  customerPONumber?: string
  /** @format int32 */
  customerSessionID?: number
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  email?: string
  fulfillmentMethods: MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetails[]
  /** @format int32 */
  id: number
  lineItems: MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetails[]
  location: MainStemAPIControllersAdminOrdersDetailsAPIResponseLocationDetails
  /** @format int32 */
  locationID?: number
  orderTotal?: MainStemAPIHelpersMainStemCurrency
  paymentStatus?: string
  phone?: string
  purchaseOrders: MainStemAPIControllersAdminOrdersDetailsAPIResponsePurchaseOrderDetails[]
  rfqs?: MainStemAPIControllersAdminOrdersDetailsAPIResponseRfqBidOwner[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  shipTo?: MainStemAPIControllersAdminOrdersDetailsAPIResponseShipToDetails
  status?: string
  /** @format double */
  subTotal?: number
  /** @format double */
  supplierSalesTax?: number
  /** @format double */
  supplierShippingCost?: number
  termsOrderDetail?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerAPIResponse
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerOrderIntegration {
  sage?: MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerSageOrderIntegration
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderGenerationDetails {
  rfqDetails?: MainStemAPIControllersAdminOrdersDetailsAPIResponseRFQDetails
  sessionDetails?: MainStemAPIControllersAdminOrdersDetailsAPIResponseSessionDetails
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseBillToDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetails {
  invoiceProblems?: MainStemAPIControllersAdminInvoiceProblemsListAPIResponseInvoiceProblemDetail[]
  orderCommunications?: MainStemAPIControllersAdminOrdersCommunicationOrderCommunicationData[]
  avalaraTaxBreakDown?: AvalaraAvaTaxRestClientTransactionSummary[]
  customerIntegration?: MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerInvoiceIntegration
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  id?: number
  internalAccounting?: MainStemAPIControllersAdminOrdersDetailsAPIResponseInternalAccounting
  invoiceStatusDetail?: MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierInvoiceStatusDetail
  isPrivateSupplier?: boolean
  lineItems?: MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetails[]
  logoURL?: string
  name?: string
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  privateCustomerID?: number
  /** @format double */
  salesTax?: number
  shipments?: MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsShipmentDetails[]
  shipmentType?: string
  /** @format double */
  shipping?: number
  /** @format double */
  subTotal?: number
  /** @format int32 */
  supplierID?: number
  supplierIntegration?: MainStemAPIControllersAdminOrdersDetailsAPIResponseSupplierIntegration
  /** @format double */
  supplierSalesTax?: number
  /** @format double */
  supplierShippingCost?: number
  taxBreakdownJSON?: string
  type?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetails {
  /** @format int32 */
  id: number
  invoicedOn: MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetailsInvoicedOnDetails[]
  /** @format double */
  price: number
  product: MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetailsProductDetails
  /** @format int32 */
  quantity: number
  /** @format int32 */
  quantityToBeInvoiced: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqBidLineItemID?: number
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export type MainStemAPIHelpersMainStemCurrency = object

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponsePurchaseOrderDetails {
  /** @format int32 */
  id: number
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseRfqBidOwner {
  /** @format int32 */
  id?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  title?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseShipToDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerAPIResponse {
  invoiceCollectables?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerInvoiceCollectable[]
  additionalModifications?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  allTermLedgers?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  /** @format date-time */
  basedOnShippedDate?: string
  error?: string
  orderBalanceTerm?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails
  organizationHasTerms?: boolean
  /** @format int32 */
  organizationID?: number
  /** @format date-time */
  paymentDateDue?: string
  /** @format double */
  paymentDue?: number
  /** @format double */
  paymentDueSoon?: number
  /** @format double */
  paymentsTotal?: number
  termPayments?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  /** @format int32 */
  termsDays?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerSageOrderIntegration {
  orderId?: string
  requisitionId?: string
  syncSettings?: MainStemAPIControllersAdminOrdersDetailsAPIResponseSageSettings
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseRFQDetails {
  bidWinners?: MainStemAPIControllersAdminOrdersDetailsAPIResponseRfqBidOwner[]
  /** @format int32 */
  id?: number
  title?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseSessionDetails {
  description?: string
  fileUrl?: string
  name?: string
  sessionType?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerInvoiceIntegration {
  sage?: MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerSageInvoiceIntegration
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseInternalAccounting {
  invoiceId?: string
  invoiceState?: string
  purchaseOrderId?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierInvoiceStatusDetail {
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateViewed?: string
  /** @format int32 */
  id?: number
  status?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetails {
  /** @format int32 */
  id?: number
  /** @format int32 */
  orderLineItemID?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqBidLIneItemID?: number
  /** @format int32 */
  rfqID?: number
  /** @format double */
  supplierPricePerItemActual?: number
  /** @format double */
  supplierPricePerItemExpected?: number
  /** @format double */
  taxAmount?: number
  /** @format double */
  taxRate?: number
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseSupplierIntegration {
  integrationInvoiceID?: string
  integrationType?: string
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetailsInvoicedOnDetails {
  /** @format int32 */
  invoiceID: number
  /** @format int32 */
  invoiceItemId: number
  /** @format int32 */
  quantity: number
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetailsProductDetails {
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format int32 */
  id: number
  imageURL?: string
  isPrivate: boolean
  /** @format int32 */
  maximumOrderAmount?: number
  /** @format int32 */
  minimumOrderAmount?: number
  name?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  type?: string
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerInvoiceCollectable {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCollectable?: string
  /** @format date-time */
  earliestCollectable?: string
  isDue?: boolean
  /** @format date-time */
  shippedOn?: string
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  note?: string
  /** @format int32 */
  orderID?: number
  reason?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseSageSettings {
  canSyncInvoicesAtLocation?: boolean
  canSyncPurchaseOrdersAtLocation?: boolean
  canSyncPurchaseRequistionsAtLocation?: boolean
  enabled?: boolean
  workflowAuthoritySetting?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseCustomerSageInvoiceIntegration {
  invoiceId?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails {
  /** @format int32 */
  customerID?: number
  customerImageURL?: string
  customerName?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isPrivate?: boolean
  /** @format int32 */
  maximumOrderAmount?: number
  /** @format int32 */
  minimumOrderAmount?: number
  name?: string
  supplerImageURL?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  type?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsPaymentsAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponse {
  payments?: MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentDetails {
  /** @format int32 */
  id?: number
  paymentMethod?: MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentMethod
  /** @format double */
  amount?: number
  createdBy?: string
  /** @format date-time */
  dateCreated?: string
  last4?: string
  note?: string
  status?: string
  type?: string
  paymentType?: string
  quickBooksBillID?: string
  quickBooksBillPaymentID?: string
  internalAccounting?: MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponseInternalAccounting
  processor?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentMethod {
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  issuer?: string
  last4?: string
  name?: string
}

export interface MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponseInternalAccounting {
  paymentId?: string
  paymentState?: string
  invoicePaymentLinks?: string[]
  invoicePaymentLinkId?: string
  settlementStatus?: string
  /** @format date-time */
  settlementDate?: string
}

export interface MainStemAPIControllersAdminOrdersListAPIRequest {
  allPages?: boolean
  sortField?: string
  sortOrder?: string
  filters?: Record<string, MainStemSharpHelpersPaginateFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  exportResults?: boolean
}

export interface MainStemAPIControllersAdminOrdersListAPIResponse {
  /** @format int32 */
  filteredCount?: number
  orders?: MainStemAPIControllersAdminOrdersListAPIResponseOrderDetails[]
  /** @format int32 */
  totalOrders?: number
  totals?: MainStemAPIControllersAdminOrdersListAPIResponseTotalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersListAPIResponseOrderDetails {
  createdOn?: string
  /** @format date-time */
  dateCreated?: string
  hasPrivateSuppliers?: boolean
  hasVerifiedSuppliers?: boolean
  /** @format int32 */
  id?: number
  locationAddress1?: string
  locationAddress2?: string
  /** @format int32 */
  locationAddressId?: number
  locationCity?: string
  locationCountry?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  locationState?: string
  locationZip?: string
  manager?: string
  orderStatus?:
    | 'Open'
    | 'Approved'
    | 'CantFulfill'
    | 'Cancelled'
    | 'Return'
    | 'Fulfillment'
    | 'Shipping'
    | 'Closed'
    | 'Complete'
  paymentStatus?: string
  paymentStatusComplete?: boolean
  /** @format double */
  totalAmount?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminOrdersListAPIResponseTotalDetails {
  /** @format int32 */
  count?: number
  /** @format int32 */
  filterVal?: number
  title?: string
}

export interface MainStemAPIControllersAdminOrdersSubmitApproveAPIRequest {
  orderUUID?: string
  paymentMethods?: MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails[]
  privateSuppliers?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillments[]
  shippingOptions?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestShippingOptionsInfo[]
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  applyPaymentImmediately?: boolean
  bankName?: string
  customerTaxId?: string
  isACH?: boolean
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillments {
  lineItems?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillmentsLineItemDetails[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestShippingOptionsInfo {
  /** @format int32 */
  optionId?: number
  /** @format double */
  price?: number
  /** @format double */
  salesTax?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillmentsLineItemDetails {
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminOrdersSubmitApproveAPIResponse {
  paymentSuccessful?: boolean
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersUpdateAddressAPIRequest {
  orderUUID?: string
  /** @format int32 */
  id?: number
  type?: 'ShipTo' | 'BillTo'
  address1?: string
  address2?: string
  city?: string
  name?: string
  state?: string
  country?: string
  zip?: string
}

export interface MainStemAPIControllersAdminParentProductTypeCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersAdminParentProductTypeCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminParentProductTypeDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminParentProductTypeDetailsAPIResponse {
  parentProductType?: MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminParentProductTypeDetailsAPIResponseParentProductType {
  /** @format int32 */
  id?: number
  name?: string
}

export type MainStemAPIControllersAdminParentProductTypeListAPIRequest = object

export interface MainStemAPIControllersAdminParentProductTypeListAPIResponse {
  parentProductTypes?: MainStemAPIControllersAdminParentProductTypeListAPIResponseParentProductType[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminParentProductTypeListAPIResponseParentProductType {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminParentProductTypeUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminParentProductTypeUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentAPIRequest {
  ids?: number[]
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentAPIResponse {
  error?: string
  paymentLinks?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentPaymentLinks[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentPaymentLinks {
  /** @format double */
  amount?: number
  /** @format int32 */
  bulkInvoicePaymentId?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fulfillmentId?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  paymentTransactionId?: number
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIRequest {
  ids?: number[]
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIResponse {
  combinedTotals?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderTotalDetails
  invoicedLineItemsDetails?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderLineItemTotals
  orderLineItemsDetails?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderLineItemTotals
  privateTotals?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderTotalDetails
  verifiedTotals?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderTotalDetails
  error?: string
  orderBalance?: MainStemSharpHelpersOrderBreakdown
  invoiceBalances?: MainStemSharpModelsInvoicesInvoiceBalanceDetails[]
  paymentLinks?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentLinks[]
  /** @format double */
  balance?: number
  /** @format double */
  allPaymentsMade?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderTotalDetails {
  /** @format double */
  total?: number
  /** @format double */
  subTotal?: number
  /** @format double */
  shipping?: number
  /** @format double */
  salesTax?: number
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderLineItemTotals {
  /** @format int32 */
  totalQuantity?: number
  /** @format int32 */
  uniqueLineItems?: number
  /** @format double */
  subTotal?: number
  /** @format double */
  privateSubTotal?: number
  /** @format double */
  verifiedSubTotal?: number
}

export interface MainStemSharpHelpersOrderBreakdown {
  /** @format double */
  discount?: number
  fulfillmentMethods?: MainStemSharpDataModelsFulfillmentMethod[]
  /** @format double */
  lineItemCost?: number
  orderLineItems?: MainStemSharpHelpersOrderLineItemDetail[]
  /** @format double */
  orderTotal?: number
  paymentTransactions?: MainStemSharpDataModelsPaymentTransaction[]
  refundedOrderLines?: MainStemSharpHelpersOrderLineItemDetail[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format double */
  totalLineItemCostReturned?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalPaymentsRefunded?: number
  /** @format double */
  totalRemaining?: number
  /** @format double */
  totalReturnAmount?: number
  /** @format double */
  totalSalesTaxReturned?: number
  /** @format double */
  totalShippingCostReturned?: number
}

export interface MainStemSharpModelsInvoicesInvoiceBalanceDetails {
  /** @format double */
  allFulfillmentTotals?: number
  /** @format int32 */
  supplierId?: number
  supplierName?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  invoiceId?: number
  id?: string
  /** @format double */
  invoicePaymentsMade?: number
  /** @format double */
  subTotal?: number
  /** @format int32 */
  orderId?: number
  /** @format double */
  orderPaymentsMade?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  termsApplied?: boolean
  /** @format double */
  total?: number
  userId?: string
  userName?: string
  isPrivate?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentLinks {
  /** @format double */
  amount?: number
  /** @format int32 */
  bulkInvoicePaymentId?: number
  /** @format date-time */
  dateCreated?: string
  fulfillmentDetail?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderFulfillmentMethodDetail
  /** @format int32 */
  fulfillmentId?: number
  /** @format int32 */
  id?: number
  paymentTransactionDetail?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentTransactionDetail
  /** @format int32 */
  paymentTransactionId?: number
}

export interface MainStemSharpHelpersOrderLineItemDetail {
  /** @format double */
  amount?: number
  /** @format double */
  discountEach?: number
  note?: string
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  priceEach?: number
  priceString?: string
  productName?: string
  /** @format int32 */
  quantity?: number
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
}

export interface MainStemSharpDataModelsPaymentTransaction {
  /** @format double */
  amount?: number
  checkoutUrl?: string
  createdBy?: string
  customerQuickBooksBillID?: string
  customerQuickBooksBillPaymentID?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  invoiceID?: string
  invoiceLinks?: string[]
  note?: string
  paidFromOrder?: MainStemSharpDataModelsOrder
  /** @format int32 */
  paidFromOrderId?: number
  payment?: MainStemSharpDataModelsPayment
  /** @format int32 */
  paymentID?: number
  paymentMethod?: MainStemSharpDataModelsPaymentMethod
  /** @format int32 */
  paymentMethodID?: number
  processor?: string
  quickBooksID?: string
  quickBooksLinkID?: string
  quickBooksPaymentState?: 'Open' | 'Partial' | 'Closed' | 'Ignore'
  /** @format date-time */
  settlementDate?: string
  settlementStatus?: string
  status?: 'Open' | 'Approved' | 'Denied' | 'Voided'
  type?:
    | 'CreditCard'
    | 'DebitCard'
    | 'Check'
    | 'StoreCredit'
    | 'Cash'
    | 'Refund'
    | 'VendorPayment'
    | 'Voided'
    | 'PayQwick'
    | 'ACH'
    | 'Terms'
    | 'Lendica'
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderFulfillmentMethodDetail {
  /** @format double */
  total?: number
  /** @format double */
  subTotal?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  shipmentType?: string
  subType?: string
  type?: string
  /** @format int32 */
  vendorId?: number
  vendorName?: string
  isPrivate?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentTransactionDetail {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  paymentMethod?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentMethod
  paymentStatus?: string
  paymentType?: string
  transactionCreatedBy?: string
  transactionProcessor?: string
  transactionStatus?: string
  transactionType?: string
}

export interface MainStemSharpDataModelsPayment {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDue?: string
  /** @format int32 */
  id?: number
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  status?: 'Open' | 'Partial' | 'Complete' | 'Cancelled'
  type?: 'Debit' | 'Credit'
}

export interface MainStemSharpDataModelsPaymentMethod {
  address1?: string
  address2?: string
  archivedBy?: string
  authorizeNetPaymentID?: string
  ccv?: string
  city?: string
  country?: string
  customerVaultID?: string
  /** @format date-time */
  dateArchived?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  isArchived?: boolean
  isDefault?: boolean
  isDeleted?: boolean
  issuer?: string
  last4OfACH?: string
  last4OfCard?: string
  nameOnCard?: string
  nickname?: string
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  state?: string
  type?: 'CreditCard' | 'ACH'
  usAePayToken?: string
  zip?: string
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderPaymentMethod {
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  issuer?: string
  last4?: string
  name?: string
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnPaymentTransactionAPIRequest {
  ids?: number[]
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnPaymentTransactionAPIResponse {
  error?: string
  paymentLinks?: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnPaymentTransactionPaymentLinks[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentLinksOnPaymentTransactionPaymentLinks {
  /** @format double */
  amount?: number
  /** @format int32 */
  bulkInvoicePaymentId?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fulfillmentId?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  paymentTransactionId?: number
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateACHAPIRequest {
  accountNumber?: string
  accountType?: string
  bankName?: string
  /** @format int32 */
  locationId?: number
  nameOnAccount?: string
  nickname?: string
  routingNumber?: string
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateACHAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateCreditCardAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  cvc?: string
  /** @format int32 */
  expirationMonth?: number
  /** @format int32 */
  expirationYear?: number
  /** @format int32 */
  locationId?: number
  name?: string
  number?: string
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateCreditCardAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIRequest {
  /** @format int32 */
  customerID?: number
  locationIDs?: number[]
  allowArchived?: boolean
  allowDeleted?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponse {
  achs?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseAccountDetails[]
  allowedOptions?: MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions
  creditCards?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseCreditCardDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseAccountDetails {
  /** @format int32 */
  id?: number
  isArchived?: boolean
  isDeleted?: boolean
  last4OfACH?: string
  location?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseLocationDetails
  nickname?: string
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseCreditCardDetails {
  authNetID?: string
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  isArchived?: boolean
  isDeleted?: boolean
  issuer?: string
  last4OfCard?: string
  location?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseLocationDetails
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedAPIRequest {
  ids?: number[]
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedAPIResponse {
  error?: string
  paymentTransactions?: MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentTransactionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentTransactionDetail {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  paymentMethod?: MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentMethod
  paymentStatus?: string
  paymentType?: string
  transactionCreatedBy?: string
  transactionProcessor?: string
  transactionStatus?: string
  transactionType?: string
  /** @format double */
  unLinkedAmount?: number
}

export interface MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentMethod {
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  issuer?: string
  last4?: string
  name?: string
}

export interface MainStemAPIControllersAdminPermissionCreateAPIRequest {
  displayName?: string
  group?: string
  shortCode?: string
  type?: string
}

export interface MainStemAPIControllersAdminPermissionCreateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionDeleteAPIRequest {
  deleteUserPermissions?: boolean
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminPermissionGroupAddUserToGroupAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupCreateAPIRequest {
  name?: string
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupCreateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupDeleteAPIRequest {
  /** @format int32 */
  id?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListAPIRequest {
  type?: string
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListAPIResponse {
  permissionGroups?: MainStemAPIControllersAdminPermissionGroupListPermissionGroupDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupListPermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  permissionGroupValues?: MainStemAPIControllersAdminPermissionGroupListPermissionGroupValueDetail[]
  /** @format int32 */
  permissionsAssigned?: number
  /** @format int32 */
  totalPermissionsCount?: number
  /** @format int32 */
  usersAssigned?: number
  usersAssignedToGroup?: MainStemAPIControllersAdminPermissionGroupListAssignedUserDetail[]
}

export interface MainStemAPIControllersAdminPermissionGroupListPermissionGroupValueDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionId?: number
  shortCode?: string
  type?: string
  value?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListAssignedUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListByTypeAPIRequest {
  /** @format int32 */
  organizationID?: number
  /** @format int32 */
  supplierID?: number
  type?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListByTypeAPIResponse {
  permissionGroups?: MainStemAPIControllersAdminPermissionGroupListByTypePermissionGroupDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupListByTypePermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  permissionGroupValues?: MainStemAPIControllersAdminPermissionGroupListByTypePermissionGroupValueDetail[]
  /** @format int32 */
  permissionsAssigned?: number
  /** @format int32 */
  totalPermissionsCount?: number
  /** @format int32 */
  usersAssigned?: number
  usersAssignedToGroup?: MainStemAPIControllersAdminPermissionGroupListByTypeAssignedUserDetail[]
}

export interface MainStemAPIControllersAdminPermissionGroupListByTypePermissionGroupValueDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionId?: number
  shortCode?: string
  type?: string
  value?: string
}

export interface MainStemAPIControllersAdminPermissionGroupListByTypeAssignedUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminPermissionGroupRemoveUserFromGroupAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupUpdateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAPIResponse {
  usersNotInGroup?: MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAvailableForAssignmentUserDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAvailableForAssignmentUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueCreateAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  /** @format int32 */
  permissionId?: number
  userId?: string
  value?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueCreateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupValueDeleteAPIRequest {
  /** @format int32 */
  id?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueListAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueListAPIResponse {
  permissionGroupDetail?: MainStemAPIControllersAdminPermissionGroupValueListPermissionGroupDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionGroupValueListPermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  permissionGroupValues?: MainStemAPIControllersAdminPermissionGroupValueListPermissionGroupValueDetail[]
  /** @format int32 */
  totalPermissionsCount?: number
  usersAssigned?: MainStemAPIControllersAdminPermissionGroupValueListAssignedUserDetail[]
}

export interface MainStemAPIControllersAdminPermissionGroupValueListPermissionGroupValueDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionId?: number
  shortCode?: string
  type?: string
  value?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueListAssignedUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionGroupId?: number
  /** @format int32 */
  permissionId?: number
  type?: string
  userId?: string
  value?: string
}

export interface MainStemAPIControllersAdminPermissionGroupValueUpdateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionListAPIRequest {
  type?: string
}

export interface MainStemAPIControllersAdminPermissionListAPIResponse {
  error?: string
  permissions?: MainStemAPIControllersAdminPermissionListPermissionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPermissionListPermissionDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  rank?: number
  shortCode?: string
  type?: string
}

export interface MainStemAPIControllersAdminPermissionUpdateAPIRequest {
  applicationType?: string
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
}

export interface MainStemAPIControllersAdminPermissionUpdateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPriceAgreementsCreateAPIRequest {
  /** @format double */
  cost?: number
  /** @format int32 */
  customerId?: number
  /** @format double */
  margin?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productId?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersAdminPriceAgreementsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPriceAgreementsDeleteAPIRequest {
  /** @format int32 */
  priceAgreementId?: number
}

export interface MainStemAPIControllersAdminPriceAgreementsDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerProcessDropshipAPIRequest {
  manualOverride?: boolean
  /** @format int32 */
  orderID?: number
}

export interface MainStemAPIControllersAdminProductsAttributeValuesCreateAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductsAttributeValuesDeleteAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemSharpServiceBusProductCleanRequest {
  fullIndex?: boolean
  productIDs?: number[]
  environment?: 'None' | 'Local' | 'Dev' | 'Demo' | 'Prod'
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  logSessionID?: string
  requestBody?: string
  requestingUserID?: string
  requestType?: string
}

export interface MainStemSharpServiceBusServiceBusResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponse {
  bulkFileUploads?: MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponseProductBulkFileUpload[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponseProductBulkFileUpload {
  /** @format date-time */
  dateCompleted?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fileResultsID?: number
  /** @format int32 */
  fileUploadedID?: number
  /** @format int32 */
  id?: number
  requestFileLocation?: string
  requestFileName?: string
  requestOriginalFileName?: string
  responseFileLocation?: string
  responseFileName?: string
  status?: 'Failed' | 'Open' | 'Processing' | 'Completed' | 'CompletedWithErrors'
  type?:
    | 'BulkProductUpload'
    | 'InvoiceBillUpload'
    | 'ProductSync'
    | 'BulkMenuItemUpload'
    | 'BulkMenuProductUploadAppend'
    | 'BulkMenuProductUploadReplace'
    | 'StaplesBulkUpload'
  user?: MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponseProductBulkFileUploadUserDetails
}

export interface MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponseProductBulkFileUploadUserDetails {
  id?: string
  username?: string
}

export interface MainStemAPIControllersAdminProductsChildCreateAPIRequest {
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminProductsChildCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponse {
  product?: MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetails {
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  attributeValues?: MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsAttributeValueDetails[]
  customsDescription?: string
  customsHarmonizedTarrifCodes?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedBackInStock?: string
  /** @format date-time */
  dateLastUpdated?: string
  /** @format date-time */
  datePriceLastUpdated?: string
  /** @format int32 */
  daysToFulfill?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  /** @format double */
  flatShippingCost?: number
  freeShipping?: boolean
  freightShippingRequired?: boolean
  hasVolumePricing?: boolean
  /** @format double */
  hazmatFee?: number
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  image?: string
  images?: string[]
  isApproved?: boolean
  isClean?: boolean
  isCustomizable?: boolean
  isCustomProduct?: boolean
  isDeleted?: boolean
  isDroppedShipped?: boolean
  isNonCancellable?: boolean
  isNonReturnable?: boolean
  isOutOfStock?: boolean
  isPrivate?: boolean
  isShippable?: boolean
  /** @format double */
  length?: number
  /** @format int32 */
  manufacturerID?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  /** @format int32 */
  minimumPurchasableQuantity?: number
  /** @format double */
  msrp?: number
  name?: string
  parentProduct?: MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsParentProduct
  priceAgreements?: MainStemAPIControllersAdminProductsDetailsAPIResponsePriceAgreementDetails[]
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  quickBooksId?: string
  showInStore?: boolean
  skuInternal?: string
  skuManufacturer?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  supplier?: MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsSupplierDetails
  supplierPartNumber?: string
  tags?: MainStemAPIControllersAdminProductsDetailsAPIResponseTagDetails[]
  taxCode?: string
  taxExempt?: boolean
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  volumePricing?: MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsVolumePricingDetails[]
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsAttributeValueDetails {
  attribute?: string
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsParentProduct {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponsePriceAgreementDetails {
  /** @format double */
  cost?: number
  customer?: MainStemAPIControllersAdminProductsDetailsAPIResponsePriceAgreementDetailsCustomerDetails
  /** @format int32 */
  id?: number
  /** @format double */
  margin?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsSupplierDetails {
  city?: string
  country?: string
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  logo?: string
  name?: string
  state?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponseTagDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetailsVolumePricingDetails {
  /** @format double */
  cost?: number
  /** @format int32 */
  id?: number
  /** @format double */
  margin?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponsePriceAgreementDetailsCustomerDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsChildDuplicateAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductsChildDuplicateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsChildListAPIRequest {
  filter?: MainStemAPIControllersAdminProductsChildListFilterDetail
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
}

export interface MainStemAPIControllersAdminProductsChildListFilterDetail {
  /** @format int32 */
  id?: number
  name?: string
  skuInternal?: string
  skuManufacturer?: string
  supplierPartNumber?: string
}

export interface MainStemAPIControllersAdminProductsChildListAPIResponse {
  products?: MainStemAPIControllersAdminProductsChildListAPIResponseProductDetails[]
  /** @format int32 */
  total?: number
  /** @format int32 */
  totalFiltered?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsChildListAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  supplier?: MainStemAPIControllersAdminProductsChildListAPIResponseProductDetailsSupplierDetails
}

export interface MainStemAPIControllersAdminProductsChildListAPIResponseProductDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsChildUpdateAPIRequest {
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  customsDescription?: string
  customsHarmonizedTarrifCodes?: string
  /** @format int32 */
  daysToFulfill?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  /** @format double */
  flatShippingCost?: number
  freeShipping?: boolean
  freightShippingRequired?: boolean
  hasVolumePricing?: boolean
  /** @format double */
  hazmatFee?: number
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  isApproved?: boolean
  isClean?: boolean
  isCustomizable?: boolean
  isCustomProduct?: boolean
  isDeleted?: boolean
  isDroppedShipped?: boolean
  isNonCancellable?: boolean
  isNonReturnable?: boolean
  isOutOfStock?: boolean
  isShippable?: boolean
  /** @format double */
  length?: number
  /** @format int32 */
  manufacturerID?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  /** @format int32 */
  minimumPurchasableQuantity?: number
  /** @format double */
  msrp?: number
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  quickBooksId?: string
  showInStore?: boolean
  skuInternal?: string
  skuManufacturer?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  /** @format int32 */
  supplierId?: number
  supplierPartNumber?: string
  taxCode?: string
  taxExempt?: boolean
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersAdminProductsChildUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsCreateAPIRequest {
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminProductsCreateAPIResponse {
  error?: string
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponse {
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  attributeValues?: MainStemAPIControllersAdminProductsDetailsAttributeValueDetails[]
  /** @format int32 */
  brandID?: number
  customsDescription?: string
  customsHarmonizedTarrifCodes?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedBackInStock?: string
  /** @format date-time */
  dateLastUpdated?: string
  /** @format date-time */
  datePriceLastUpdated?: string
  /** @format int32 */
  daysToFulfill?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  dropShippedItem?: boolean
  /** @format double */
  flatShippingCost?: number
  freeShipping?: boolean
  freightShippingRequired?: boolean
  hasVolumePricing?: boolean
  /** @format double */
  hazmatFee?: number
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  image?: string
  images?: string[]
  isApproved?: boolean
  isChildProduct?: boolean
  isClean?: boolean
  isCustomizable?: boolean
  isCustomProduct?: boolean
  isDeleted?: boolean
  isNonCancellable?: boolean
  isNonReturnable?: boolean
  isOutOfStock?: boolean
  isParentProduct?: boolean
  isPrivate?: boolean
  isShippable?: boolean
  itemMaster?: boolean
  /** @format double */
  length?: number
  /** @format int32 */
  manufacturerID?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  menuItems?: MainStemAPIControllersAdminProductsDetailsMenuItemDetails[]
  /** @format int32 */
  minimumPurchasableQuantity?: number
  /** @format double */
  msrp?: number
  name?: string
  /** @format int32 */
  parentProductID?: number
  parentProductName?: string
  priceAgreements?: MainStemAPIControllersAdminProductsDetailsAPIResponsePriceAgreementDetails[]
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  productChildrenDetails?: MainStemAPIControllersAdminProductsDetailsAPIResponseProductChildrenGroup
  quickBooksId?: string
  relatedChildrenProductsOfParent?: MainStemAPIControllersAdminProductsDetailsAPIResponseProductChildrenGroup
  showInStore?: boolean
  skuInternal?: string
  skuManufacturer?: string
  supplier?: MainStemAPIControllersAdminProductsDetailsAPIResponseSupplierDetails
  tags?: MainStemAPIControllersAdminProductsDetailsAPIResponseTagDetails[]
  taxCode?: string
  taxExempt?: boolean
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  usnpsc?: MainStemAPIControllersAdminProductsDetailsAPIResponseUSNPSCDetails
  volumePricing?: MainStemAPIControllersAdminProductsDetailsVolumePricingDetails[]
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsAttributeValueDetails {
  attribute?: string
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersAdminProductsDetailsMenuItemDetails {
  childMenu?: string
  grandParentMenu?: string
  /** @format int32 */
  id?: number
  parentMenu?: string
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponseProductChildrenGroup {
  childProducts?: MainStemAPIControllersAdminProductsDetailsAPIResponseChildProductDetails[]
  /** @format double */
  highestEnterprisePrice?: number
  /** @format double */
  highestRetailPrice?: number
  /** @format double */
  highestWholesalePrice?: number
  /** @format double */
  lowestEnterprisePrice?: number
  /** @format double */
  lowestRetailPrice?: number
  /** @format double */
  lowestWholesalePrice?: number
  /** @format int32 */
  supplierCount?: number
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponseSupplierDetails {
  city?: string
  country?: string
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  logo?: string
  name?: string
  state?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponseUSNPSCDetails {
  /** @format int32 */
  class?: number
  classTitle?: string
  /** @format int32 */
  commodity?: number
  commodityTitle?: string
  /** @format int32 */
  family?: number
  familyTitle?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  segment?: number
  segmentTitle?: string
}

export interface MainStemAPIControllersAdminProductsDetailsVolumePricingDetails {
  /** @format double */
  cost?: number
  /** @format int32 */
  id?: number
  /** @format double */
  margin?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersAdminProductsDetailsAPIResponseChildProductDetails {
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  /** @format int32 */
  id?: number
  isApproved?: boolean
  isDeleted?: boolean
  name?: string
  outOfStock?: boolean
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  showInStore?: boolean
  supplier?: MainStemAPIControllersAdminProductsDetailsAPIResponseSupplierDetails
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersAdminProductsDetailsOrdersAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductsDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersAdminProductsDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminProductsDetailsOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminProductsDetailsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminProductsDetailsProductListsAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductsDetailsProductListsAPIResponse {
  productLists?: MainStemAPIControllersAdminProductsDetailsProductListsAPIResponseProductListDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsProductListsAPIResponseProductListDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsDetailsShipmentsAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductsDetailsShipmentsAPIResponse {
  shipments?: MainStemAPIControllersAdminProductsDetailsShipmentsAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsDetailsShipmentsAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminProductsParentClassificationAddAPIRequest {
  /** @format int32 */
  classificationId?: number
  /** @format int32 */
  parentProductId?: number
}

export interface MainStemAPIControllersAdminProductsParentClassificationAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsParentClassificationRemoveAPIRequest {
  /** @format int32 */
  classificationId?: number
  /** @format int32 */
  parentProductId?: number
}

export interface MainStemAPIControllersAdminProductsParentClassificationRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsParentCreateAPIRequest {
  name?: string
  /** @format int32 */
  parentProductTypeId?: number
}

export interface MainStemAPIControllersAdminProductsParentCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponse {
  childProducts?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseChildProductDetails[]
  collections?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionClassification[]
  parentProduct?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseParentProductDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseChildProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  supplier?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseChildProductDetailsSupplierDetails
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionClassification {
  /** @format int32 */
  id?: number
  name?: string
  subCategory?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionSubCategory
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseParentProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  isDeleted?: boolean
  name?: string
  showInStore?: boolean
  type?: MainStemSharpDataModelsParentProductType
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseChildProductDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionSubCategory {
  category?: MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionCategory
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsParentDetailsAPIResponseCollectionCategory {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsParentListAPIRequest {
  filter?: MainStemAPIControllersAdminProductsParentListFilterDetail
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
}

export interface MainStemAPIControllersAdminProductsParentListFilterDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductsParentListAPIResponse {
  parentProducts?: MainStemAPIControllersAdminProductsParentListAPIResponseParentProductDetails[]
  /** @format int32 */
  total?: number
  /** @format int32 */
  totalFiltered?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsParentListAPIResponseParentProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  type?: MainStemSharpDataModelsParentProductType
}

export interface MainStemAPIControllersAdminProductsParentUpdateAPIRequest {
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  name?: string
  /** @format int32 */
  parentProductTypeId?: number
  showInStore?: boolean
}

export interface MainStemAPIControllersAdminProductsParentUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsScrapeAPIRequest {
  url: string
}

export interface MainStemAPIControllersAdminProductsScrapeAPIResponse {
  product?: MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetails {
  mainImage?: MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetailsImageDetails
  name?: string
  /** @format double */
  price?: number
  sku?: string
}

export interface MainStemAPIControllersAdminProductsScrapeModelsZyteAPIResponseProductDetailsImageDetails {
  url?: string
}

export interface MainStemAPIControllersAdminProductTagsAddTagsToProductAPIRequest {
  /** @format int32 */
  productID?: number
  tagIDs?: number[]
}

export interface MainStemAPIControllersAdminProductsTagsDeleteAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersAdminProductTagsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminProductTagsDetailsAPIResponse {
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemAPIControllersAdminProductTagsDetailsAPIResponseProductTagProduct[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductTagsDetailsAPIResponseProductTagProduct {
  /** @format int32 */
  id?: number
  name?: string
  isPrivate?: boolean
  ownerName?: string
}

export interface MainStemAPIControllersAdminProductTagsListAPIResponse {
  productTags?: MainStemAPIControllersAdminProductTagsListProductTagDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductTagsListProductTagDetail {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  productsUsingTag?: number
  /** @format int32 */
  suppliersUsingTag?: number
}

export interface MainStemAPIControllersAdminProductTagsListForProductAPIRequest {
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersAdminProductTagsListForProductAPIResponse {
  productTags?: MainStemAPIControllersAdminProductTagsListForProductAPIResponseProductGroupDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminProductTagsListForProductAPIResponseProductGroupDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminProductTagsRemoveFromProductAPIRequest {
  /** @format int32 */
  productID?: number
  /** @format int32 */
  tagID?: number
}

export interface MainStemAPIControllersAdminProductsVolumePricingCreateAPIRequest {
  /** @format double */
  cost?: number
  /** @format double */
  margin?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productId?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersAdminProductsVolumePricingDeleteAPIRequest {
  /** @format int32 */
  productVolumePricingId?: number
}

export interface MainStemAPIControllersAdminPunchoutsRequestSessionAPIRequest {
  /** @format int32 */
  integrationSettingID?: number
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminPunchoutsRequestSessionAPIResponse {
  url?: string
  cXmlDocument?: string
  redirectUrl?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPunchoutsSettingsAddLocationsAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  customerSettingsID?: number
}

export interface MainStemAPIControllersAdminPunchoutsSettingsCreatePunchoutAPIRequest {
  isActive?: boolean
  /** @format int32 */
  customerID?: number
  punchoutSupplierName?: string
  punchoutSupplierEmail?: string
  description?: string
  defaultOrderProcessingEmail?: string
  cXmlOrderProcessingAllowed?: boolean
  locationIds?: number[]
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
}

export interface MainStemAPIControllersAdminPunchoutsSettingsCreatePunchoutAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPunchoutsSettingsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminPunchoutsSettingsDetailsAPIResponse {
  /** @format int32 */
  customerID?: number
  description?: string
  /** @format int32 */
  id?: number
  isActive?: boolean
  punchoutSupplierName?: string
  punchoutSupplierEmail?: string
  punchoutUrl?: string
  fileUrl?: string
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  cXmlOrderProcessingAllowed?: boolean
  defaultOrderProcessingEmail?: string
  assignedLocations?: MainStemAPIControllersAdminPunchoutsSettingsDetailsLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPunchoutsSettingsDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminPunchoutsSettingsListAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminPunchoutsSettingsListAPIResponse {
  list?: MainStemAPIControllersAdminPunchoutsSettingsListAPIResponsePunchoutDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPunchoutsSettingsListAPIResponsePunchoutDetail {
  description?: string
  /** @format int32 */
  id?: number
  isActive?: boolean
  punchoutSupplierName?: string
  punchoutSupplierEmail?: string
  /** @format int32 */
  privateSupplierID?: number
  fileUrl?: string
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
  cXmlOrderProcessingAllowed?: boolean
  defaultOrderProcessingEmail?: string
  assignedLocations?: MainStemAPIControllersAdminPunchoutsSettingsListLocationDetails[]
}

export interface MainStemAPIControllersAdminPunchoutsSettingsListLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminPunchoutsSettingsRemoveLocationsAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  customerSettingsID?: number
}

export interface MainStemAPIControllersAdminPunchoutsSettingsUpdateAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  id?: number
  isActive?: boolean
  punchoutSupplierName?: string
  punchoutSupplierEmail?: string
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
  cXmlOrderProcessingAllowed?: boolean
  defaultOrderProcessingEmail?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminPurchaseOrdersDetailsAPIResponse {
  purchaseOrder?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails {
  alerts?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderAlertDetails[]
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateSalesTaxCalculated?: string
  /** @format date-time */
  dateShippingCalculated?: string
  discounts?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsDiscountDetails[]
  fees?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsFeeDetails[]
  freightCollect?: boolean
  /** @format int32 */
  id?: number
  isPunchOutGenerated?: boolean
  lineItems?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetails[]
  /** @format int32 */
  orderSupplierId?: number
  requisition?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsRequisitionDetails
  salesTax?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSalesTaxDescriptionDetails[]
  shipments?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetails[]
  shippingDetails?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsShippingDetails
  supplier?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetails
  totals?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderTotalsDetails
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderAlertDetails {
  content?: string
  title?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsDiscountDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  notes?: string
  reason?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsFeeDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  notes?: string
  reason?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetails {
  /** @format double */
  discountsPerItem?: number
  /** @format double */
  feesPerItem?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTaxPerItem?: number
  /** @format double */
  shippingPerItem?: number
  /** @format double */
  total?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsRequisitionDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSalesTaxDescriptionDetails {
  /** @format double */
  amount?: number
  jurisdiction?: string
  name?: string
  /** @format double */
  rate?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateShippedOn?: string
  location?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetails
  status?: string
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsShippingDetails {
  carrier?: string
  frieghtCollect?: boolean
  log?: string[]
  method?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetails {
  bandedShippingRates?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetailsBandedShippingRateDetails[]
  city?: string
  country?: string
  freightShippingRequired?: boolean
  /** @format int32 */
  freightWeightLimit?: number
  hasBandedShipping?: boolean
  hasDirectPay?: boolean
  hasPreferredShipping?: boolean
  /** @format int32 */
  id?: number
  isVerified?: boolean
  logo?: string
  name?: string
  state?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderTotalsDetails {
  /** @format double */
  discounts?: number
  /** @format double */
  fees?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subtotal?: number
  /** @format double */
  total?: number
  /** @format double */
  weight?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetailsProductDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
  type?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetails {
  address?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetailsAddressDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetailsBandedShippingRateDetails {
  /** @format double */
  amount?: number
  /** @format double */
  orderTotalLowerLimit?: number
  /** @format double */
  orderTotalUpperLimit?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersDiscountsCreateAPIRequest {
  /** @format double */
  amount?: number
  notes?: string
  /** @format int32 */
  purchaseOrderId?: number
  reason?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersDiscountsCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPurchaseOrdersFeesCreateAPIRequest {
  /** @format double */
  amount?: number
  notes?: string
  /** @format int32 */
  purchaseOrderId?: number
  reason?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersFeesCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponse {
  purchaseOrders?: MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsLocationDetails
  order?: MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsOrderDetails
  requisition?: MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsRequisitionDetails
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subtotal?: number
  supplier?: MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsSupplierDetails
  /** @format double */
  total?: number
  /** @format double */
  totalFees?: number
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsOrderDetails {
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsRequisitionDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  uuid?: string
}

export interface MainStemAPIControllersAdminPurchaseOrdersListAPIResponsePurchaseOrderDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  inNetwork?: boolean
  name?: string
}

export type MainStemAPIControllersAdminAccountingQuickbooksQuickBooksSyncEventAPIRequest = object

export interface MainStemAPIControllersAdminLocationsNotificationEmailsRemoveAPIRequest {
  /** @format int32 */
  locationID?: number
  notificationType?: 'General' | 'NewOrder' | 'ShipmentUpdate' | 'ReviewRequest'
  notificationEmail?: string
}

export interface MainStemAPIControllersAdminReturnsCreateAPIRequest {
  /** @format double */
  amountDue?: number
  cardRefunds?: MainStemAPIControllersAdminReturnsCreateCardRefundDetails[]
  fulfillmentMethods?: MainStemAPIControllersAdminReturnsCreateFulfillmentMethod[]
  hasTermsApplied?: boolean
  /** @format int32 */
  orderId?: number
  paymentType?: string
  reason?: string
  /** @format int32 */
  returnLocationID?: number
}

export interface MainStemAPIControllersAdminReturnsCreateCardRefundDetails {
  /** @format int32 */
  id?: number
  /** @format double */
  refundAmount?: number
}

export interface MainStemAPIControllersAdminReturnsCreateFulfillmentMethod {
  businessPartner?: MainStemAPIControllersAdminReturnsCreateBusinessPartner
  /** @format date-time */
  datePickedOn?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  /** @format double */
  discountReturned?: number
  dropShipUniqueID?: string
  /** @format int32 */
  id?: number
  lineItems?: MainStemAPIControllersAdminReturnsCreateFulfillmentLineItemDetail[]
  location?: MainStemAPIControllersAdminReturnsCreateLocation
  /** @format double */
  salesTax?: number
  shipmentType?: string
  /** @format double */
  shippingCost?: number
  /** @format double */
  shippingReturned?: number
  /** @format double */
  shippingTaxAmount?: number
  /** @format double */
  shippingTaxReturned?: number
  subType?: string
  type?: string
}

export interface MainStemAPIControllersAdminReturnsCreateBusinessPartner {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminReturnsCreateFulfillmentLineItemDetail {
  /** @format double */
  discountPerItem?: number
  /** @format int32 */
  fulfillmentMethodId?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIControllersAdminReturnsCreateFulfillmentLineItemDetailProduct
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  returnAmount?: number
  /** @format double */
  taxAmount?: number
  /** @format double */
  taxAmountPerItem?: number
  taxJarJson?: object
}

export interface MainStemAPIControllersAdminReturnsCreateLocation {
  address1?: string
  address2?: string
  city?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminReturnsCreateFulfillmentLineItemDetailProduct {
  /** @format int32 */
  id?: number
  name?: string
  taxExempt?: boolean
}

export interface MainStemAPIControllersAdminReturnsCreateAPIResponse {
  error?: string
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminReturnsDetailsAPIRequest {
  /** @format int32 */
  orderID?: number
  orderUUID?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsAPIResponse {
  message?: string
  wasSuccessful?: boolean
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  locationUUID?: string
  /** @format int32 */
  ownerBusinessPartner?: number
  /** @format int32 */
  parentOrderID?: number
  parentOrderUUID?: string
  returnedItems?: MainStemAPIControllersAdminReturnsDetailsOrderReturnLineItemDetail[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersAdminReturnsDetailsOrderReturnLineItemDetail {
  /** @format double */
  discountPerItem?: number
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIControllersAdminReturnsDetailsOrderReturnProductDetail
  /** @format double */
  quantity?: number
}

export interface MainStemAPIControllersAdminReturnsDetailsOrderReturnProductDetail {
  /** @format int32 */
  id?: number
  name?: string
  supplier?: MainStemAPIControllersAdminReturnsDetailsOrderReturnProductSupplierDetails
  unitOfMeasure?: string
  unitOfMeasureLabel?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOrderReturnProductSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIRequest {
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponse {
  /** @format int32 */
  customerID?: number
  customerName?: string
  financialDetails?: MainStemAPIControllersAdminReturnsDetailsOfOrderFinancialDetails
  fulfillmentMethods?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseInvoiceDetail[]
  hasTermsApplied?: boolean
  /** @format int32 */
  locationID?: number
  locationName?: string
  locationUUID?: string
  /** @format int32 */
  orderID?: number
  orderLineItems?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseOrderLineItem[]
  orderStatus?: string
  orderUUID?: string
  payments?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponsePayment[]
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  termsApplied?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseTermDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderFinancialDetails {
  /** @format double */
  amountLeft?: number
  /** @format double */
  discount?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format double */
  totalDue?: number
  /** @format double */
  totalLineItemCost?: number
  /** @format double */
  totalLineItemDiscount?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalRefunded?: number
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseInvoiceDetail {
  businessPartner?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseSupplierDetail
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  /** @format double */
  discountReturned?: number
  dropShipUniqueID?: string
  /** @format int32 */
  id?: number
  lineItems?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseOrderLineItem[]
  location?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseLocation
  quickbooksId?: string
  /** @format double */
  salesTax?: number
  shipmentType?: string
  /** @format double */
  shippingCost?: number
  /** @format double */
  shippingReturned?: number
  /** @format double */
  shippingTaxAmount?: number
  /** @format double */
  shippingTaxReturned?: number
  subType?: string
  type?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseOrderLineItem {
  /** @format double */
  discountPerItem?: number
  /** @format int32 */
  fulfillmentMethodId?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseOrderLineItemProduct
  /** @format int32 */
  quantity?: number
  /** @format double */
  returnAmount?: number
  /** @format double */
  taxAmount?: number
  /** @format double */
  taxAmountPerItem?: number
  taxJarJson?: object
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponsePayment {
  /** @format double */
  amount?: number
  /** @format date-time */
  cardExpirationDate?: string
  cardIssuer?: string
  /** @format int32 */
  id?: number
  last4OfCard?: string
  /** @format int32 */
  paymentId?: number
  paymentSettled?: boolean
  type?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseTermDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  note?: string
  /** @format int32 */
  orderID?: number
  reason?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseSupplierDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseLocation {
  address1?: string
  address2?: string
  city?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponseOrderLineItemProduct {
  /** @format int32 */
  id?: number
  name?: string
  taxExempt?: boolean
}

export interface MainStemAPIControllersAdminReturnsListAPIResponse {
  returns?: MainStemAPIControllersAdminReturnsListReturnDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminReturnsListReturnDetails {
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateCreated?: string
  id?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  suppliers?: string
  /** @format double */
  totalOrderAmount?: number
}

export interface MainStemAPIControllersAdminOrdersReturnsForSalesOrderAPIRequest {
  /** @format int32 */
  orderID?: number
  orderUUID?: string
}

export interface MainStemAPIControllersAdminOrdersReturnsForSalesOrderAPIResponse {
  orderReturns?: MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnDetail {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  ownerBusinessPartner?: number
  returnedItems?: MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnLineItemDetail[]
}

export interface MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnLineItemDetail {
  /** @format double */
  discountPerItem?: number
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnProductDetail
  /** @format double */
  quantity?: number
}

export interface MainStemAPIControllersAdminOrdersReturnsForSalesOrderOrderReturnProductDetail {
  /** @format int32 */
  id?: number
  name?: string
  unitOfMeasure?: string
  unitOfMeasureLabel?: string
}

export interface MainStemAPIControllersAdminSearchAPIRequest {
  query?: string
}

export interface MainStemAPIControllersAdminSearchAPIResponse {
  results?: MainStemAPIControllersAdminSearchAPIResponseSearchResult[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSearchAPIResponseSearchResult {
  /** @format int32 */
  id?: number
  text?: string
  type?: 'Customer' | 'Location' | 'Supplier' | 'Product' | 'Order' | 'User' | 'CustomerInvoice'
  uuid?: string
}

export interface MainStemSharpServiceBusSearchIndexRequest {
  fullIndex?: boolean
  productIDs?: number[]
  searchableAttributes?: string[]
  environment?: 'None' | 'Local' | 'Dev' | 'Demo' | 'Prod'
  /**
   * @format uuid
   * @example "00000000-0000-0000-0000-000000000000"
   */
  logSessionID?: string
  requestBody?: string
  requestingUserID?: string
  requestType?: string
}

export interface MainStemAPIControllersAdminSearchQueueSearchIndexAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersShipmentsDetailsAPIRequest {
  shipmentId?: string
}

export interface MainStemAPIControllersAdminCustomersShipmentsListAPIRequest {
  /** @format int32 */
  customerID?: number
  locationIDs?: number[]
}

export interface MainStemAPIControllersAdminCustomersShipmentsListAPIResponse {
  shipments?: MainStemAPIControllersAdminCustomersShipmentsListAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersShipmentsListAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  location?: MainStemAPIControllersAdminCustomersShipmentsListAPIResponseShipmentDetailsLocationDetails
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersAdminCustomersShipmentsListAPIResponseShipmentDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingCreateAPIRequest {
  carrier?: string
  /** @format double */
  cost?: number
  /** @format date-time */
  dateShipped?: string
  destinationAddress?: MainStemAPIControllersAdminShipmentTrackingCreateAddressDetail
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format double */
  height?: number
  /** @format int32 */
  invoiceID?: number
  /** @format double */
  length?: number
  originAddress?: MainStemAPIControllersAdminShipmentTrackingCreateAddressDetail
  shipmentType?: string
  trackingNumber?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingCreateAddressDetail {
  city?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAPIRequest {
  /** @format int32 */
  invoiceID?: number
  invoiceUUID?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAPIResponse {
  shipmentTracking?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShippingContainerDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShippingContainerDetails {
  carrier?: string
  /** @format double */
  cost?: number
  /** @format date-time */
  dateShipped?: string
  destination?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAddressDetails
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceId?: number
  /** @format double */
  length?: number
  lineItems?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShipmentLineItem[]
  /** @format int32 */
  orderId?: number
  origin?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAddressDetails
  shipEngineLabel?: string
  shipmentType?: string
  trackingNumber?: string
  trackingURL?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAddressDetails {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceShipmentLineItem {
  /** @format int32 */
  id?: number
  orderLineItem?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceOrderLineItemDetail
  product?: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceProductDetail
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceOrderLineItemDetail {
  /** @format int32 */
  id?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceProductDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAPIRequest {
  /** @format int32 */
  orderID?: number
  orderUUID?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAPIResponse {
  shipmentTracking?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderShippingContainerDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderShippingContainerDetails {
  carrier?: string
  /** @format double */
  cost?: number
  /** @format date-time */
  dateShipped?: string
  destination?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAddressDetails
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceId?: number
  /** @format double */
  length?: number
  lineItems?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderShipmentLineItem[]
  /** @format int32 */
  orderId?: number
  origin?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAddressDetails
  shipEngineLabel?: string
  shipmentType?: string
  trackingNumber?: string
  trackingURL?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAddressDetails {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderShipmentLineItem {
  /** @format int32 */
  id?: number
  orderLineItem?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderOrderLineItemDetail
  product?: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderProductDetail
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderOrderLineItemDetail {
  /** @format int32 */
  id?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminShipmentTrackingTrackingForOrderProductDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminShipmentTrackingUpdateAPIRequest {
  carrier?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  id?: number
  shipmentType?: string
  trackingNumber?: string
}

export interface MainStemAPIControllersAdminUsersSoftDeleteOrRecoveryAPIRequest {
  isDeleted?: boolean
  userId?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingAPIRequest {
  /** @format int32 */
  daysStale?: number
  filters?: Record<string, MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  sortField?: string
  sortOrder?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingFilterDetail {
  caseSensitive?: boolean
  comparator?: string
  filterType?: string
  filterVal?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingAPIResponse {
  fulfillments?: MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingTrackedFulfillment[]
  /** @format int32 */
  resultsTotalCount?: number
  /** @format int32 */
  totalFulfilled?: number
  /** @format int32 */
  totalFulfillments?: number
  /** @format int32 */
  totalNoProblems?: number
  /** @format int32 */
  totalProblems?: number
  /** @format int32 */
  totalUnfulfilled?: number
  /** @format int32 */
  totalUniqueOrders?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingTrackedFulfillment {
  commaSeparatedTrackingNumbers?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  fulfillmentDateNotified?: string
  /** @format int32 */
  fulfillmentId?: number
  fulfillmentOrderNumber?: string
  fulfillmentStatus?: string
  hasOrganization?: boolean
  hasProblem?: boolean
  hasTrackingDetails?: boolean
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  isFreightShipping?: boolean
  isFulfilled?: boolean
  isPrivateSupplier?: boolean
  isTermsOrder?: boolean
  lastInvoiceNote?: string
  lastInvoiceNoteCreatedBy?: string
  /** @format date-time */
  lastInvoiceNoteDate?: string
  notes?: string
  notificationRequired?: boolean
  notified?: boolean
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format int32 */
  organizationId?: number
  organizationName?: string
  owner?: MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingBusinessPartnerDetail
  owner_contact_email?: string
  owner_contact_phone?: string
  paymentStatus?: string
  /** @format date-time */
  shipmentDate?: string
  shipmentType?: string
  subType?: string
  supplier?: MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingBusinessPartnerDetail
  supplier_contact_email?: string
  supplier_contact_phone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  termLedgerId?: number
  trackingDetails?: MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingFulfillmentTrackingDetails[]
  type?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingFulfillmentTrackingDetails {
  carrier?: string
  trackingNumber?: string
}

export interface MainStemAPIControllersAdminSuppliersUpdateNotificationEmailsAddAPIRequest {
  notificationEmail?: string
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingCreateAPIRequest {
  /** @format double */
  orderAmountHigh?: number
  /** @format double */
  orderAmountLow?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format double */
  orderAmountHigh?: number
  /** @format double */
  orderAmountLow?: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersAdminSuppliersBandedShippingUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersCreateAPIRequest {
  address1?: string
  address2?: string
  city?: string
  country?: string
  email: string
  name: string
  password?: string
  phone?: string
  state?: string
  usesDirectPay?: boolean
  zip?: string
}

export interface MainStemAPIControllersAdminSuppliersCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIRequest {
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIResponse {
  supplier?: MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetails {
  addressBilling?: MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetailsAddressDetails
  addressPhysical?: MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetailsAddressDetails
  anonymousDataOptOut?: boolean
  bandedShipping?: MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetailsBandedShippingDetails[]
  /** @format date-time */
  dateFirstOrder?: string
  /** @format date-time */
  dateLastOrder?: string
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  doNotCall: boolean
  email?: string
  facebookUrl?: string
  /** @format double */
  freeShippingMinOrderThreshold?: number
  freightShippingAccountNumber?: string
  freightShippingCarrier?: string
  /** @format double */
  freightShippingFreeMinimum?: number
  freightShippingRequired?: boolean
  glCode?: string
  hasBandedShipping: boolean
  header?: string
  /** @format int32 */
  id: number
  instagramUrl?: string
  integrationType?: string
  isPrivateSupplier: boolean
  linkedInUrl?: string
  logoURL?: string
  manualOrderAcceptanceRequired?: boolean
  /** @format double */
  minimumOrderAmount?: number
  name?: string
  notificationEmails?: string[]
  parcelShippingAccountNumber?: string
  parcelShippingCarrier?: string
  /** @format double */
  parcelShippingFreeMinimum?: number
  paymentAgreement?: 'DueUponReceipt' | 'PrePay' | 'Net15' | 'Net30' | 'Net45' | 'Net30_110' | 'Net30_210'
  paymentTerms?: string
  /** @format double */
  paymentTermsCreditLimit?: number
  /** @format int32 */
  paymentTermsDays?: number
  /** @format int32 */
  paymentTermsDiscountDays?: number
  /** @format double */
  paymentTermsDiscountPercent?: number
  /** @format double */
  paymentTermsPercent?: number
  /** @format double */
  paymentTermsPrePaymentPercent?: number
  phone?: string
  phoneExtension?: string
  refundPolicy?: string
  requiresManualProcessing: boolean
  requiresPrePayment: boolean
  sendSupplierNotificationAcceptance?: boolean
  sendSupplierNotificationShipmentTracking?: boolean
  /** @format int32 */
  shippingFreightWeightLimit?: number
  /** @format int32 */
  shippingMaxBundleWeight?: number
  shortCodeIdentifier?: string
  twitterUrl?: string
  users?: MainStemAPIControllersAdminSuppliersDetailsAPIResponseUserDetails[]
  usesDirectPay: boolean
  usesPreferredShipping: boolean
  website?: string
  youtubeUrl?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIResponseSupplierDetailsBandedShippingDetails {
  /** @format int32 */
  id: number
  /** @format double */
  orderAmountHigh?: number
  /** @format double */
  orderAmountLow: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsAPIResponseUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIRequest {
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIResponse {
  shipments?: MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIRequest {
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIResponse {
  integrationLogs?: MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIResponseIntegrationLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIResponseIntegrationLogDetails {
  apiCallName?: string
  /** @format int32 */
  customerId?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  request?: string
  response?: string
  type?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersMetricsAPIRequest {
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminSuppliersMetricsAPIResponse {
  supplierMetrics?: MainStemAPIControllersAdminSuppliersMetricsAPIResponseSupplierMetrics[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersMetricsAPIResponseSupplierMetrics {
  /** @format double */
  averageOrderFrequency?: number
  /** @format double */
  averageShippingTimeInHours?: number
  /** @format date-time */
  firstOrder?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  issueCount?: number
  /** @format date-time */
  lastOrder?: string
  name?: string
  /** @format int32 */
  numberOfShipments?: number
  /** @format int32 */
  primaryUsers?: number
  productGroupsInLast90Days?: string
  /** @format int32 */
  returnsTotal?: number
  /** @format int32 */
  secondaryUsers?: number
  /** @format int32 */
  supplierProductEdits?: number
  /** @format int32 */
  totalProductEdits?: number
  /** @format int32 */
  totalQuantityOfProductsSold?: number
  /** @format double */
  totalRevenue?: number
  /** @format int32 */
  totalUniqueProductsSold?: number
  /** @format int32 */
  uniqueOrderCount?: number
}

export interface MainStemAPIControllersAdminSuppliersUpdateNotificationEmailsRemoveAPIRequest {
  notificationEmail?: string
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIRequest {
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIResponse {
  logs?: MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIResponseLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIResponseLogDetails {
  /** @format date-time */
  dateCreated?: string
  type?: string
  url?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsOrdersAPIRequest {
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsAPIRequest {
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsAPIResponse {
  products?: MainStemAPIControllersAdminSuppliersDetailsProductsAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsAPIResponseProductDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
  type?: string
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsPurchasedAPIRequest {
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsPurchasedAPIResponse {
  products?: MainStemAPIControllersAdminSuppliersDetailsProductsPurchasedAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersDetailsProductsPurchasedAPIResponseProductDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
  type?: string
}

export interface MainStemAPIControllersAdminSuppliersListAPIResponse {
  suppliers?: MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetails {
  address?: MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetailsAddressDetails
  email?: string
  /** @format int32 */
  id?: number
  imgLogo?: string
  name?: string
  phone?: string
}

export interface MainStemAPIControllersAdminSuppliersListAPIResponseSupplierDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminSuppliersUpdateAPIRequest {
  anonymousDataOptOut?: boolean
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  doNotCall?: boolean
  email?: string
  facebookUrl?: string
  /** @format double */
  freeShippingMinOrderThreshold?: number
  freightShippingAccountNumber?: string
  freightShippingCarrier?: string
  /** @format double */
  freightShippingFreeMinimum?: number
  freightShippingRequired?: boolean
  glCode?: string
  hasBandedShipping?: boolean
  instagramUrl?: string
  linkedInUrl?: string
  manualOrderAcceptanceRequired?: boolean
  /** @format double */
  minimumOrderAmount?: number
  name?: string
  parcelShippingAccountNumber?: string
  parcelShippingCarrier?: string
  /** @format double */
  parcelShippingFreeMinimum?: number
  paymentAgreement?: string
  paymentTerms?: string
  /** @format double */
  paymentTermsCreditLimit?: number
  /** @format int32 */
  paymentTermsDays?: number
  /** @format int32 */
  paymentTermsDiscountDays?: number
  /** @format double */
  paymentTermsDiscountPercent?: number
  /** @format double */
  paymentTermsPercent?: number
  /** @format double */
  paymentTermsPrePaymentPercent?: number
  phone?: string
  phoneExtension?: string
  requiresManualProcessing: boolean
  requiresPrePayment: boolean
  sendSupplierNotificationAcceptance?: boolean
  sendSupplierNotificationShipmentTracking?: boolean
  shortCodeIdentifier?: string
  /** @format int32 */
  supplierID?: number
  twitterUrl?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
  website?: string
  youtubeUrl?: string
}

export interface MainStemAPIControllersAdminSuppliersUpdateAddressAPIRequest {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  /** @format int32 */
  supplierID?: number
  type?: 'Physical' | 'Billing'
  zip?: string
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponse {
  baseLinePrices?: MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponseBaselinePrice[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponseBaselinePrice {
  /** @format double */
  price?: number
  product?: MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponseBaselinePriceProductDetails
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponseBaselinePriceProductDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceCreateAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format double */
  price?: number
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceUploadAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminSupplyAuditBaseLinePriceUploadAPIResponse {
  /** @format int32 */
  totalAdded?: number
  /** @format int32 */
  totalUpdated?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditCreateAPIRequest {
  /** @format int32 */
  customerID?: number
  description?: string
  title?: string
}

export interface MainStemAPIControllersAdminSupplyAuditCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditDetailsAPIRequest {
  /** @format int32 */
  supplyAuditID?: number
}

export interface MainStemAPIControllersAdminSupplyAuditDetailsAPIResponse {
  supplyAudit?: MainStemAPIControllersAdminSupplyAuditDetailsAPIResponseSupplyAuditDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditDetailsAPIResponseSupplyAuditDetails {
  /** @format date-time */
  dateCreated?: string
  description?: string
  files?: MainStemAPIControllersAdminSupplyAuditDetailsAPIResponseSupplyAuditDetailsFileDetails[]
  title?: string
}

export interface MainStemAPIControllersAdminSupplyAuditDetailsAPIResponseSupplyAuditDetailsFileDetails {
  url?: string
}

export interface MainStemAPIControllersAdminSupplyAuditListForCustomerAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersAdminSupplyAuditListForCustomerAPIResponse {
  supplyAudits?: MainStemAPIControllersAdminSupplyAuditListForCustomerAPIResponseSupplyAuditDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminSupplyAuditListForCustomerAPIResponseSupplyAuditDetails {
  /** @format date-time */
  dateCreated?: string
  description?: string
  title?: string
}

export interface MainStemSharpHelpersTermsFactoryTermsCreateRequest {
  createdByID?: string
  /** @format double */
  amount?: number
  note?: string
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  organizationID?: number
  reason?: string
}

export interface MainStemSharpHelpersTermsFactoryTermsCreateResponse {
  error?: string
  /** @format int32 */
  id?: number
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingTermsTermsLedgerAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponse {
  ledger?: MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponseTermLedger[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponseTermLedger {
  /** @format double */
  amount?: number
  /** @format date-time */
  date?: string
  description?: string
  visible?: boolean
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersAdminCustomersBillingTermsTermsTotalAPIRequest {
  /** @format int32 */
  customerID?: number
}

export interface MainStemSharpHelpersTermsFactoryTermsUpdateRequest {
  createdByID?: string
  reason?: string
  /** @format int32 */
  termID?: number
  /** @format double */
  updateAmountTo?: number
}

export interface MainStemSharpHelpersTermsFactoryTermsUpdateResponse {
  error?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingAPIRequest {
  filters?: Record<string, MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  sortField?: string
  sortOrder?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingFilterDetail {
  caseSensitive?: boolean
  comparator?: string
  filterType?: string
  filterVal?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingAPIResponse {
  fulfillments?: MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingTrackedFulfillment[]
  /** @format int32 */
  resultsTotalCount?: number
  /** @format int32 */
  totalFulfilled?: number
  /** @format int32 */
  totalFulfillments?: number
  /** @format int32 */
  totalNoProblems?: number
  /** @format int32 */
  totalProblems?: number
  /** @format int32 */
  totalUnfulfilled?: number
  /** @format int32 */
  totalUniqueOrders?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingTrackedFulfillment {
  commaSeparatedTrackingNumbers?: string
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  fulfillmentDateNotified?: string
  /** @format int32 */
  fulfillmentId?: number
  fulfillmentOrderNumber?: string
  fulfillmentStatus?: string
  hasOrganization?: boolean
  hasProblem?: boolean
  hasTrackingDetails?: boolean
  invoiceId?: string
  /** @format double */
  invoiceTotal?: number
  isFreightShipping?: boolean
  isFulfilled?: boolean
  isPrivateSupplier?: boolean
  isTermsOrder?: boolean
  lastInvoiceNote?: string
  lastInvoiceNoteCreatedBy?: string
  /** @format date-time */
  lastInvoiceNoteDate?: string
  notes?: string
  notificationRequired?: boolean
  notified?: boolean
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format int32 */
  organizationId?: number
  organizationName?: string
  owner?: MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingBusinessPartnerDetail
  owner_contact_email?: string
  owner_contact_phone?: string
  paymentStatus?: string
  /** @format date-time */
  shipmentDate?: string
  shipmentType?: string
  subType?: string
  supplier?: MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingBusinessPartnerDetail
  supplier_contact_email?: string
  supplier_contact_phone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  termLedgerId?: number
  trackingDetails?: MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingFulfillmentTrackingDetails[]
  type?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingFulfillmentTrackingDetails {
  carrier?: string
  trackingNumber?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsAPIRequest {
  filters?: Record<string, MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsFilterDetail>
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  sortField?: string
  sortOrder?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsFilterDetail {
  caseSensitive?: boolean
  comparator?: string
  filterType?: string
  filterVal?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsAPIResponse {
  /** @format int32 */
  resultsTotalCount?: number
  /** @format int32 */
  totalOrderLineItems?: number
  /** @format int32 */
  totalUniqueOrders?: number
  unfulfilledOrderLineItems?: MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsOrderLineItemsUnFulfilled[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsOrderLineItemsUnFulfilled {
  /** @format int32 */
  customerID?: number
  customerName?: string
  dateApproved?: string
  dateCreated?: string
  /** @format int32 */
  fulfilled?: number
  isPrivateSupplier?: boolean
  isTermsOrder?: boolean
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format int32 */
  organizationId?: number
  organizationName?: string
  owner?: MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsBusinessPartnerDetail
  owner_contact_email?: string
  owner_contact_phone?: string
  paymentStatus?: string
  /** @format int32 */
  productId?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  quantityReturned?: number
  supplier?: MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsBusinessPartnerDetail
  supplier_contact_email?: string
  supplier_contact_phone?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  toBeFulfilled?: number
  type?: string
}

export interface MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsBusinessPartnerDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminUNSPSCClassCreateAPIRequest {
  /** @format int64 */
  code?: number
  /** @format int64 */
  familyId?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCClassCreateAPIResponse {
  /** @format int64 */
  code?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCClassDetailsAPIRequest {
  /** @format int64 */
  code?: number
}

export interface MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponse {
  class?: MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponseUNSPSCClassDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponseUNSPSCClassDetails {
  /** @format int64 */
  code?: number
  commodities?: MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponseUNSPSCClassDetailsUNSPSCCommodityDetails[]
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponseUNSPSCClassDetailsUNSPSCCommodityDetails {
  /** @format int64 */
  code?: number
  title?: string
}

export type MainStemAPIControllersAdminUNSPSCClassListAPIRequest = object

export interface MainStemAPIControllersAdminUNSPSCClassListAPIResponse {
  classes?: MainStemAPIControllersAdminUNSPSCClassListAPIResponseUNSPSCClassDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCClassListAPIResponseUNSPSCClassDetails {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCClassUpdateAPIRequest {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCClassUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCCommodityCreateAPIRequest {
  /** @format int64 */
  classId?: number
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCCommodityCreateAPIResponse {
  /** @format int64 */
  code?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIRequest {
  /** @format int64 */
  code?: number
}

export interface MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIResponse {
  commodity?: MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIResponseUNSPSCCommodityDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIResponseUNSPSCCommodityDetails {
  /** @format int64 */
  code?: number
  title?: string
}

export type MainStemAPIControllersAdminUNSPSCCommodityListAPIRequest = object

export interface MainStemAPIControllersAdminUNSPSCCommodityListAPIResponse {
  commodities?: MainStemAPIControllersAdminUNSPSCCommodityListAPIResponseUNSPSCCommodityDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCCommodityListAPIResponseUNSPSCCommodityDetails {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCCommodityUpdateAPIRequest {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCCommodityUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCFamilyCreateAPIRequest {
  /** @format int64 */
  code?: number
  /** @format int64 */
  segmentId?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCFamilyCreateAPIResponse {
  /** @format int64 */
  code?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIRequest {
  /** @format int64 */
  code?: number
}

export interface MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponse {
  family?: MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponseUNSPSCFamilyDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponseUNSPSCFamilyDetails {
  classes?: MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponseUNSPSCFamilyDetailsUNSPSCClassDetails[]
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponseUNSPSCFamilyDetailsUNSPSCClassDetails {
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export type MainStemAPIControllersAdminUNSPSCFamilyListAPIRequest = object

export interface MainStemAPIControllersAdminUNSPSCFamilyListAPIResponse {
  families?: MainStemAPIControllersAdminUNSPSCFamilyListAPIResponseUNSPSCFamilyDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCFamilyListAPIResponseUNSPSCFamilyDetails {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCFamilyUpdateAPIRequest {
  /** @format int64 */
  code?: number
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCFamilyUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCSegmentCreateAPIRequest {
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCSegmentCreateAPIResponse {
  /** @format int64 */
  code?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIRequest {
  /** @format int64 */
  code?: number
}

export interface MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponse {
  segment?: MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponseUNSPSCSegmentDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponseUNSPSCSegmentDetails {
  /** @format int64 */
  code?: number
  definition?: string
  families?: MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponseUNSPSCSegmentDetailsUNSPSCFamilyDetails[]
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponseUNSPSCSegmentDetailsUNSPSCFamilyDetails {
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export type MainStemAPIControllersAdminUNSPSCSegmentListAPIRequest = object

export interface MainStemAPIControllersAdminUNSPSCSegmentListAPIResponse {
  segments?: MainStemAPIControllersAdminUNSPSCSegmentListAPIResponseUNSPSCSegmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUNSPSCSegmentListAPIResponseUNSPSCSegmentDetails {
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCSegmentUpdateAPIRequest {
  /** @format int64 */
  code?: number
  definition?: string
  title?: string
}

export interface MainStemAPIControllersAdminUNSPSCSegmentUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUserPermissionsCreateAPIRequest {
  /** @format int32 */
  permissionId?: number
  userId?: string
  value?: string
}

export interface MainStemAPIControllersAdminUserPermissionsCreateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUserPermissionsDeleteAPIRequest {
  /** @format int32 */
  id?: number
  userId?: string
}

export interface MainStemAPIControllersAdminUserPermissionsUpdateAPIRequest {
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersAdminUserPermissionsUpdateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersUpdateLocationsRemoveDefaultAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminUsersUpdateLocationsRemoveAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminUsersCreateAPIRequest {
  email?: string
  friendlyName?: string
  /** @format int32 */
  locationId?: number
  password?: string
  /** @format int32 */
  permissionGroupId?: number
  role?: string
  title?: string
}

export interface MainStemAPIControllersAdminUsersCreateAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersDetailsAPIResponse {
  activityLogs?: MainStemAPIControllersAdminUsersDetailsAPIResponseActivityLog[]
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  /** @format date-time */
  dateCreated?: string
  defaultLocation?: MainStemAPIControllersAdminUsersDetailsAPIResponseLocationDetails
  email?: string
  friendlyName?: string
  groupPermissions?: MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionDetails[]
  isDeleted?: boolean
  /** @format int32 */
  organizationID?: number
  permissionGroup?: MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionGroupDetail
  permissions?: MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionDetails[]
  permissionsType?: string
  role?: string
  secondaryLocations?: MainStemAPIControllersAdminUsersDetailsAPIResponseLocationDetails[]
  sideKickEnabled?: boolean
  title?: string
  username?: string
  userPermissions?: MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionDetails[]
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsAPIResponseActivityLog {
  content?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  documentID?: number
  documentType?: string
  /** @format int32 */
  id?: number
  reason?: string
}

export interface MainStemAPIControllersAdminUsersDetailsAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionDetails {
  group?: string
  shortCode?: string
  type?: string
  value?: string
}

export interface MainStemAPIControllersAdminUsersDetailsAPIResponsePermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  type?: string
}

export interface MainStemAPIControllersAdminUsersDetailsOrdersAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersAdminUsersDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersAdminUsersDetailsOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersDetailsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersAdminUsersDetailsProductsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersDetailsProductsAPIResponse {
  products?: MainStemAPIControllersAdminUsersDetailsProductsAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsProductsAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  supplier?: MainStemAPIControllersAdminUsersDetailsProductsAPIResponseProductDetailsSupplierDetails
  type?: string
}

export interface MainStemAPIControllersAdminUsersDetailsProductsAPIResponseProductDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminUsersDetailsResetPasswordAPIRequest {
  password?: string
  userId?: string
}

export interface MainStemAPIControllersAdminUsersUpdateLocationsSetDefaultAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersAdminUsersListAPIRequest {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  role?: string
}

export interface MainStemAPIControllersAdminUsersListAPIResponse {
  users?: MainStemAPIControllersAdminUsersListAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersListAPIResponseUserDetails {
  friendlyName?: string
  /** @format int32 */
  permissionGroupID?: number
  permissionGroupName?: string
  /** @format int32 */
  primaryLocationID?: number
  primaryLocationName?: string
  primaryLocationUUID?: string
  role?: string
  title?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersUpdateAPIRequest {
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  email?: string
  friendlyName?: string
  /** @format int32 */
  permissionGroupId?: number
  role?: string
  sideKickEnabled?: boolean
  title?: string
  userId?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersUpdateLocationsAddAPIRequest {
  locationIds?: number[]
  userId?: string
}

export interface MainStemAPIControllersAdminValueMetricsAPIRequest {
  /** @format int32 */
  customerId?: number
  locationIds?: number[]
}

export interface MainStemAPIControllersAdminValueMetricsAPIResponse {
  /** @format double */
  activityRangeInDays?: number
  /** @format double */
  averageOrderFrequency?: number
  /** @format double */
  averagePricePaid?: number
  /** @format double */
  averageProductCountPerOrder?: number
  /** @format double */
  averageProductQuantityPerOrder?: number
  /** @format double */
  averageTotalRevenueOfAllCustomers?: number
  /** @format int32 */
  primaryUserCount?: number
  /** @format int32 */
  secondaryUserCount?: number
  /** @format date-time */
  firstOrdered?: string
  /** @format date-time */
  lastOrdered?: string
  /** @format double */
  paymentsMade?: number
  productGroups?: string[]
  productGroupsInLast90Days?: string
  /** @format double */
  ratioVsOthersTotalSpent?: number
  /** @format double */
  returnsMade?: number
  /** @format double */
  totalCountOfProductsOrdered?: number
  /** @format double */
  totalMoneySpent?: number
  /** @format double */
  totalOrderLineItemRevenue?: number
  /** @format double */
  totalQuantityOfProductsOrdered?: number
  /** @format double */
  totalQuantityOrdered?: number
  /** @format double */
  totalRevenue?: number
  /** @format int32 */
  uniqueOrders?: number
  usageByAverageOrder?: string
  usageSinceJoining?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminPaymentsVoidPaymentAPIRequest {
  /** @format int32 */
  paymentTransactionID?: number
}

export interface MainStemAPIControllersAdminPaymentsVoidPaymentAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowActionsListAPIRequest {
  /** @format int32 */
  workflowId?: number
}

export interface MainStemAPIControllersAdminWorkflowActionsListAPIResponse {
  actions?: MainStemAPIControllersAdminWorkflowActionsListAPIResponseWorkflowActionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowActionsListAPIResponseWorkflowActionDetails {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  rank?: number
}

export interface MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIRequest {
  /** @format int32 */
  objectId?: number
  type?: 'Supplier' | 'Order' | 'Location'
  /** @format int32 */
  workflowEndpointId?: number
}

export interface MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowEndpointsCreateAPIRequest {
  description?: string
  name?: string
  triggerType?: 'Manual' | 'Automatic'
  /** @format int32 */
  workflowActionID?: number
}

export interface MainStemAPIControllersAdminWorkflowEndpointsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowEndpointsListAPIRequest {
  /** @format int32 */
  workflowActionID?: number
}

export interface MainStemAPIControllersAdminWorkflowEndpointsListAPIResponse {
  endpoints?: MainStemAPIControllersAdminWorkflowEndpointsListAPIResponseWorkflowEndpointDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowEndpointsListAPIResponseWorkflowEndpointDetails {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  triggerType?: 'Manual' | 'Automatic'
}

export interface MainStemAPIControllersAdminWorkflowListAPIResponse {
  workflows?: MainStemAPIControllersAdminWorkflowListAPIResponseWorkflowDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminWorkflowListAPIResponseWorkflowDetails {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersAdminBillsCreateAPIRequest {
  /** @format int32 */
  fileID?: number
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format date-time */
  invoiceDateDue?: string
  /** @format date-time */
  invoiceDateIssued?: string
  invoiceId?: string
  lineItems?: MainStemAPIControllersAdminBillsCreateBillLineItemDetail[]
  /** @format int32 */
  orderID?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersAdminBillsCreateBillLineItemDetail {
  name?: string
  /** @format double */
  price?: number
  /** @format double */
  quantity?: number
  sku?: string
}

export interface MainStemAPIControllersAdminBillsCreateAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminBillsUpdateAPIRequest {
  /** @format int32 */
  billId?: number
  /** @format date-time */
  invoiceDateDue?: string
  /** @format date-time */
  invoiceDateIssued?: string
  invoiceId?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  /** @format int32 */
  statusOfImport?: number
}

export interface MainStemAPIControllersAdminBillsUpdateAPIResponse {
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export type MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsAPIRequest = object

export interface MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsAPIResponse {
  invoiceMissingTaxDocuments?: MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsOrderMissingTaxDocumentDetail[]
  recalculationResponses?: MainStemAPIControllersAdminOrdersSalesTaxCalculateAPIResponse[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsOrderMissingTaxDocumentDetail {
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  orderUUID?: number
  /** @format int32 */
  customerLocationID?: number
  customerLocationName?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersAdminOrdersSalesTaxCalculateAPIResponse {
  /** @format double */
  taxAmount?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomFieldsCreateAPIRequest {
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
  fieldValue?: string
}

export interface MainStemAPIControllersAdminCustomFieldsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomFieldsCreateOrUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
  fieldValue?: string
}

export interface MainStemAPIControllersAdminCustomFieldsCreateOrUpdateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomFieldsDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersAdminCustomFieldsGetValueAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
}

export interface MainStemAPIControllersAdminCustomFieldsGetValueAPIResponse {
  value?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomFieldsListAPIRequest {
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
}

export interface MainStemAPIControllersAdminCustomFieldsListAPIResponse {
  customFields?: MainStemAPIControllersAdminCustomFieldsListCustomFieldDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomFieldsListCustomFieldDetails {
  /** @format int32 */
  id?: number
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
  fieldValue?: string
}

export interface MainStemAPIControllersAdminCustomFieldsUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  documentID?: number
  documentType?: string
  fieldName?: string
  fieldValue?: string
}

export interface MainStemAPIControllersAdminCustomFieldsUpdateAPIResponse {
  state?: 'NotFound' | 'Error' | 'Complete'
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionAPIRequest {
  /** @format int32 */
  customerID?: number
  type?: 'CreditCard' | 'ACH'
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionAPIResponse {
  locationPaymentOptions?: MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionLocationAllowablePaymentOptions[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionLocationAllowablePaymentOptions {
  /** @format int32 */
  locationID?: number
  name?: string
  imgUrl?: string
  paymentOptions?: MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions
}

export interface MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIRequest {
  /** @format int32 */
  invoiceID?: number
  lineItems?: MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIRequestItemOnFulfillment[]
}

export interface MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIRequestItemOnFulfillment {
  /** @format int32 */
  orderLineItemID?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersInvoicesCreateAPIRequest {
  /** @format int32 */
  supplierID?: number
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  /** @format double */
  shippingCost?: number
  shipmentType?: string
  lineItems?: MainStemAPIControllersAdminOrdersInvoicesCreateAPIRequestItemOnFulfillment[]
}

export interface MainStemAPIControllersAdminOrdersInvoicesCreateAPIRequestItemOnFulfillment {
  /** @format int32 */
  orderLineItemID?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersAdminOrdersInvoicesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminOrdersInvoicesDeleteAPIRequest {
  /** @format int32 */
  invoiceID?: number
}

export interface MainStemAPIControllersAdminOrdersInvoicesUpdateAPIRequest {
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  datePickedOn?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShipped?: string
  supplierInvoiceID?: string
  /** @format int32 */
  invoiceID?: number
  qbPurchaseOrderID?: string
  qbInvoiceID?: string
  shipmentType?: string
  /** @format double */
  shippingCost?: number
  /** @format double */
  salesTax?: number
  qbImportState?: 'Open' | 'Partial' | 'Closed' | 'Ignore'
}

export interface MainStemAPIControllersAdminOrdersSalesTaxCalculateAPIRequest {
  /** @format int32 */
  orderID?: number
}

export interface MainStemAPIControllersAdminOrdersEmailVerificationLogsAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersAdminOrdersEmailVerificationLogsAPIResponse {
  logs?: MainStemSharpHelpersLoggingOrderEmailedVerificationEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpHelpersLoggingOrderEmailedVerificationEntry {
  partitionKey?: string
  allEmailStatusesAsString?: string
  data?: string
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  fulfillmentID?: number
  /** @format date-time */
  sentToMiddleWareAt?: string
  /** @format date-time */
  sentToQueueAt?: string
  queueMessageID?: string
  /** @format date-time */
  enteredQueueAt?: string
  subject?: string
  sentTo?: string
  /** @format date-time */
  emailProviderEventAt?: string
  emailProviderWasSuccessful?: boolean
  emailProviderMessage?: string
  emailProviderID?: string
  /** @format date-time */
  emailProviderDeliveredAt?: string
  sentMultipleEmails?: boolean
  groupID?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
  eTag?: AzureETag
}

export interface MainStemAPIControllersAdminPermissionAddPermissionManagerAPIRequest {
  type?: string
  userId?: string
}

export interface MainStemAPIControllersAdminPermissionAddPermissionManagerAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  type?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsSuppliersAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersAdminUsersDetailsSuppliersAPIResponse {
  suppliers?: MainStemAPIControllersAdminUsersDetailsSuppliersAPIResponseSupplierDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersAdminUsersDetailsSuppliersAPIResponseSupplierDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.mainstem.io',
      headers: {
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID || '',
        'X-SessionURL': sessionURL || '',
        'X-Platform': 'Purchase Pro'
      }
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((this.instance.defaults.headers || {}) as any),
        ...((params1.headers || {}) as any),
        ...(((params2 && params2.headers) || {}) as any)
      }
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      if (requestParams && requestParams.headers) {
        requestParams.headers.common = { Accept: '*/*' } as any
        requestParams.headers.post = {} as any
        requestParams.headers.put = {} as any
      }

      body = this.createFormData(body as Record<string, unknown>)
    }

    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID || '',
        'X-SessionURL': sessionURL || '',
        'X-Platform': 'Purchase Pro'
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title MainStem Admin API V1
 * @version admin
 * @baseUrl https://api.mainstem.io
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags AdminAccountingInventoryValue
     * @name AccountingDashboardInventoryValue
     * @request POST:/api/admin/accounting/dashboard/inventoryValue
     */
    accountingDashboardInventoryValue: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardInventoryValueAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardInventoryValueAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/inventoryValue`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingInvoices
     * @name AccountingDashboardInvoices
     * @request POST:/api/admin/accounting/dashboard/invoices
     */
    accountingDashboardInvoices: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardInvoicesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardInvoicesAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/invoices`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingMarkAsSettled
     * @name AccountingDashboardMarkAsSettled
     * @request POST:/api/admin/accounting/dashboard/markAsSettled
     */
    accountingDashboardMarkAsSettled: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardMarkAsSettledAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/accounting/dashboard/markAsSettled`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingOrdersMissingAvalaraTaxDocuments
     * @name AccountingDashboardOrderMissingAvalaraTaxDocuments
     * @request POST:/api/admin/accounting/dashboard/orderMissingAvalaraTaxDocuments
     */
    accountingDashboardOrderMissingAvalaraTaxDocuments: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/orderMissingAvalaraTaxDocuments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingOrdersShipped
     * @name AccountingDashboardOrdersShipped
     * @request POST:/api/admin/accounting/dashboard/ordersShipped
     */
    accountingDashboardOrdersShipped: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardOrdersShippedAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersShippedAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/ordersShipped`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingPaymentsMade
     * @name AccountingDashboardPaymentsMade
     * @request POST:/api/admin/accounting/dashboard/paymentsMade
     */
    accountingDashboardPaymentsMade: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardPaymentsMadeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardPaymentsMadeAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/paymentsMade`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingPostShipmentChanges
     * @name AccountingDashboardPostShipmentChanges
     * @request POST:/api/admin/accounting/dashboard/postShipmentChanges
     */
    accountingDashboardPostShipmentChanges: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardPostShipmentChangesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardPostShipmentChangesAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/postShipmentChanges`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAccountingUnsettledPayments
     * @name AccountingDashboardUnsettledPayments
     * @request POST:/api/admin/accounting/dashboard/unsettledPayments
     */
    accountingDashboardUnsettledPayments: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardUnsettledPaymentsAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/unsettledPayments`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddBasePermissions
     * @name PermissionSetup
     * @request POST:/api/admin/permission/setup
     */
    permissionSetup: (params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permission/setup`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddDefaultPermissionGroup
     * @name PermissionGroupAddDefaultToUser
     * @request POST:/api/admin/permissionGroup/addDefaultToUser
     */
    permissionGroupAddDefaultToUser: (
      apiRequest: MainStemSharpHelpersDefaultPermissionsRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permissionGroup/addDefaultToUser`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddNotificationEmail
     * @name NotificationEmailAdd
     * @request POST:/api/admin/notification-email/add
     */
    notificationEmailAdd: (
      apiRequest: MainStemAPIControllersAdminLocationsNotificationEmailsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/notification-email/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddressBookCreate
     * @name AddressBookCreate
     * @request POST:/api/admin/address-book/create
     */
    addressBookCreate: (
      apiRequest: MainStemAPIControllersAdminAddressBookCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/address-book/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddressBookDelete
     * @name AddressBookDelete
     * @request POST:/api/admin/address-book/delete
     */
    addressBookDelete: (
      apiRequest: MainStemAPIControllersAdminAddressBookDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/address-book/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddressBookList
     * @name AddressBookList
     * @request POST:/api/admin/address-book/list
     */
    addressBookList: (apiRequest: MainStemAPIControllersAdminAddressBookListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAddressBookListAPIResponse, any>({
        path: `/api/admin/address-book/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAddressBookUpdate
     * @name AddressBookUpdate
     * @request POST:/api/admin/address-book/update
     */
    addressBookUpdate: (
      apiRequest: MainStemAPIControllersGlobalAddressBookUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/address-book/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateCreate
     * @name AffiliateCreate
     * @request POST:/api/admin/affiliate/create
     */
    affiliateCreate: (apiRequest: MainStemAPIControllersAdminAffiliatesCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAffiliatesCreateAPIResponse, any>({
        path: `/api/admin/affiliate/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateDetails
     * @name AffiliateDetails
     * @request GET:/api/admin/affiliate/details
     */
    affiliateDetails: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAffiliatesDetailsAPIResponse, any>({
        path: `/api/admin/affiliate/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateList
     * @name AffiliateList
     * @request GET:/api/admin/affiliate/list
     */
    affiliateList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAffiliatesListAPIResponse, any>({
        path: `/api/admin/affiliate/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateProgramCreate
     * @name AffiliateProgramCreate
     * @request POST:/api/admin/affiliate-program/create
     */
    affiliateProgramCreate: (
      apiRequest: MainStemAPIControllersAdminAffiliatesProgramsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAffiliatesProgramsCreateAPIResponse, any>({
        path: `/api/admin/affiliate-program/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateProgramDetails
     * @name AffiliateProgramDetails
     * @request GET:/api/admin/affiliate-program/details
     */
    affiliateProgramDetails: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAffiliatesProgramsDetailsAPIResponse, any>({
        path: `/api/admin/affiliate-program/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateProgramList
     * @name AffiliateProgramList
     * @request GET:/api/admin/affiliate-program/list
     */
    affiliateProgramList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAffiliatesProgramsListAPIResponse, any>({
        path: `/api/admin/affiliate-program/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateProgramUpdate
     * @name AffiliateProgramUpdate
     * @request POST:/api/admin/affiliate-program/update
     */
    affiliateProgramUpdate: (
      apiRequest: MainStemAPIControllersAdminAffiliatesProgramsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/affiliate-program/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAffiliateUpdate
     * @name AffiliateUpdate
     * @request POST:/api/admin/affiliate/update
     */
    affiliateUpdate: (apiRequest: MainStemAPIControllersAdminAffiliatesUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/affiliate/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAmazonMerchantBlockCreate
     * @name AmazonMerchantBlockCreate
     * @request POST:/api/admin/amazon/merchant/block/create
     */
    amazonMerchantBlockCreate: (
      APIRequest: MainStemAPIControllersAdminAmazonMerchantBlockCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAmazonMerchantBlockCreateAPIResponse, any>({
        path: `/api/admin/amazon/merchant/block/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAmazonMerchantBlockDetails
     * @name AmazonMerchantBlockDetails
     * @request POST:/api/admin/amazon/merchant/block/details
     */
    amazonMerchantBlockDetails: (
      APIRequest: MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAmazonMerchantBlockDetailsAPIResponse, any>({
        path: `/api/admin/amazon/merchant/block/details`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAmazonMerchantBlockList
     * @name AmazonMerchantBlockList
     * @request POST:/api/admin/amazon/merchant/block/list
     */
    amazonMerchantBlockList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAmazonMerchantBlockListAPIResponse, any>({
        path: `/api/admin/amazon/merchant/block/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAmazonMerchantBlockUpdate
     * @name AmazonMerchantBlockUpdate
     * @request POST:/api/admin/amazon/merchant/block/update
     */
    amazonMerchantBlockUpdate: (
      APIRequest: MainStemAPIControllersAdminAmazonMerchantBlockUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAmazonMerchantBlockUpdateAPIResponse, any>({
        path: `/api/admin/amazon/merchant/block/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAmazonOrderLog
     * @name AmazonOrderLog
     * @request POST:/api/admin/amazon/order/log
     */
    amazonOrderLog: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAmazonOrderLogAPIResponse, any>({
        path: `/api/admin/amazon/order/log`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminApplyPayment
     * @name OrdersApplyPayment
     * @request POST:/api/admin/orders/applyPayment
     */
    ordersApplyPayment: (
      apiRequest: MainStemAPIControllersAdminOrdersApplyPaymentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersApplyPaymentAPIResponse, any>({
        path: `/api/admin/orders/applyPayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeCreate
     * @name AttributeCreate
     * @request POST:/api/admin/attribute/create
     */
    attributeCreate: (APIRequest: MainStemAPIControllersAdminAttributesCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributesCreateAPIResponse, any>({
        path: `/api/admin/attribute/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeDetails
     * @name AttributeDetails
     * @request POST:/api/admin/attribute/details
     */
    attributeDetails: (
      apiRequest: MainStemAPIControllersAdminAttributesDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributesDetailsAPIResponse, any>({
        path: `/api/admin/attribute/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeRemove
     * @name AttributeRemove
     * @request POST:/api/admin/attribute/remove
     */
    attributeRemove: (APIRequest: MainStemAPIControllersAdminAttributesRemoveAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributesRemoveAPIResponse, any>({
        path: `/api/admin/attribute/remove`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributesGetValues
     * @name AttributesGetValues
     * @request GET:/api/admin/attributes/getValues
     */
    attributesGetValues: (
      query?: {
        /** @format int32 */
        apiRequestAttributeId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributesGetValuesAPIResponse, any>({
        path: `/api/admin/attributes/getValues`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributesList
     * @name AttributesList
     * @request GET:/api/admin/attributes/list
     */
    attributesList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributesListAPIResponse, any>({
        path: `/api/admin/attributes/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeUpdate
     * @name AttributeUpdate
     * @request POST:/api/admin/attribute/update
     */
    attributeUpdate: (APIRequest: MainStemAPIControllersAdminAttributesUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributesUpdateAPIResponse, any>({
        path: `/api/admin/attribute/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueAddProduct
     * @name AttributeValueAddProduct
     * @request POST:/api/admin/attributeValue/addProduct
     */
    attributeValueAddProduct: (
      APIRequest: MainStemAPIControllersAdminAttributeValueAddProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueAddProductAPIResponse, any>({
        path: `/api/admin/attributeValue/addProduct`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueCreate
     * @name AttributeValueCreate
     * @request POST:/api/admin/attributeValue/create
     */
    attributeValueCreate: (
      APIRequest: MainStemAPIControllersAdminAttributeValueCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueCreateAPIResponse, any>({
        path: `/api/admin/attributeValue/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueDetails
     * @name AttributeValueDetails
     * @request GET:/api/admin/attributeValue/details
     */
    attributeValueDetails: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueDetailsAPIResponse, any>({
        path: `/api/admin/attributeValue/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueExportToCSV
     * @name AttributeValueExportToCsv
     * @request GET:/api/admin/attributeValue/exportToCSV
     */
    attributeValueExportToCsv: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/attributeValue/exportToCSV`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueList
     * @name AttributeValueList
     * @request GET:/api/admin/attributeValue/list
     */
    attributeValueList: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueListAPIResponse, any>({
        path: `/api/admin/attributeValue/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueProductBulkAppend
     * @name AttributeValueBulkProductAppend
     * @request POST:/api/admin/attributeValue/bulkProductAppend
     */
    attributeValueBulkProductAppend: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributeValueBulkAppendAPIResponse, any>({
        path: `/api/admin/attributeValue/bulkProductAppend`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueProductBulkReplace
     * @name AttributeValueBulkProductReplace
     * @request POST:/api/admin/attributeValue/bulkProductReplace
     */
    attributeValueBulkProductReplace: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAttributeValueBulkReplaceAPIResponse, any>({
        path: `/api/admin/attributeValue/bulkProductReplace`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueRemove
     * @name AttributeValueRemove
     * @request POST:/api/admin/attributeValue/remove
     */
    attributeValueRemove: (
      APIRequest: MainStemAPIControllersAdminAttributeValueRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueRemoveAPIResponse, any>({
        path: `/api/admin/attributeValue/remove`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueRemoveProduct
     * @name AttributeValueRemoveProduct
     * @request POST:/api/admin/attributeValue/removeProduct
     */
    attributeValueRemoveProduct: (
      APIRequest: MainStemAPIControllersAdminAttributeValueRemoveProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueRemoveProductAPIResponse, any>({
        path: `/api/admin/attributeValue/removeProduct`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAttributeValueUpdate
     * @name AttributeValueUpdate
     * @request POST:/api/admin/attributeValue/update
     */
    attributeValueUpdate: (
      APIRequest: MainStemAPIControllersAdminAttributeValueUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAttributeValueUpdateAPIResponse, any>({
        path: `/api/admin/attributeValue/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAuthCurrentUser
     * @name AuthCurrentUser
     * @request GET:/api/admin/auth/currentUser
     */
    authCurrentUser: (params: RequestParams = {}) =>
      this.request<MainStemAPIHelpersAuthorizationCurrentUser, any>({
        path: `/api/admin/auth/currentUser`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminAuthLogin
     * @name AuthLogin
     * @request POST:/api/admin/auth/login
     */
    authLogin: (apiRequest: MainStemAPIHelpersAuthorizationRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIHelpersAuthorizationResponse, any>({
        path: `/api/admin/auth/login`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminBillsAddLineItem
     * @name BillLineItemAdd
     * @request POST:/api/admin/bill/lineItem/add
     */
    billLineItemAdd: (
      apiRequest: MainStemAPIControllersAdminBillsAddBillLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminBillsAddBillLineItemAPIResponse, any>({
        path: `/api/admin/bill/lineItem/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminBillsDeleteLineItem
     * @name BillLineItemDelete
     * @request POST:/api/admin/bill/lineItem/delete
     */
    billLineItemDelete: (
      apiRequest: MainStemAPIControllersAdminBillsDeleteBillLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminBillsDeleteBillLineItemAPIResponse, any>({
        path: `/api/admin/bill/lineItem/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminBillsDetails
     * @name BillDetails
     * @request POST:/api/admin/bill/details
     */
    billDetails: (apiRequest: MainStemAPIControllersAdminBillsDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminBillsDetailsAPIResponse, any>({
        path: `/api/admin/bill/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminBillsList
     * @name BillsList
     * @request POST:/api/admin/bills/list
     */
    billsList: (apiRequest: MainStemAPIControllersAdminBillsListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminBillsListAPIResponse, any>({
        path: `/api/admin/bills/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminBillsUpdateLineItem
     * @name BillLineItemUpdate
     * @request POST:/api/admin/bill/lineItem/update
     */
    billLineItemUpdate: (
      apiRequest: MainStemAPIControllersAdminBillsUpdateLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminBillsUpdateLineItemAPIResponse, any>({
        path: `/api/admin/bill/lineItem/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCarrierCreate
     * @name CarrierCreate
     * @request POST:/api/admin/carrier/create
     */
    carrierCreate: (apiRequest: MainStemAPIControllersAdminCarriersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCarriersCreateAPIResponse, any>({
        path: `/api/admin/carrier/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCarrierDetails
     * @name CarrierDetails
     * @request POST:/api/admin/carrier/details
     */
    carrierDetails: (apiRequest: MainStemAPIControllersAdminCarriersDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCarriersDetailsAPIResponse, any>({
        path: `/api/admin/carrier/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCarrierList
     * @name CarrierList
     * @request POST:/api/admin/carrier/list
     */
    carrierList: (apiRequest: MainStemAPIControllersAdminCarriersListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCarriersListAPIResponse, any>({
        path: `/api/admin/carrier/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCarrierUpdate
     * @name CarrierUpdate
     * @request POST:/api/admin/carrier/update
     */
    carrierUpdate: (apiRequest: MainStemAPIControllersAdminCarriersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCarriersUpdateAPIResponse, any>({
        path: `/api/admin/carrier/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogCreate
     * @name CatalogsCreate
     * @request POST:/api/admin/catalogs/create
     */
    catalogsCreate: (apiRequest: MainStemAPIControllersAdminCatalogsCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCatalogsCreateAPIResponse, any>({
        path: `/api/admin/catalogs/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDelete
     * @name CatalogsDelete
     * @request POST:/api/admin/catalogs/delete
     */
    catalogsDelete: (apiRequest: MainStemAPIControllersAdminCatalogsDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/catalogs/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetails
     * @name CatalogsDetails
     * @request POST:/api/admin/catalogs/details
     */
    catalogsDetails: (apiRequest: MainStemAPIControllersAdminCatalogsDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsLocationsAdd
     * @name CatalogsLocationsAdd
     * @request POST:/api/admin/catalogs/locations/add
     */
    catalogsLocationsAdd: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsLocationsList
     * @name CatalogsLocationsList
     * @request POST:/api/admin/catalogs/locations/list
     */
    catalogsLocationsList: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsLocationsListAPIResponse, any>({
        path: `/api/admin/catalogs/locations/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsLocationsRemove
     * @name CatalogsLocationsRemove
     * @request POST:/api/admin/catalogs/locations/remove
     */
    catalogsLocationsRemove: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsProductsAdd
     * @name CatalogsProductsAdd
     * @request POST:/api/admin/catalogs/products/add
     */
    catalogsProductsAdd: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsProductsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/products/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsProductsList
     * @name CatalogsProductsList
     * @request POST:/api/admin/catalogs/products/list
     */
    catalogsProductsList: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsProductsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsProductsListAPIResponse, any>({
        path: `/api/admin/catalogs/products/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsProductsRemove
     * @name CatalogsProductsRemove
     * @request POST:/api/admin/catalogs/products/remove
     */
    catalogsProductsRemove: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsProductsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/products/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsUsersAdd
     * @name CatalogsUsersAdd
     * @request POST:/api/admin/catalogs/users/add
     */
    catalogsUsersAdd: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/users/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsUsersList
     * @name CatalogsUsersList
     * @request POST:/api/admin/catalogs/users/list
     */
    catalogsUsersList: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsUsersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsUsersListAPIResponse, any>({
        path: `/api/admin/catalogs/users/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDetailsUsersRemove
     * @name CatalogsUsersRemove
     * @request POST:/api/admin/catalogs/users/remove
     */
    catalogsUsersRemove: (
      apiRequest: MainStemAPIControllersAdminCatalogsDetailsUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDetailsAPIResponse, any>({
        path: `/api/admin/catalogs/users/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogDuplicate
     * @name CatalogsDuplicate
     * @request POST:/api/admin/catalogs/duplicate
     */
    catalogsDuplicate: (
      apiRequest: MainStemAPIControllersAdminCatalogsDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCatalogsDuplicateAPIResponse, any>({
        path: `/api/admin/catalogs/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogList
     * @name CatalogsList
     * @request POST:/api/admin/catalogs/list
     */
    catalogsList: (apiRequest: MainStemAPIControllersAdminCatalogsListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCatalogsListAPIResponse, any>({
        path: `/api/admin/catalogs/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCatalogUpdate
     * @name CatalogsUpdate
     * @request POST:/api/admin/catalogs/update
     */
    catalogsUpdate: (apiRequest: MainStemAPIControllersAdminCatalogsUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/catalogs/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminChangeLogList
     * @name ChangeLogList
     * @request POST:/api/admin/change-log/list
     */
    changeLogList: (APIRequest: MainStemAPIControllersAdminChangeLogListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminChangeLogListAPIResponse, any>({
        path: `/api/admin/change-log/list`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCheckSettlementStatus
     * @name PaymentCheckStatus
     * @request POST:/api/admin/payment/checkStatus
     */
    paymentCheckStatus: (
      apiRequest: MainStemAPIControllersAdminPaymentsCheckPaymentStatusAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentsCheckPaymentStatusAPIResponse, any>({
        path: `/api/admin/payment/checkStatus`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionCategoryCreate
     * @name CollectionCategoryCreate
     * @request POST:/api/admin/collection/category/create
     */
    collectionCategoryCreate: (
      apiRequest: MainStemAPIControllersAdminCollectionCategoryCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionCategoryCreateAPIResponse, any>({
        path: `/api/admin/collection/category/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionCategoryDetails
     * @name CollectionCategoryDetails
     * @request POST:/api/admin/collection/category/details
     */
    collectionCategoryDetails: (
      apiRequest: MainStemAPIControllersAdminCollectionCategoryDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionCategoryDetailsAPIResponse, any>({
        path: `/api/admin/collection/category/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionCategoryList
     * @name CollectionCategoryList
     * @request POST:/api/admin/collection/category/list
     */
    collectionCategoryList: (
      apiRequest: MainStemAPIControllersAdminCollectionCategoryListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionCategoryListAPIResponse, any>({
        path: `/api/admin/collection/category/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionCategoryUpdate
     * @name CollectionCategoryUpdate
     * @request POST:/api/admin/collection/category/update
     */
    collectionCategoryUpdate: (
      apiRequest: MainStemAPIControllersAdminCollectionCategoryUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionCategoryUpdateAPIResponse, any>({
        path: `/api/admin/collection/category/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionClassificationCreate
     * @name CollectionClassificationCreate
     * @request POST:/api/admin/collection/classification/create
     */
    collectionClassificationCreate: (
      apiRequest: MainStemAPIControllersAdminCollectionClassificationCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionClassificationCreateAPIResponse, any>({
        path: `/api/admin/collection/classification/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionClassificationDetails
     * @name CollectionClassificationDetails
     * @request POST:/api/admin/collection/classification/details
     */
    collectionClassificationDetails: (
      apiRequest: MainStemAPIControllersAdminCollectionClassificationDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionClassificationDetailsAPIResponse, any>({
        path: `/api/admin/collection/classification/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionClassificationList
     * @name CollectionClassificationList
     * @request POST:/api/admin/collection/classification/list
     */
    collectionClassificationList: (
      apiRequest: MainStemAPIControllersAdminCollectionClassificationListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionClassificationListAPIResponse, any>({
        path: `/api/admin/collection/classification/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionClassificationUpdate
     * @name CollectionClassificationUpdate
     * @request POST:/api/admin/collection/classification/update
     */
    collectionClassificationUpdate: (
      apiRequest: MainStemAPIControllersAdminCollectionClassificationUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionClassificationUpdateAPIResponse, any>({
        path: `/api/admin/collection/classification/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionSubCategoryCreate
     * @name CollectionSubCategoryCreate
     * @request POST:/api/admin/collection/sub-category/create
     */
    collectionSubCategoryCreate: (
      apiRequest: MainStemAPIControllersAdminCollectionSubCategoryCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionSubCategoryCreateAPIResponse, any>({
        path: `/api/admin/collection/sub-category/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionSubCategoryDetails
     * @name CollectionSubCategoryDetails
     * @request POST:/api/admin/collection/sub-category/details
     */
    collectionSubCategoryDetails: (
      apiRequest: MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionSubCategoryDetailsAPIResponse, any>({
        path: `/api/admin/collection/sub-category/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionSubCategoryList
     * @name CollectionSubCategoryList
     * @request POST:/api/admin/collection/sub-category/list
     */
    collectionSubCategoryList: (
      apiRequest: MainStemAPIControllersAdminCollectionSubCategoryListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionSubCategoryListAPIResponse, any>({
        path: `/api/admin/collection/sub-category/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCollectionSubCategoryUpdate
     * @name CollectionSubCategoryUpdate
     * @request POST:/api/admin/collection/sub-category/update
     */
    collectionSubCategoryUpdate: (
      apiRequest: MainStemAPIControllersAdminCollectionSubCategoryUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCollectionSubCategoryUpdateAPIResponse, any>({
        path: `/api/admin/collection/sub-category/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminConversionDashboard
     * @name ConversionDashboard
     * @request POST:/api/admin/conversion/dashboard
     */
    conversionDashboard: (
      apiRequest: MainStemAPIControllersAdminConversionDashboardAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminConversionDashboardAPIResponse, any>({
        path: `/api/admin/conversion/dashboard`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminConversionPowerBI
     * @name ConversionPowerBi
     * @request GET:/api/admin/conversion/power-bi
     */
    conversionPowerBi: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/conversion/power-bi`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCreateFreightShippingInvoice
     * @name ShipmentTrackingFreightShippingInvoice
     * @request POST:/api/admin/shipment-tracking/freightShippingInvoice
     */
    shipmentTrackingFreightShippingInvoice: (
      apiRequest: MainStemAPIControllersAdminShipmentTrackingAddFreightShippingInvoiceAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminShipmentTrackingAddFreightShippingInvoiceAPIResponse, any>({
        path: `/api/admin/shipment-tracking/freightShippingInvoice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerLogoUpdate
     * @name CustomerUpdateLogo
     * @request POST:/api/admin/customer/update/logo
     */
    customerUpdateLogo: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/customer/update/logo`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerOpenInvoices
     * @name CustomersOpenInvoices
     * @request POST:/api/admin/customers/open-invoices
     */
    customersOpenInvoices: (
      apiRequest: MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersOrdersOpenInvoicesAPIResponse, any>({
        path: `/api/admin/customers/open-invoices`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerOpenOrders
     * @name CustomersOpenOrders
     * @request POST:/api/admin/customers/open-orders
     */
    customersOpenOrders: (
      apiRequest: MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersOrdersOpenOrdersAPIResponse, any>({
        path: `/api/admin/customers/open-orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPaymentMethodArchive
     * @name PaymentMethodsArchive
     * @request POST:/api/admin/paymentMethods/archive
     */
    paymentMethodsArchive: (
      request: MainStemAPIControllersAdminCustomersBillingPaymentMethodsArchiveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/paymentMethods/archive`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPaymentMethodSoftDelete
     * @name PaymentMethodsDelete
     * @request POST:/api/admin/paymentMethods/delete
     */
    paymentMethodsDelete: (
      request: MainStemAPIControllersAdminCustomersBillingPaymentMethodsSoftDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/paymentMethods/delete`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingAddLineItem
     * @name CustomerPricingAddLineItem
     * @request POST:/api/admin/customerPricing/addLineItem
     */
    customerPricingAddLineItem: (
      APIRequest: MainStemAPIControllersAdminCustomerPricingAddLineAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomerPricingAddLineAPIResponse, any>({
        path: `/api/admin/customerPricing/addLineItem`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingBulkUpdate
     * @name CustomerPricingBulkUpdate
     * @request POST:/api/admin/customerPricing/bulkUpdate
     */
    customerPricingBulkUpdate: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomerPricingBulkUpdateAPIResponse, any>({
        path: `/api/admin/customerPricing/bulkUpdate`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingCreate
     * @name CustomerPricingCreate
     * @request POST:/api/admin/customerPricing/create
     */
    customerPricingCreate: (
      APIRequest: MainStemAPIControllersAdminCustomerPricingCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomerPricingCreateAPIResponse, any>({
        path: `/api/admin/customerPricing/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingDetails
     * @name CustomerPricingDetails
     * @request GET:/api/admin/customerPricing/details
     */
    customerPricingDetails: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersAdminCustomerPricingDetailsAdminCustomerPricingDetailsControllerAPIResponse,
        any
      >({
        path: `/api/admin/customerPricing/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingLineItemUpdate
     * @name CustomerPricingUpdateLineItem
     * @request POST:/api/admin/customerPricing/updateLineItem
     */
    customerPricingUpdateLineItem: (
      APIRequest: MainStemAPIControllersAdminCustomerPricingUpdateLineAdminCustomerPricingLineItemUpdateControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/customerPricing/updateLineItem`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingList
     * @name CustomerPricingList
     * @request GET:/api/admin/customerPricing/list
     */
    customerPricingList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomerPricingListAPIResponse, any>({
        path: `/api/admin/customerPricing/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomerPricingRemoveLineItem
     * @name CustomerPricingRemoveLineItem
     * @request POST:/api/admin/customerPricing/removeLineItem
     */
    customerPricingRemoveLineItem: (
      APIRequest: MainStemAPIControllersAdminCustomerPricingRemoveLineAdminCustomerPricingRemoveLineItemControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/customerPricing/removeLineItem`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersCreate
     * @name CustomersCreate
     * @request POST:/api/admin/customers/create
     */
    customersCreate: (apiRequest: MainStemAPIControllersAdminCustomersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomersCreateAPIResponse, any>({
        path: `/api/admin/customers/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersDetails
     * @name CustomersDetails
     * @request POST:/api/admin/customers/details
     */
    customersDetails: (apiRequest: MainStemAPIControllersAdminCustomersDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomersDetailsAPIResponse, any>({
        path: `/api/admin/customers/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersIntegrationListLogs
     * @name CustomersIntegrationLogs
     * @request POST:/api/admin/customers/integration/logs
     */
    customersIntegrationLogs: (
      apiRequest: MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersIntegrationsListLogsAPIResponse, any>({
        path: `/api/admin/customers/integration/logs`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersList
     * @name CustomersList
     * @request GET:/api/admin/customers/list
     */
    customersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomersListAPIResponse, any>({
        path: `/api/admin/customers/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersLocationsList
     * @name CustomersLocationsList
     * @request POST:/api/admin/customers/locations/list
     */
    customersLocationsList: (
      apiRequest: MainStemAPIControllersAdminCustomersLocationsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersLocationsListAPIResponse, any>({
        path: `/api/admin/customers/locations/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersOrdersList
     * @name CustomersOrdersList
     * @request POST:/api/admin/customers/orders/list
     */
    customersOrdersList: (
      apiRequest: MainStemAPIControllersAdminCustomersOrdersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersOrdersListAPIResponse, any>({
        path: `/api/admin/customers/orders/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersUpdate
     * @name CustomersUpdate
     * @request POST:/api/admin/customers/update
     */
    customersUpdate: (apiRequest: MainStemAPIControllersAdminCustomersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/customers/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomersUsersList
     * @name CustomersUsersList
     * @request POST:/api/admin/customers/users/list
     */
    customersUsersList: (
      apiRequest: MainStemAPIControllersAdminCustomersUsersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersUsersListAPIResponse, any>({
        path: `/api/admin/customers/users/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminCustomPricingItemsExportToCSV
     * @name CustomerPricingExportLineItemsToCsv
     * @request GET:/api/admin/customerPricing/exportLineItemsToCSV
     */
    customerPricingExportLineItemsToCsv: (
      query?: {
        /** @format int32 */
        apiRequestCustomPriceListId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/customerPricing/exportLineItemsToCSV`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminDashboardApprovedUnshipped
     * @name DashboardApprovedUnshipped
     * @request GET:/api/admin/dashboard/approved-unshipped
     */
    dashboardApprovedUnshipped: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminDashboardApprovedUnshippedAPIResponse, any>({
        path: `/api/admin/dashboard/approved-unshipped`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminDashboardLast5Orders
     * @name DashboardLast5Orders
     * @request GET:/api/admin/dashboard/last-5-orders
     */
    dashboardLast5Orders: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminDashboardLast5OrdersAPIResponse, any>({
        path: `/api/admin/dashboard/last-5-orders`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminDashboardSalesByCustomer
     * @name DashboardSalesCustomer
     * @request POST:/api/admin/dashboard/sales/customer
     */
    dashboardSalesCustomer: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminDashboardSalesByCustomerAPIResponse, any>({
        path: `/api/admin/dashboard/sales/customer`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminDashboardSalesByPlatform
     * @name DashboardSalesByPlatform
     * @request GET:/api/admin/dashboard/sales-by-platform
     */
    dashboardSalesByPlatform: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminDashboardSalesByPlatformAPIResponse, any>({
        path: `/api/admin/dashboard/sales-by-platform`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminDashboardUnchargedFreight
     * @name DashboardUnchargedFreight
     * @request GET:/api/admin/dashboard/uncharged-freight
     */
    dashboardUnchargedFreight: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminDashboardUnchargedFreightAPIResponse, any>({
        path: `/api/admin/dashboard/uncharged-freight`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFeedCreate
     * @name FeedCreate
     * @request POST:/api/admin/feed/create
     */
    feedCreate: (apiRequest: MainStemAPIControllersAdminFeedCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFeedCreateAPIResponse, any>({
        path: `/api/admin/feed/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFeedDelete
     * @name FeedDelete
     * @request POST:/api/admin/feed/delete
     */
    feedDelete: (apiRequest: MainStemAPIControllersAdminFeedDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFeedDeleteAPIResponse, any>({
        path: `/api/admin/feed/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFeedDetails
     * @name FeedDetails
     * @request POST:/api/admin/feed/details
     */
    feedDetails: (apiRequest: MainStemAPIControllersAdminFeedDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFeedDetailsAPIResponse, any>({
        path: `/api/admin/feed/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFeedList
     * @name FeedList
     * @request POST:/api/admin/feed/list
     */
    feedList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFeedListAPIResponse, any>({
        path: `/api/admin/feed/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFeedUpdate
     * @name FeedUpdate
     * @request POST:/api/admin/feed/update
     */
    feedUpdate: (apiRequest: MainStemAPIControllersAdminFeedUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFeedUpdateAPIResponse, any>({
        path: `/api/admin/feed/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFileUploadsList
     * @name FileUploadsList
     * @request POST:/api/admin/file-uploads/list
     */
    fileUploadsList: (apiRequest: MainStemAPIControllersAdminFileUploadsListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminFileUploadsListAPIResponse, any>({
        path: `/api/admin/file-uploads/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFulfillmentTrackerExport
     * @name FulfillmentTrackerExportToCsv
     * @request POST:/api/admin/fulfillment-tracker/exportToCSV
     */
    fulfillmentTrackerExportToCsv: (
      apiRequest: MainStemSharpHelpersPaginatePaginationRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/fulfillment-tracker/exportToCSV`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFulfillmentTrackerLineDetails
     * @name FulfillmentTrackerDetails
     * @request GET:/api/admin/fulfillment-tracker/details
     */
    fulfillmentTrackerDetails: (
      query?: {
        /** @format int32 */
        apiRequestFulfillmentId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminFulfillmentTrackerDetailsAPIResponse, any>({
        path: `/api/admin/fulfillment-tracker/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFulfillmentTrackerList
     * @name FulfillmentTrackerList
     * @request POST:/api/admin/fulfillment-tracker/list
     */
    fulfillmentTrackerList: (
      apiRequest: MainStemAPIControllersAdminFulfillmentTrackerListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminFulfillmentTrackerListAPIResponse, any>({
        path: `/api/admin/fulfillment-tracker/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminFulfillmentTrackerUpdate
     * @name FulfillmentTrackerUpdate
     * @request POST:/api/admin/fulfillment-tracker/update
     */
    fulfillmentTrackerUpdate: (
      trackedFulfillment: MainStemAPIControllersAdminFulfillmentTrackerUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/fulfillment-tracker/update`,
        method: 'POST',
        body: trackedFulfillment,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminGetQuote
     * @name GetQuote
     * @request GET:/api/admin/get-quote
     */
    getQuote: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminGetQuoteAPIResponse, any>({
        path: `/api/admin/get-quote`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminImpersonation
     * @name UserImpersonationToken
     * @request POST:/api/admin/user/impersonationToken
     */
    userImpersonationToken: (
      request: MainStemAPIControllersAdminUsersImpersonationAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersImpersonationAPIResponse, any>({
        path: `/api/admin/user/impersonationToken`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInfrastructureQueueList
     * @name InfrastructureQueuesLists
     * @request POST:/api/admin/infrastructure/queues/lists
     */
    infrastructureQueuesLists: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminInfrastructureQueuesListAPIResponse, any>({
        path: `/api/admin/infrastructure/queues/lists`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInfrastructureWebJobList
     * @name InfrastructureWebjobLists
     * @request POST:/api/admin/infrastructure/webjob/lists
     */
    infrastructureWebjobLists: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminInfrastructureWebJobsListAPIResponse, any>({
        path: `/api/admin/infrastructure/webjob/lists`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminIntegrationsGlobalIndustrialOrderLog
     * @name IntegrationsGlobalIndustrialOrderLog
     * @request POST:/api/admin/integrations/global-industrial/order/log
     */
    integrationsGlobalIndustrialOrderLog: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminIntegrationsGlobalIndustrialOrderLogAPIResponse, any>({
        path: `/api/admin/integrations/global-industrial/order/log`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminIntegrationsGlobalIndustrialShipmentEstimate
     * @name IntegrationsGlobalIndustrialShipmentEstimate
     * @request POST:/api/admin/integrations/global-industrial/shipment/estimate
     */
    integrationsGlobalIndustrialShipmentEstimate: (
      APIRequest: MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminIntegrationsGlobalIndustrialShipmentEstimateAPIResponse, any>({
        path: `/api/admin/integrations/global-industrial/shipment/estimate`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoicePaymentLinkCreate
     * @name PaymentLinksCreatePaymentRecord
     * @request POST:/api/admin/paymentLinks/createPaymentRecord
     */
    paymentLinksCreatePaymentRecord: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkCreateAPIResponse, any>({
        path: `/api/admin/paymentLinks/createPaymentRecord`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoicePaymentLinkDelete
     * @name InvoicePaymentLinkDelete
     * @request POST:/api/admin/invoicePaymentLink/delete
     */
    invoicePaymentLinkDelete: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkDeleteAPIResponse, any>({
        path: `/api/admin/invoicePaymentLink/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoicePaymentLinkUpdate
     * @name InvoicePaymentLinkUpdate
     * @request POST:/api/admin/invoicePaymentLink/update
     */
    invoicePaymentLinkUpdate: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksInvoicePaymentLinkUpdateAPIResponse, any>({
        path: `/api/admin/invoicePaymentLink/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceProblemsCreate
     * @name InvoiceProblemCreate
     * @request POST:/api/admin/invoice-problem/create
     */
    invoiceProblemCreate: (
      apiRequest: MainStemAPIControllersAdminInvoiceProblemsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersGlobalMessageCreateAPIResponse, any>({
        path: `/api/admin/invoice-problem/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceProblemsList
     * @name InvoiceProblemList
     * @request POST:/api/admin/invoice-problem/list
     */
    invoiceProblemList: (
      apiRequest: MainStemAPIControllersAdminInvoiceProblemsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminInvoiceProblemsListAPIResponse, any>({
        path: `/api/admin/invoice-problem/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceProblemsListUnresolved
     * @name InvoiceProblemListUnresolved
     * @request POST:/api/admin/invoice-problem/listUnresolved
     */
    invoiceProblemListUnresolved: (
      apiRequest: MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminInvoiceProblemsListUnresolvedAPIResponse, any>({
        path: `/api/admin/invoice-problem/listUnresolved`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceProblemsUpdateStatus
     * @name InvoiceProblemUpdateStatus
     * @request POST:/api/admin/invoice-problem/updateStatus
     */
    invoiceProblemUpdateStatus: (
      apiRequest: MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminInvoiceProblemsUpdateStatusAPIResponse, any>({
        path: `/api/admin/invoice-problem/updateStatus`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceReadyToImport
     * @name AccountingInvoicesReadyToImport
     * @request POST:/api/admin/accounting/invoices/ready-to-import
     */
    accountingInvoicesReadyToImport: (
      apiRequest: MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingQuickbooksInvoicesToImportAPIResponse, any>({
        path: `/api/admin/accounting/invoices/ready-to-import`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoicesIntegrationsProcess
     * @name InvoicesIntegrationsProcess
     * @request POST:/api/admin/invoices/integrations/process
     */
    invoicesIntegrationsProcess: (
      apiRequest: MainStemAPIControllersAdminInvoicesProcessDropshipAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/invoices/integrations/process`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceTrackerExport
     * @name InvoicesExportToCsv
     * @request POST:/api/admin/invoices/exportToCSV
     */
    invoicesExportToCsv: (apiRequest: MainStemSharpHelpersPaginatePaginationRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/invoices/exportToCSV`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceTrackerLineDetails
     * @name InvoicesDetails
     * @request GET:/api/admin/invoices/details
     */
    invoicesDetails: (
      query?: {
        /** @format int32 */
        apiRequestInvoiceId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminInvoicesDetailsAPIResponse, any>({
        path: `/api/admin/invoices/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceTrackerList
     * @name InvoicesList
     * @request POST:/api/admin/invoices/list
     */
    invoicesList: (apiRequest: MainStemAPIControllersAdminInvoicesListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminInvoicesListAPIResponse, any>({
        path: `/api/admin/invoices/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminInvoiceUpdate
     * @name InvoicesUpdate
     * @request POST:/api/admin/invoices/update
     */
    invoicesUpdate: (apiRequest: MainStemAPIControllersAdminInvoicesUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminInvoicesUpdateAPIResponse, any>({
        path: `/api/admin/invoices/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsAllowablePaymentOptions
     * @name PaymentMethodsLocationsAllowablePayments
     * @request POST:/api/admin/paymentMethods/locations/allowablePayments
     */
    paymentMethodsLocationsAllowablePayments: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersAdminCustomersBillingPaymentMethodsLocationsAllowablePaymentOptionsAPIResponse,
        any
      >({
        path: `/api/admin/paymentMethods/locations/allowablePayments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsCreate
     * @name LocationsCreate
     * @request POST:/api/admin/locations/create
     */
    locationsCreate: (apiRequest: MainStemAPIControllersAdminLocationsCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminLocationsCreateAPIResponse, any>({
        path: `/api/admin/locations/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsDetails
     * @name LocationsDetails
     * @request POST:/api/admin/locations/details
     */
    locationsDetails: (apiRequest: MainStemAPIControllersAdminLocationsDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminLocationsDetailsAPIResponse, any>({
        path: `/api/admin/locations/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsList
     * @name LocationsList
     * @request GET:/api/admin/locations/list
     */
    locationsList: (
      query: {
        type: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminLocationsListAPIResponse, any>({
        path: `/api/admin/locations/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsListOfOrganization
     * @name LocationsOfOrganizations
     * @request POST:/api/admin/locations/ofOrganizations
     */
    locationsOfOrganizations: (
      apiRequest: MainStemAPIControllersAdminLocationsListOfOrganizationAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminLocationsListOfOrganizationAPIResponse, any>({
        path: `/api/admin/locations/ofOrganizations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsOrdersList
     * @name LocationsOrdersList
     * @request POST:/api/admin/locations/orders/list
     */
    locationsOrdersList: (
      apiRequest: MainStemAPIControllersAdminLocationsOrdersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminLocationsOrdersListAPIResponse, any>({
        path: `/api/admin/locations/orders/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsUpdate
     * @name LocationsUpdate
     * @request POST:/api/admin/locations/update
     */
    locationsUpdate: (apiRequest: MainStemAPIControllersAdminLocationsUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/locations/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationsUpdateAddress
     * @name LocationsUpdateAddress
     * @request POST:/api/admin/locations/update/address
     */
    locationsUpdateAddress: (
      apiRequest: MainStemAPIControllersAdminLocationsUpdateAddressAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/locations/update/address`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminLocationUsersList
     * @name LocationsUsersList
     * @request POST:/api/admin/locations/users/list
     */
    locationsUsersList: (
      apiRequest: MainStemAPIControllersAdminLocationsUsersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminLocationsUsersListAPIResponse, any>({
        path: `/api/admin/locations/users/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminMainStemLogs
     * @name LogsMainstem
     * @request POST:/api/admin/logs/mainstem
     */
    logsMainstem: (apiRequest: MainStemAPIControllersAdminLogsMainStemLogsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminLogsMainStemLogsAPIResponse, any>({
        path: `/api/admin/logs/mainstem`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminMakeInvoicePayments
     * @name MakePaymentsInvoice
     * @request POST:/api/admin/makePayments/invoice
     */
    makePaymentsInvoice: (
      apiRequest: MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentsMakePaymentsInvoiceAPIResponse, any>({
        path: `/api/admin/makePayments/invoice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminMakeOrderPayments
     * @name MakePaymentsOrder
     * @request POST:/api/admin/makePayments/order
     */
    makePaymentsOrder: (
      apiRequest: MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentsMakePaymentsOrderAPIResponse, any>({
        path: `/api/admin/makePayments/order`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminMarginException
     * @name ReportingMarginException
     * @request POST:/api/admin/reporting/marginException
     */
    reportingMarginException: (
      apiRequest: MainStemAPIControllersAdminReportingMarginExceptionAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminReportingMarginExceptionAPIResponse, any>({
        path: `/api/admin/reporting/marginException`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderCommunicationForFulfillment
     * @name OrdersCommunicationsFulfillment
     * @request GET:/api/admin/orders/communications/fulfillment
     */
    ordersCommunicationsFulfillment: (
      query?: {
        /** @format int32 */
        apiRequestOrderId?: number
        apiRequestFulfillmentMethodIds?: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersCommunicationAPIResponse, any>({
        path: `/api/admin/orders/communications/fulfillment`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderDuplicate
     * @name SalesOrderDuplicate
     * @request POST:/api/admin/sales-order/duplicate
     */
    salesOrderDuplicate: (
      apiRequest: MainStemAPIControllersAdminOrdersDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersDuplicateAPIResponse, any>({
        path: `/api/admin/sales-order/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderInvoiceItemDelete
     * @name OrdersInvoiceItemsDelete
     * @request POST:/api/admin/orders/invoiceItems/delete
     */
    ordersInvoiceItemsDelete: (
      apiRequest: MainStemAPIControllersAdminOrdersInvoiceItemsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/invoiceItems/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderInvoiceItemUpdate
     * @name OrdersInvoiceItemsUpdate
     * @request POST:/api/admin/orders/invoiceItems/update
     */
    ordersInvoiceItemsUpdate: (
      apiRequest: MainStemAPIControllersAdminOrdersInvoiceItemsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/invoiceItems/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderLineItemCreate
     * @name OrderLineItemCreate
     * @request POST:/api/admin/order-line-item/create
     */
    orderLineItemCreate: (
      apiRequest: MainStemAPIControllersAdminOrderLineItemCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/order-line-item/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderLineItemDelete
     * @name OrderLineItemDelete
     * @request POST:/api/admin/order-line-item/delete
     */
    orderLineItemDelete: (
      apiRequest: MainStemAPIControllersAdminOrderLineItemDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/order-line-item/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderLineItemUpdate
     * @name OrderLineItemUpdate
     * @request POST:/api/admin/order-line-item/update
     */
    orderLineItemUpdate: (
      apiRequest: MainStemAPIControllersAdminOrderLineItemUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/order-line-item/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrdersCancel
     * @name OrdersCancel
     * @request POST:/api/admin/orders/cancel
     */
    ordersCancel: (apiRequest: MainStemAPIControllersAdminOrdersCancelAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/cancel`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrdersDetails
     * @name OrdersDetails
     * @request POST:/api/admin/orders/details
     */
    ordersDetails: (apiRequest: MainStemAPIControllersAdminOrdersDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminOrdersDetailsAPIResponse, any>({
        path: `/api/admin/orders/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrdersDetailsPayments
     * @name OrdersDetailsPayments
     * @request POST:/api/admin/orders/details/payments
     */
    ordersDetailsPayments: (
      apiRequest: MainStemAPIControllersAdminOrdersDetailsPaymentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponse, any>({
        path: `/api/admin/orders/details/payments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrdersList
     * @name OrdersList
     * @request POST:/api/admin/orders/list
     */
    ordersList: (apiRequest: MainStemAPIControllersAdminOrdersListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminOrdersListAPIResponse, any>({
        path: `/api/admin/orders/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderSubmitApprove
     * @name OrderSubmitApprove
     * @request POST:/api/admin/order/submit/approve
     */
    orderSubmitApprove: (
      apiRequest: MainStemAPIControllersAdminOrdersSubmitApproveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersSubmitApproveAPIResponse, any>({
        path: `/api/admin/order/submit/approve`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminOrderUpdateAddress
     * @name OrdersUpdateAddress
     * @request POST:/api/admin/orders/updateAddress
     */
    ordersUpdateAddress: (
      apiRequest: MainStemAPIControllersAdminOrdersUpdateAddressAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/updateAddress`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminParentProductTypeCreate
     * @name ParentProductTypeCreate
     * @request POST:/api/admin/parent-product-type/create
     */
    parentProductTypeCreate: (
      apiRequest: MainStemAPIControllersAdminParentProductTypeCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminParentProductTypeCreateAPIResponse, any>({
        path: `/api/admin/parent-product-type/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminParentProductTypeDetails
     * @name ParentProductTypeDetails
     * @request POST:/api/admin/parent-product-type/details
     */
    parentProductTypeDetails: (
      apiRequest: MainStemAPIControllersAdminParentProductTypeDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminParentProductTypeDetailsAPIResponse, any>({
        path: `/api/admin/parent-product-type/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminParentProductTypeList
     * @name ParentProductTypeList
     * @request POST:/api/admin/parent-product-type/list
     */
    parentProductTypeList: (
      apiRequest: MainStemAPIControllersAdminParentProductTypeListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminParentProductTypeListAPIResponse, any>({
        path: `/api/admin/parent-product-type/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminParentProductTypeUpdate
     * @name ParentProductTypeUpdate
     * @request POST:/api/admin/parent-product-type/update
     */
    parentProductTypeUpdate: (
      apiRequest: MainStemAPIControllersAdminParentProductTypeUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminParentProductTypeUpdateAPIResponse, any>({
        path: `/api/admin/parent-product-type/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentLinksOnFulfillment
     * @name PaymentLinksOnFulfillment
     * @request POST:/api/admin/paymentLinks/onFulfillment
     */
    paymentLinksOnFulfillment: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentAPIResponse, any>({
        path: `/api/admin/paymentLinks/onFulfillment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentLinksOnOrders
     * @name PaymentLinksOnOrder
     * @request POST:/api/admin/paymentLinks/onOrder
     */
    paymentLinksOnOrder: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIResponse, any>({
        path: `/api/admin/paymentLinks/onOrder`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentLinksOnPaymentTransaction
     * @name PaymentLinksOnPaymentTransaction
     * @request GET:/api/admin/paymentLinks/onPaymentTransaction
     */
    paymentLinksOnPaymentTransaction: (
      query?: {
        apiRequestIds?: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksPaymentLinksOnPaymentTransactionAPIResponse, any>({
        path: `/api/admin/paymentLinks/onPaymentTransaction`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentMethodsCreateACH
     * @name PaymentMethodsCreateAch
     * @request POST:/api/admin/paymentMethods/create/ach
     */
    paymentMethodsCreateAch: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateACHAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateACHAPIResponse, any>({
        path: `/api/admin/paymentMethods/create/ach`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentMethodsCreateCreditCard
     * @name PaymentMethodsCreateCreditCard
     * @request POST:/api/admin/paymentMethods/create/creditCard
     */
    paymentMethodsCreateCreditCard: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateCreditCardAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersBillingPaymentMethodsCreateCreditCardAPIResponse, any>({
        path: `/api/admin/paymentMethods/create/creditCard`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentMethodsList
     * @name PaymentMethodsList
     * @request POST:/api/admin/paymentMethods/list
     */
    paymentMethodsList: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersBillingPaymentMethodsListAPIResponse, any>({
        path: `/api/admin/paymentMethods/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPaymentTransactionsNotLinked
     * @name PaymentLinksUnlinkedPayments
     * @request POST:/api/admin/paymentLinks/unlinkedPayments
     */
    paymentLinksUnlinkedPayments: (
      apiRequest: MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedAPIResponse, any>({
        path: `/api/admin/paymentLinks/unlinkedPayments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionCreate
     * @name PermissionCreate
     * @request POST:/api/admin/permission/create
     */
    permissionCreate: (apiRequest: MainStemAPIControllersAdminPermissionCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminPermissionCreateAPIResponse, any>({
        path: `/api/admin/permission/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionDelete
     * @name PermissionDelete
     * @request POST:/api/admin/permission/delete
     */
    permissionDelete: (apiRequest: MainStemAPIControllersAdminPermissionDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permission/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupAddUserToGroup
     * @name PermissionGroupAddUserToGroup
     * @request POST:/api/admin/permissionGroup/addUserToGroup
     */
    permissionGroupAddUserToGroup: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupAddUserToGroupAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permissionGroup/addUserToGroup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupCreate
     * @name PermissionGroupCreate
     * @request POST:/api/admin/permissionGroup/create
     */
    permissionGroupCreate: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupCreateAPIResponse, any>({
        path: `/api/admin/permissionGroup/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupDelete
     * @name PermissionGroupDelete
     * @request POST:/api/admin/permissionGroup/delete
     */
    permissionGroupDelete: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permissionGroup/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupList
     * @name PermissionGroupList
     * @request POST:/api/admin/permissionGroup/list
     */
    permissionGroupList: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupListAPIResponse, any>({
        path: `/api/admin/permissionGroup/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupListByType
     * @name PermissionGroupListByType
     * @request POST:/api/admin/permissionGroup/listByType
     */
    permissionGroupListByType: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupListByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupListByTypeAPIResponse, any>({
        path: `/api/admin/permissionGroup/listByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupRemoveUserFromGroup
     * @name PermissionGroupRemoveUserFromGroup
     * @request POST:/api/admin/permissionGroup/removeUserFromGroup
     */
    permissionGroupRemoveUserFromGroup: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupRemoveUserFromGroupAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permissionGroup/removeUserFromGroup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupUpdate
     * @name PermissionGroupUpdate
     * @request POST:/api/admin/permissionGroup/update
     */
    permissionGroupUpdate: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupUpdateAPIResponse, any>({
        path: `/api/admin/permissionGroup/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupUsersNotInGroup
     * @name PermissionGroupUsersNotInGroup
     * @request POST:/api/admin/permissionGroup/usersNotInGroup
     */
    permissionGroupUsersNotInGroup: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupUsersNotInGroupAPIResponse, any>({
        path: `/api/admin/permissionGroup/usersNotInGroup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupValueCreate
     * @name PermissionGroupValueCreate
     * @request POST:/api/admin/permissionGroupValue/create
     */
    permissionGroupValueCreate: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupValueCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupValueCreateAPIResponse, any>({
        path: `/api/admin/permissionGroupValue/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupValueDelete
     * @name PermissionGroupValueDelete
     * @request POST:/api/admin/permissionGroupValue/delete
     */
    permissionGroupValueDelete: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupValueDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/permissionGroupValue/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupValueList
     * @name PermissionGroupValueList
     * @request POST:/api/admin/permissionGroupValue/list
     */
    permissionGroupValueList: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupValueListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupValueListAPIResponse, any>({
        path: `/api/admin/permissionGroupValue/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionGroupValueUpdate
     * @name PermissionGroupValueUpdate
     * @request POST:/api/admin/permissionGroupValue/update
     */
    permissionGroupValueUpdate: (
      apiRequest: MainStemAPIControllersAdminPermissionGroupValueUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionGroupValueUpdateAPIResponse, any>({
        path: `/api/admin/permissionGroupValue/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionList
     * @name PermissionList
     * @request POST:/api/admin/permission/list
     */
    permissionList: (apiRequest: MainStemAPIControllersAdminPermissionListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminPermissionListAPIResponse, any>({
        path: `/api/admin/permission/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPermissionUpdate
     * @name PermissionUpdate
     * @request POST:/api/admin/permission/update
     */
    permissionUpdate: (apiRequest: MainStemAPIControllersAdminPermissionUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminPermissionUpdateAPIResponse, any>({
        path: `/api/admin/permission/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPriceAgreementCreate
     * @name PriceAgreementCreate
     * @request POST:/api/admin/price-agreement/create
     */
    priceAgreementCreate: (
      apiRequest: MainStemAPIControllersAdminPriceAgreementsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPriceAgreementsCreateAPIResponse, any>({
        path: `/api/admin/price-agreement/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPriceAgreementDelete
     * @name PriceAgreementDelete
     * @request POST:/api/admin/price-agreement/delete
     */
    priceAgreementDelete: (
      apiRequest: MainStemAPIControllersAdminPriceAgreementsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPriceAgreementsDeleteAPIResponse, any>({
        path: `/api/admin/price-agreement/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProcessDropship
     * @name FulfillmentTrackerProcessDropShip
     * @request POST:/api/admin/fulfillment-tracker/processDropShip
     */
    fulfillmentTrackerProcessDropShip: (
      apiRequest: MainStemAPIControllersAdminFulfillmentTrackerProcessDropshipAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/fulfillment-tracker/processDropShip`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductAttributeValuesCreate
     * @name ProductsAttributeValuesCreate
     * @request POST:/api/admin/products/attribute-values/create
     */
    productsAttributeValuesCreate: (
      apiRequest: MainStemAPIControllersAdminProductsAttributeValuesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/products/attribute-values/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductAttributeValuesDelete
     * @name ProductsAttributeValuesDelete
     * @request POST:/api/admin/products/attribute-values/delete
     */
    productsAttributeValuesDelete: (
      apiRequest: MainStemAPIControllersAdminProductsAttributeValuesDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/products/attribute-values/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductCleanQueue
     * @name ProductQueueClean
     * @request POST:/api/admin/product/queue/clean
     */
    productQueueClean: (apiRequest: MainStemSharpServiceBusProductCleanRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpServiceBusServiceBusResponse, any>({
        path: `/api/admin/product/queue/clean`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsBulkUploadList
     * @name ProductsBulkUploadList
     * @request GET:/api/admin/products/bulk/upload/list
     */
    productsBulkUploadList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminProductsBulkBulkResponseListAPIResponse, any>({
        path: `/api/admin/products/bulk/upload/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsChildCreate
     * @name ProductsChildCreate
     * @request POST:/api/admin/products/child/create
     */
    productsChildCreate: (
      apiRequest: MainStemAPIControllersAdminProductsChildCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsChildCreateAPIResponse, any>({
        path: `/api/admin/products/child/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsChildDetails
     * @name ProductsChildDetails
     * @request POST:/api/admin/products/child/details
     */
    productsChildDetails: (
      apiRequest: MainStemAPIControllersAdminProductsChildDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsChildDetailsAPIResponse, any>({
        path: `/api/admin/products/child/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsChildDuplicate
     * @name ProductsChildDuplicate
     * @request POST:/api/admin/products/child/duplicate
     */
    productsChildDuplicate: (
      apiRequest: MainStemAPIControllersAdminProductsChildDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsChildDuplicateAPIResponse, any>({
        path: `/api/admin/products/child/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsChildList
     * @name ProductsChildList
     * @request POST:/api/admin/products/child/list
     */
    productsChildList: (
      apiRequest: MainStemAPIControllersAdminProductsChildListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsChildListAPIResponse, any>({
        path: `/api/admin/products/child/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsChildUpdate
     * @name ProductsChildUpdate
     * @request POST:/api/admin/products/child/update
     */
    productsChildUpdate: (
      apiRequest: MainStemAPIControllersAdminProductsChildUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsChildUpdateAPIResponse, any>({
        path: `/api/admin/products/child/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsCreate
     * @name ProductsCreate
     * @request POST:/api/admin/products/create
     */
    productsCreate: (apiRequest: MainStemAPIControllersAdminProductsCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminProductsCreateAPIResponse, any>({
        path: `/api/admin/products/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsDetails
     * @name ProductsDetails
     * @request GET:/api/admin/products/details
     */
    productsDetails: (
      query?: {
        /** @format int32 */
        apiRequestId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsDetailsAPIResponse, any>({
        path: `/api/admin/products/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsDetailsOrders
     * @name ProductsDetailsOrders
     * @request POST:/api/admin/products/details/orders
     */
    productsDetailsOrders: (
      apiRequest: MainStemAPIControllersAdminProductsDetailsOrdersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsDetailsOrdersAPIResponse, any>({
        path: `/api/admin/products/details/orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsDetailsProductLists
     * @name ProductsDetailsProductLists
     * @request POST:/api/admin/products/details/productLists
     */
    productsDetailsProductLists: (
      apiRequest: MainStemAPIControllersAdminProductsDetailsProductListsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsDetailsProductListsAPIResponse, any>({
        path: `/api/admin/products/details/productLists`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsDetailsShipments
     * @name ProductsDetailsShipments
     * @request POST:/api/admin/products/details/shipments
     */
    productsDetailsShipments: (
      apiRequest: MainStemAPIControllersAdminProductsDetailsShipmentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsDetailsShipmentsAPIResponse, any>({
        path: `/api/admin/products/details/shipments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentClassificationAdd
     * @name ProductsParentClassificationAdd
     * @request POST:/api/admin/products/parent/classification/add
     */
    productsParentClassificationAdd: (
      apiRequest: MainStemAPIControllersAdminProductsParentClassificationAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentClassificationAddAPIResponse, any>({
        path: `/api/admin/products/parent/classification/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentClassificationRemove
     * @name ProductsParentClassificationRemove
     * @request POST:/api/admin/products/parent/classification/remove
     */
    productsParentClassificationRemove: (
      apiRequest: MainStemAPIControllersAdminProductsParentClassificationRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentClassificationRemoveAPIResponse, any>({
        path: `/api/admin/products/parent/classification/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentCreate
     * @name ProductsParentCreate
     * @request POST:/api/admin/products/parent/create
     */
    productsParentCreate: (
      apiRequest: MainStemAPIControllersAdminProductsParentCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentCreateAPIResponse, any>({
        path: `/api/admin/products/parent/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentDetails
     * @name ProductsParentDetails
     * @request POST:/api/admin/products/parent/details
     */
    productsParentDetails: (
      apiRequest: MainStemAPIControllersAdminProductsParentDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentDetailsAPIResponse, any>({
        path: `/api/admin/products/parent/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentList
     * @name ProductsParentList
     * @request POST:/api/admin/products/parent/list
     */
    productsParentList: (
      apiRequest: MainStemAPIControllersAdminProductsParentListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentListAPIResponse, any>({
        path: `/api/admin/products/parent/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsParentUpdate
     * @name ProductsParentUpdate
     * @request POST:/api/admin/products/parent/update
     */
    productsParentUpdate: (
      apiRequest: MainStemAPIControllersAdminProductsParentUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductsParentUpdateAPIResponse, any>({
        path: `/api/admin/products/parent/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductsScrape
     * @name ProductsScrape
     * @request POST:/api/admin/products/scrape
     */
    productsScrape: (APIRequest: MainStemAPIControllersAdminProductsScrapeAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminProductsScrapeAPIResponse, any>({
        path: `/api/admin/products/scrape`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsAddTagsToProduct
     * @name ProductTagsAddTagsToProduct
     * @request POST:/api/admin/productTags/addTagsToProduct
     */
    productTagsAddTagsToProduct: (
      APIRequest: MainStemAPIControllersAdminProductTagsAddTagsToProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/productTags/addTagsToProduct`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsDelete
     * @name ProductsTagsDelete
     * @request POST:/api/admin/products/tags/delete
     */
    productsTagsDelete: (
      apiRequest: MainStemAPIControllersAdminProductsTagsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/products/tags/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsDetails
     * @name ProductTagsDetails
     * @request POST:/api/admin/productTags/details
     */
    productTagsDetails: (
      apiRequest: MainStemAPIControllersAdminProductTagsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductTagsDetailsAPIResponse, any>({
        path: `/api/admin/productTags/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsList
     * @name ProductTagsList
     * @request GET:/api/admin/productTags/list
     */
    productTagsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminProductTagsListAPIResponse, any>({
        path: `/api/admin/productTags/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsListForProduct
     * @name ProductTagsListForProduct
     * @request POST:/api/admin/productTags/listForProduct
     */
    productTagsListForProduct: (
      apiRequest: MainStemAPIControllersAdminProductTagsListForProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminProductTagsListForProductAPIResponse, any>({
        path: `/api/admin/productTags/listForProduct`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductTagsRemoveFromProduct
     * @name ProductTagsRemoveFromProduct
     * @request POST:/api/admin/productTags/removeFromProduct
     */
    productTagsRemoveFromProduct: (
      APIRequest: MainStemAPIControllersAdminProductTagsRemoveFromProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/productTags/removeFromProduct`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductUpdateImage
     * @name ProductsUpdateImage
     * @request POST:/api/admin/products/update/image
     */
    productsUpdateImage: (
      query: {
        /** @format int32 */
        productId: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/products/update/image`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductVolumePricingCreate
     * @name ProductsVolumePricingCreate
     * @request POST:/api/admin/products/volume-pricing/create
     */
    productsVolumePricingCreate: (
      apiRequest: MainStemAPIControllersAdminProductsVolumePricingCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/products/volume-pricing/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminProductVolumePricingDelete
     * @name ProductsVolumePricingDelete
     * @request POST:/api/admin/products/volume-pricing/delete
     */
    productsVolumePricingDelete: (
      apiRequest: MainStemAPIControllersAdminProductsVolumePricingDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/products/volume-pricing/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutRequestSession
     * @name PunchoutsSetup
     * @request POST:/api/admin/punchouts/setup
     */
    punchoutsSetup: (
      apiRequest: MainStemAPIControllersAdminPunchoutsRequestSessionAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPunchoutsRequestSessionAPIResponse, any>({
        path: `/api/admin/punchouts/setup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsAddLocations
     * @name PunchoutsAddLocations
     * @request POST:/api/admin/punchouts/add-locations
     */
    punchoutsAddLocations: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsAddLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/punchouts/add-locations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsCreate
     * @name PunchoutsCreate
     * @request POST:/api/admin/punchouts/create
     */
    punchoutsCreate: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsCreatePunchoutAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPunchoutsSettingsCreatePunchoutAPIResponse, any>({
        path: `/api/admin/punchouts/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsDetails
     * @name PunchoutsDetails
     * @request POST:/api/admin/punchouts/details
     */
    punchoutsDetails: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPunchoutsSettingsDetailsAPIResponse, any>({
        path: `/api/admin/punchouts/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsList
     * @name PunchoutsList
     * @request POST:/api/admin/punchouts/list
     */
    punchoutsList: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPunchoutsSettingsListAPIResponse, any>({
        path: `/api/admin/punchouts/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsRemoveLocations
     * @name PunchoutsRemoveLocations
     * @request POST:/api/admin/punchouts/remove-locations
     */
    punchoutsRemoveLocations: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsRemoveLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/punchouts/remove-locations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPunchoutSettingsUpdate
     * @name PunchoutsUpdate
     * @request POST:/api/admin/punchouts/update
     */
    punchoutsUpdate: (
      apiRequest: MainStemAPIControllersAdminPunchoutsSettingsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/punchouts/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPurchaseOrdersDetails
     * @name PurchaseOrdersDetails
     * @request POST:/api/admin/purchase-orders/details
     */
    purchaseOrdersDetails: (
      apiRequest: MainStemAPIControllersAdminPurchaseOrdersDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPurchaseOrdersDetailsAPIResponse, any>({
        path: `/api/admin/purchase-orders/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPurchaseOrdersDiscountsCreate
     * @name PurchaseOrdersDiscountsCreate
     * @request POST:/api/admin/purchase-orders/discounts/create
     */
    purchaseOrdersDiscountsCreate: (
      apiRequest: MainStemAPIControllersAdminPurchaseOrdersDiscountsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPurchaseOrdersDiscountsCreateAPIResponse, any>({
        path: `/api/admin/purchase-orders/discounts/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPurchaseOrdersFeesCreate
     * @name PurchaseOrdersFeesCreate
     * @request POST:/api/admin/purchase-orders/fees/create
     */
    purchaseOrdersFeesCreate: (
      apiRequest: MainStemAPIControllersAdminPurchaseOrdersFeesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPurchaseOrdersFeesCreateAPIResponse, any>({
        path: `/api/admin/purchase-orders/fees/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminPurchaseOrdersList
     * @name PurchaseOrdersList
     * @request POST:/api/admin/purchase-orders/list
     */
    purchaseOrdersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminPurchaseOrdersListAPIResponse, any>({
        path: `/api/admin/purchase-orders/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminQuickbooksSyncEvent
     * @name AccountingQuickbooksTriggerFullSync
     * @request POST:/api/admin/accounting/quickbooks/trigger/full-sync
     */
    accountingQuickbooksTriggerFullSync: (
      apiRequest: MainStemAPIControllersAdminAccountingQuickbooksQuickBooksSyncEventAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/accounting/quickbooks/trigger/full-sync`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminRefundsList
     * @name PaymentRefundList
     * @request GET:/api/admin/payment/refundList
     */
    paymentRefundList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/payment/refundList`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminRemoveNotificationEmail
     * @name NotificationEmailRemove
     * @request POST:/api/admin/notification-email/remove
     */
    notificationEmailRemove: (
      apiRequest: MainStemAPIControllersAdminLocationsNotificationEmailsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/notification-email/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminReturnCreate
     * @name ReturnCreateAndModify
     * @request POST:/api/admin/return/createAndModify
     */
    returnCreateAndModify: (
      apiRequest: MainStemAPIControllersAdminReturnsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminReturnsCreateAPIResponse, any>({
        path: `/api/admin/return/createAndModify`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminReturnDetails
     * @name ReturnDetails
     * @request POST:/api/admin/return/details
     */
    returnDetails: (apiRequest: MainStemAPIControllersAdminReturnsDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminReturnsDetailsAPIResponse, any>({
        path: `/api/admin/return/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminReturnDetailsOfOrder
     * @name ReturnDetailsOfOrder
     * @request GET:/api/admin/return/detailsOfOrder
     */
    returnDetailsOfOrder: (
      query?: {
        /** @format int32 */
        apiRequestOrderId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminReturnsDetailsOfOrderAPIResponse, any>({
        path: `/api/admin/return/detailsOfOrder`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminReturnOrderList
     * @name ReturnsList
     * @request GET:/api/admin/returns/list
     */
    returnsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminReturnsListAPIResponse, any>({
        path: `/api/admin/returns/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminReturnsForOrder
     * @name SalesOrdersGetReturns
     * @request POST:/api/admin/sales-orders/getReturns
     */
    salesOrdersGetReturns: (
      apiRequest: MainStemAPIControllersAdminOrdersReturnsForSalesOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersReturnsForSalesOrderAPIResponse, any>({
        path: `/api/admin/sales-orders/getReturns`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSearch
     * @name Search
     * @request GET:/api/admin/search
     */
    search: (
      query?: {
        apiRequestQuery?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSearchAPIResponse, any>({
        path: `/api/admin/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSearchIndexProducts
     * @name SearchProductIndex
     * @request POST:/api/admin/search/productIndex
     */
    searchProductIndex: (apiRequest: MainStemSharpServiceBusSearchIndexRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminSearchQueueSearchIndexAPIResponse, any>({
        path: `/api/admin/search/productIndex`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentsDetails
     * @name ShipmentsDetails
     * @request POST:/api/admin/shipments/details
     */
    shipmentsDetails: (
      apiRequest: MainStemAPIControllersAdminCustomersShipmentsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/shipments/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentsList
     * @name ShipmentsList
     * @request POST:/api/admin/shipments/list
     */
    shipmentsList: (
      apiRequest: MainStemAPIControllersAdminCustomersShipmentsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersShipmentsListAPIResponse, any>({
        path: `/api/admin/shipments/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentTrackingCreate
     * @name ShipmentTrackingCreate
     * @request POST:/api/admin/shipment-tracking/create
     */
    shipmentTrackingCreate: (
      apiRequest: MainStemAPIControllersAdminShipmentTrackingCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminShipmentTrackingCreateAPIResponse, any>({
        path: `/api/admin/shipment-tracking/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentTrackingForInvoice
     * @name ShipmentTrackingInvoice
     * @request POST:/api/admin/shipment-tracking/invoice
     */
    shipmentTrackingInvoice: (
      apiRequest: MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminShipmentTrackingTrackingForInvoiceAPIResponse, any>({
        path: `/api/admin/shipment-tracking/invoice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentTrackingForOrder
     * @name ShipmentTrackingOrder
     * @request POST:/api/admin/shipment-tracking/order
     */
    shipmentTrackingOrder: (
      apiRequest: MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminShipmentTrackingTrackingForOrderAPIResponse, any>({
        path: `/api/admin/shipment-tracking/order`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminShipmentTrackingUpdate
     * @name ShipmentTrackingUpdate
     * @request POST:/api/admin/shipment-tracking/update
     */
    shipmentTrackingUpdate: (
      apiRequest: MainStemAPIControllersAdminShipmentTrackingUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/shipment-tracking/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSoftDeleteOrRecoverUser
     * @name UsersDelete
     * @request POST:/api/admin/users/delete
     */
    usersDelete: (
      request: MainStemAPIControllersAdminUsersSoftDeleteOrRecoveryAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/delete`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminStaleOrderTracking
     * @name FulfillmentTrackerStaleOrderTracking
     * @request POST:/api/admin/fulfillment-tracker/staleOrderTracking
     */
    fulfillmentTrackerStaleOrderTracking: (
      apiRequest: MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminFulfillmentTrackerStaleOrderTrackingAPIResponse, any>({
        path: `/api/admin/fulfillment-tracker/staleOrderTracking`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierAddNotificationEmail
     * @name SuppliersNotificationEmailAdd
     * @request POST:/api/admin/suppliers/notification-email/add
     */
    suppliersNotificationEmailAdd: (
      apiRequest: MainStemAPIControllersAdminSuppliersUpdateNotificationEmailsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/suppliers/notification-email/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierBandedShippingCreate
     * @name SuppliersBandedShippingCreate
     * @request POST:/api/admin/suppliers/banded-shipping/create
     */
    suppliersBandedShippingCreate: (
      apiRequest: MainStemAPIControllersAdminSuppliersBandedShippingCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersBandedShippingCreateAPIResponse, any>({
        path: `/api/admin/suppliers/banded-shipping/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierBandedShippingDelete
     * @name SuppliersBandedShippingDelete
     * @request POST:/api/admin/suppliers/banded-shipping/delete
     */
    suppliersBandedShippingDelete: (
      apiRequest: MainStemAPIControllersAdminSuppliersBandedShippingDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersBandedShippingDeleteAPIResponse, any>({
        path: `/api/admin/suppliers/banded-shipping/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierBandedShippingUpdate
     * @name SuppliersBandedShippingUpdate
     * @request POST:/api/admin/suppliers/banded-shipping/update
     */
    suppliersBandedShippingUpdate: (
      apiRequest: MainStemAPIControllersAdminSuppliersBandedShippingUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersBandedShippingUpdateAPIResponse, any>({
        path: `/api/admin/suppliers/banded-shipping/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierCreate
     * @name SuppliersCreate
     * @request POST:/api/admin/suppliers/create
     */
    suppliersCreate: (apiRequest: MainStemAPIControllersAdminSuppliersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminSuppliersCreateAPIResponse, any>({
        path: `/api/admin/suppliers/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierDetails
     * @name SuppliersDetails
     * @request POST:/api/admin/suppliers/details
     */
    suppliersDetails: (apiRequest: MainStemAPIControllersAdminSuppliersDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsAPIResponse, any>({
        path: `/api/admin/suppliers/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierDetailsIntegrationsHawthorneProductExport
     * @name SuppliersDetailsIntegrationsHawthorneProductExport
     * @request GET:/api/admin/suppliers/details/integrations/hawthorne/product/export
     */
    suppliersDetailsIntegrationsHawthorneProductExport: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/suppliers/details/integrations/hawthorne/product/export`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierDetailsShipments
     * @name SuppliersDetailsShipments
     * @request GET:/api/admin/suppliers/details/shipments
     */
    suppliersDetailsShipments: (
      query?: {
        /** @format int32 */
        apiRequestSupplierId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsShipmentsAPIResponse, any>({
        path: `/api/admin/suppliers/details/shipments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierIntegrationLogsList
     * @name SuppliersDetailsIntegrationLogsList
     * @request POST:/api/admin/suppliers/details/integration-logs/list
     */
    suppliersDetailsIntegrationLogsList: (
      apiRequest: MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsIntegrationLogsListAPIResponse, any>({
        path: `/api/admin/suppliers/details/integration-logs/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierMetrics
     * @name SuppliersMetrics
     * @request POST:/api/admin/suppliers/metrics
     */
    suppliersMetrics: (apiRequest: MainStemAPIControllersAdminSuppliersMetricsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminSuppliersMetricsAPIResponse, any>({
        path: `/api/admin/suppliers/metrics`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierRemoveNotificationEmail
     * @name SuppliersNotificationEmailRemove
     * @request POST:/api/admin/suppliers/notification-email/remove
     */
    suppliersNotificationEmailRemove: (
      apiRequest: MainStemAPIControllersAdminSuppliersUpdateNotificationEmailsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/suppliers/notification-email/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSuppliersDetailsLogProductSync
     * @name SuppliersDetailsLogsProductSync
     * @request POST:/api/admin/suppliers/details/logs/productSync
     */
    suppliersDetailsLogsProductSync: (
      apiRequest: MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsLogsProductSyncAPIResponse, any>({
        path: `/api/admin/suppliers/details/logs/productSync`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSuppliersDetailsOrders
     * @name SuppliersDetailsOrders
     * @request GET:/api/admin/suppliers/details/orders
     */
    suppliersDetailsOrders: (
      query?: {
        /** @format int32 */
        apiRequestSupplierId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsOrdersAPIResponse, any>({
        path: `/api/admin/suppliers/details/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSuppliersDetailsProducts
     * @name SuppliersDetailsProducts
     * @request GET:/api/admin/suppliers/details/products
     */
    suppliersDetailsProducts: (
      query?: {
        /** @format int32 */
        apiRequestSupplierId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsProductsAPIResponse, any>({
        path: `/api/admin/suppliers/details/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSuppliersDetailsProductsPurchased
     * @name SuppliersDetailsProductsPurchased
     * @request GET:/api/admin/suppliers/details/productsPurchased
     */
    suppliersDetailsProductsPurchased: (
      query?: {
        /** @format int32 */
        apiRequestSupplierId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSuppliersDetailsProductsPurchasedAPIResponse, any>({
        path: `/api/admin/suppliers/details/productsPurchased`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSuppliersList
     * @name SuppliersList
     * @request POST:/api/admin/suppliers/list
     */
    suppliersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminSuppliersListAPIResponse, any>({
        path: `/api/admin/suppliers/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierUpdate
     * @name SuppliersUpdate
     * @request POST:/api/admin/suppliers/update
     */
    suppliersUpdate: (apiRequest: MainStemAPIControllersAdminSuppliersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/suppliers/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierUpdateAddress
     * @name SuppliersUpdateAddress
     * @request POST:/api/admin/suppliers/update/address
     */
    suppliersUpdateAddress: (
      apiRequest: MainStemAPIControllersAdminSuppliersUpdateAddressAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/suppliers/update/address`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplierUpdateLogo
     * @name SuppliersUpdateLogo
     * @request POST:/api/admin/suppliers/update/logo
     */
    suppliersUpdateLogo: (
      query: {
        /** @format int32 */
        supplierID: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardOrdersMissingAvalaraTaxDocumentsAPIRequest, any>({
        path: `/api/admin/suppliers/update/logo`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditBaseinePriceListForCustomer
     * @name SupplyAuditBaselinePriceListCustomer
     * @request POST:/api/admin/supply-audit/baseline-price/list/customer
     */
    supplyAuditBaselinePriceListCustomer: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditBaseLinePriceListForCustomerAPIResponse, any>({
        path: `/api/admin/supply-audit/baseline-price/list/customer`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditBaselinePriceCreate
     * @name SupplyAuditBaselinePriceCreate
     * @request POST:/api/admin/supply-audit/baseline-price/create
     */
    supplyAuditBaselinePriceCreate: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditBaseLinePriceCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditBaseLinePriceCreateAPIResponse, any>({
        path: `/api/admin/supply-audit/baseline-price/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditBaselinePriceDelete
     * @name SupplyAuditBaselinePriceDelete
     * @request POST:/api/admin/supply-audit/baseline-price/delete
     */
    supplyAuditBaselinePriceDelete: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditBaseLinePriceDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditBaseLinePriceDeleteAPIResponse, any>({
        path: `/api/admin/supply-audit/baseline-price/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditBaselinePriceUpdate
     * @name SupplyAuditBaselinePriceUpdate
     * @request POST:/api/admin/supply-audit/baseline-price/update
     */
    supplyAuditBaselinePriceUpdate: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditBaseLinePriceUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditBaseLinePriceUpdateAPIResponse, any>({
        path: `/api/admin/supply-audit/baseline-price/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditBaselinePriceUpload
     * @name SupplyAuditBaselinePriceUpload
     * @request POST:/api/admin/supply-audit/baseline-price/upload
     */
    supplyAuditBaselinePriceUpload: (
      query?: {
        /** @format int32 */
        apiRequestCustomerId?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditBaseLinePriceUploadAPIResponse, any>({
        path: `/api/admin/supply-audit/baseline-price/upload`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditCreate
     * @name SupplyAuditCreate
     * @request POST:/api/admin/supply-audit/create
     */
    supplyAuditCreate: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditCreateAPIResponse, any>({
        path: `/api/admin/supply-audit/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditDetails
     * @name SupplyAuditDetails
     * @request POST:/api/admin/supply-audit/details
     */
    supplyAuditDetails: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditDetailsAPIResponse, any>({
        path: `/api/admin/supply-audit/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminSupplyAuditListForCustomer
     * @name SupplyAuditListForCustomer
     * @request POST:/api/admin/supply-audit/list-for-customer
     */
    supplyAuditListForCustomer: (
      apiRequest: MainStemAPIControllersAdminSupplyAuditListForCustomerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminSupplyAuditListForCustomerAPIResponse, any>({
        path: `/api/admin/supply-audit/list-for-customer`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminTermsCreate
     * @name CustomersBillingTermsCreate
     * @request POST:/api/admin/customers/billing/terms/create
     */
    customersBillingTermsCreate: (
      apiRequest: MainStemSharpHelpersTermsFactoryTermsCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpHelpersTermsFactoryTermsCreateResponse, any>({
        path: `/api/admin/customers/billing/terms/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminTermsLedger
     * @name CustomersBillingTermsLedger
     * @request POST:/api/admin/customers/billing/terms/ledger
     */
    customersBillingTermsLedger: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingTermsTermsLedgerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponse, any>({
        path: `/api/admin/customers/billing/terms/ledger`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminTermsTotal
     * @name CustomersBillingTermsTotals
     * @request POST:/api/admin/customers/billing/terms/totals
     */
    customersBillingTermsTotals: (
      APIRequest: MainStemAPIControllersAdminCustomersBillingTermsTermsTotalAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomersBillingTermsTermsTotalAPIResponse, any>({
        path: `/api/admin/customers/billing/terms/totals`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminTermsUpdate
     * @name CustomersBillingTermsUpdate
     * @request POST:/api/admin/customers/billing/terms/update
     */
    customersBillingTermsUpdate: (
      apiRequest: MainStemSharpHelpersTermsFactoryTermsUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpHelpersTermsFactoryTermsUpdateResponse, any>({
        path: `/api/admin/customers/billing/terms/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUnchargedFreightShipment
     * @name FulfillmentTrackerFreightShippingTracking
     * @request POST:/api/admin/fulfillment-tracker/freightShippingTracking
     */
    fulfillmentTrackerFreightShippingTracking: (
      apiRequest: MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminFulfillmentTrackerUnchargedFreightShippingAPIResponse, any>({
        path: `/api/admin/fulfillment-tracker/freightShippingTracking`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUnfulfilledOrderLineItems
     * @name FulfillmentTrackerUnfulfilledOrderLineItems
     * @request POST:/api/admin/fulfillment-tracker/unfulfilledOrderLineItems
     */
    fulfillmentTrackerUnfulfilledOrderLineItems: (
      apiRequest: MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminFulfillmentTrackerUnfulfilledOrderLineItemsAPIResponse, any>({
        path: `/api/admin/fulfillment-tracker/unfulfilledOrderLineItems`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCClassCreate
     * @name UnspscClassCreate
     * @request POST:/api/admin/unspsc/class/create
     */
    unspscClassCreate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCClassCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCClassCreateAPIResponse, any>({
        path: `/api/admin/unspsc/class/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCClassDetails
     * @name UnspscClassDetails
     * @request POST:/api/admin/unspsc/class/details
     */
    unspscClassDetails: (
      apiRequest: MainStemAPIControllersAdminUNSPSCClassDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCClassDetailsAPIResponse, any>({
        path: `/api/admin/unspsc/class/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCClassList
     * @name UnspscClassList
     * @request POST:/api/admin/unspsc/class/list
     */
    unspscClassList: (apiRequest: MainStemAPIControllersAdminUNSPSCClassListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminUNSPSCClassListAPIResponse, any>({
        path: `/api/admin/unspsc/class/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCClassUpdate
     * @name UnspscClassUpdate
     * @request POST:/api/admin/unspsc/class/update
     */
    unspscClassUpdate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCClassUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCClassUpdateAPIResponse, any>({
        path: `/api/admin/unspsc/class/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCCommodityCreate
     * @name UnspscCommodityCreate
     * @request POST:/api/admin/unspsc/commodity/create
     */
    unspscCommodityCreate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCCommodityCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCCommodityCreateAPIResponse, any>({
        path: `/api/admin/unspsc/commodity/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCCommodityDetails
     * @name UnspscCommodityDetails
     * @request POST:/api/admin/unspsc/commodity/details
     */
    unspscCommodityDetails: (
      apiRequest: MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCCommodityDetailsAPIResponse, any>({
        path: `/api/admin/unspsc/commodity/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCCommodityList
     * @name UnspscCommodityList
     * @request POST:/api/admin/unspsc/commodity/list
     */
    unspscCommodityList: (
      apiRequest: MainStemAPIControllersAdminUNSPSCCommodityListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCCommodityListAPIResponse, any>({
        path: `/api/admin/unspsc/commodity/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCCommodityUpdate
     * @name UnspscCommodityUpdate
     * @request POST:/api/admin/unspsc/commodity/update
     */
    unspscCommodityUpdate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCCommodityUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCCommodityUpdateAPIResponse, any>({
        path: `/api/admin/unspsc/commodity/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCFamilyCreate
     * @name UnspscFamilyCreate
     * @request POST:/api/admin/unspsc/family/create
     */
    unspscFamilyCreate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCFamilyCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCFamilyCreateAPIResponse, any>({
        path: `/api/admin/unspsc/family/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCFamilyDetails
     * @name UnspscFamilyDetails
     * @request POST:/api/admin/unspsc/family/details
     */
    unspscFamilyDetails: (
      apiRequest: MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCFamilyDetailsAPIResponse, any>({
        path: `/api/admin/unspsc/family/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCFamilyList
     * @name UnspscFamilyList
     * @request POST:/api/admin/unspsc/family/list
     */
    unspscFamilyList: (apiRequest: MainStemAPIControllersAdminUNSPSCFamilyListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminUNSPSCFamilyListAPIResponse, any>({
        path: `/api/admin/unspsc/family/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCFamilyUpdate
     * @name UnspscFamilyUpdate
     * @request POST:/api/admin/unspsc/family/update
     */
    unspscFamilyUpdate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCFamilyUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCFamilyUpdateAPIResponse, any>({
        path: `/api/admin/unspsc/family/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCSegmentCreate
     * @name UnspscSegmentCreate
     * @request POST:/api/admin/unspsc/segment/create
     */
    unspscSegmentCreate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCSegmentCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCSegmentCreateAPIResponse, any>({
        path: `/api/admin/unspsc/segment/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCSegmentDetails
     * @name UnspscSegmentDetails
     * @request POST:/api/admin/unspsc/segment/details
     */
    unspscSegmentDetails: (
      apiRequest: MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCSegmentDetailsAPIResponse, any>({
        path: `/api/admin/unspsc/segment/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCSegmentList
     * @name UnspscSegmentList
     * @request POST:/api/admin/unspsc/segment/list
     */
    unspscSegmentList: (
      apiRequest: MainStemAPIControllersAdminUNSPSCSegmentListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCSegmentListAPIResponse, any>({
        path: `/api/admin/unspsc/segment/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUNSPSCSegmentUpdate
     * @name UnspscSegmentUpdate
     * @request POST:/api/admin/unspsc/segment/update
     */
    unspscSegmentUpdate: (
      apiRequest: MainStemAPIControllersAdminUNSPSCSegmentUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUNSPSCSegmentUpdateAPIResponse, any>({
        path: `/api/admin/unspsc/segment/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserPermissionCreate
     * @name UserPermissionCreate
     * @request POST:/api/admin/userPermission/create
     */
    userPermissionCreate: (
      apiRequest: MainStemAPIControllersAdminUserPermissionsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUserPermissionsCreateAPIResponse, any>({
        path: `/api/admin/userPermission/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserPermissionDelete
     * @name UserPermissionDelete
     * @request POST:/api/admin/userPermission/delete
     */
    userPermissionDelete: (
      apiRequest: MainStemAPIControllersAdminUserPermissionsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/userPermission/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserPermissionUpdate
     * @name UserPermissionUpdate
     * @request POST:/api/admin/userPermission/update
     */
    userPermissionUpdate: (
      apiRequest: MainStemAPIControllersAdminUserPermissionsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUserPermissionsUpdateAPIResponse, any>({
        path: `/api/admin/userPermission/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserRemoveDefaultLocation
     * @name UsersLocationsRemoveDefault
     * @request POST:/api/admin/users/locations/removeDefault
     */
    usersLocationsRemoveDefault: (
      apiRequest: MainStemAPIControllersAdminUsersUpdateLocationsRemoveDefaultAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/locations/removeDefault`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserRemoveLocation
     * @name UsersLocationsRemove
     * @request POST:/api/admin/users/locations/remove
     */
    usersLocationsRemove: (
      apiRequest: MainStemAPIControllersAdminUsersUpdateLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersCreate
     * @name UsersCreate
     * @request POST:/api/admin/users/create
     */
    usersCreate: (apiRequest: MainStemAPIControllersAdminUsersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminUsersCreateAPIResponse, any>({
        path: `/api/admin/users/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersDetails
     * @name UsersDetails
     * @request GET:/api/admin/users/details
     */
    usersDetails: (
      query?: {
        apiRequestUuid?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersDetailsAPIResponse, any>({
        path: `/api/admin/users/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersDetailsOrders
     * @name UsersDetailsOrders
     * @request POST:/api/admin/users/details/orders
     */
    usersDetailsOrders: (
      apiRequest: MainStemAPIControllersAdminUsersDetailsOrdersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersDetailsOrdersAPIResponse, any>({
        path: `/api/admin/users/details/orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersDetailsProducts
     * @name UsersDetailsProducts
     * @request POST:/api/admin/users/details/products
     */
    usersDetailsProducts: (
      apiRequest: MainStemAPIControllersAdminUsersDetailsProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersDetailsProductsAPIResponse, any>({
        path: `/api/admin/users/details/products`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersDetailsResetPassword
     * @name UsersDetailsResetPassword
     * @request POST:/api/admin/users/details/resetPassword
     */
    usersDetailsResetPassword: (
      apiRequest: MainStemAPIControllersAdminUsersDetailsResetPasswordAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersDetailsAPIResponse, any>({
        path: `/api/admin/users/details/resetPassword`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUserSetDefaultLocation
     * @name UsersLocationsSetDefault
     * @request POST:/api/admin/users/locations/setDefault
     */
    usersLocationsSetDefault: (
      apiRequest: MainStemAPIControllersAdminUsersUpdateLocationsSetDefaultAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/locations/setDefault`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersList
     * @name UsersList
     * @request POST:/api/admin/users/list
     */
    usersList: (apiRequest: MainStemAPIControllersAdminUsersListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminUsersListAPIResponse, any>({
        path: `/api/admin/users/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersUpdate
     * @name UsersUpdate
     * @request POST:/api/admin/users/update
     */
    usersUpdate: (apiRequest: MainStemAPIControllersAdminUsersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminUsersUpdateLocationsAdd
     * @name UsersLocationsAdd
     * @request POST:/api/admin/users/locations/add
     */
    usersLocationsAdd: (
      apiRequest: MainStemAPIControllersAdminUsersUpdateLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/users/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminValueMetrics
     * @name ValueMetrics
     * @request POST:/api/admin/valueMetrics
     */
    valueMetrics: (apiRequest: MainStemAPIControllersAdminValueMetricsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminValueMetricsAPIResponse, any>({
        path: `/api/admin/valueMetrics`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminVoidPayment
     * @name PaymentVoid
     * @request POST:/api/admin/payment/void
     */
    paymentVoid: (apiRequest: MainStemAPIControllersAdminPaymentsVoidPaymentAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminPaymentsVoidPaymentAPIResponse, any>({
        path: `/api/admin/payment/void`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminWorkflowActionsList
     * @name WorkflowActionsList
     * @request POST:/api/admin/workflow/actions/list
     */
    workflowActionsList: (
      apiRequest: MainStemAPIControllersAdminWorkflowActionsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminWorkflowActionsListAPIResponse, any>({
        path: `/api/admin/workflow/actions/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminWorkflowAssignmentsCreate
     * @name WorkflowAssignmentsCreate
     * @request POST:/api/admin/workflow/assignments/create
     */
    workflowAssignmentsCreate: (
      apiRequest: MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminWorkflowAssignmentsCreateAPIResponse, any>({
        path: `/api/admin/workflow/assignments/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminWorkflowEndpointsCreate
     * @name WorkflowEndpointsCreate
     * @request POST:/api/admin/workflow/endpoints/create
     */
    workflowEndpointsCreate: (
      apiRequest: MainStemAPIControllersAdminWorkflowEndpointsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminWorkflowEndpointsCreateAPIResponse, any>({
        path: `/api/admin/workflow/endpoints/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminWorkflowEndpointsList
     * @name WorkflowEndpointsList
     * @request POST:/api/admin/workflow/endpoints/list
     */
    workflowEndpointsList: (
      apiRequest: MainStemAPIControllersAdminWorkflowEndpointsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminWorkflowEndpointsListAPIResponse, any>({
        path: `/api/admin/workflow/endpoints/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags AdminWorkflowList
     * @name WorkflowList
     * @request POST:/api/admin/workflow/list
     */
    workflowList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminWorkflowListAPIResponse, any>({
        path: `/api/admin/workflow/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags BillsCreate
     * @name BillCreate
     * @request POST:/api/admin/bill/create
     */
    billCreate: (apiRequest: MainStemAPIControllersAdminBillsCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminBillsCreateAPIResponse, any>({
        path: `/api/admin/bill/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags BillsUpdate
     * @name BillUpdate
     * @request POST:/api/admin/bill/update
     */
    billUpdate: (apiRequest: MainStemAPIControllersAdminBillsUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminBillsUpdateAPIResponse, any>({
        path: `/api/admin/bill/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CreateMissingAvalaraTaxDocuments
     * @name AccountingDashboardCreateMissingAvalaraTaxDocuments
     * @request POST:/api/admin/accounting/dashboard/createMissingAvalaraTaxDocuments
     */
    accountingDashboardCreateMissingAvalaraTaxDocuments: (
      apiRequest: MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminAccountingDashboardCreateMissingAvalaraTaxDocumentsAPIResponse, any>({
        path: `/api/admin/accounting/dashboard/createMissingAvalaraTaxDocuments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldCreate
     * @name CustomFieldsCreate
     * @request POST:/api/admin/customFields/create
     */
    customFieldsCreate: (
      apiRequest: MainStemAPIControllersAdminCustomFieldsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomFieldsCreateAPIResponse, any>({
        path: `/api/admin/customFields/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldCreateOrUpdate
     * @name CustomFieldsCreateOrUpdate
     * @request POST:/api/admin/customFields/createOrUpdate
     */
    customFieldsCreateOrUpdate: (
      apiRequest: MainStemAPIControllersAdminCustomFieldsCreateOrUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomFieldsCreateOrUpdateAPIResponse, any>({
        path: `/api/admin/customFields/createOrUpdate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldDelete
     * @name CustomFieldsDelete
     * @request POST:/api/admin/customFields/delete
     */
    customFieldsDelete: (
      apiRequest: MainStemAPIControllersAdminCustomFieldsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/customFields/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldGetValue
     * @name CustomFieldsValue
     * @request POST:/api/admin/customFields/value
     */
    customFieldsValue: (
      apiRequest: MainStemAPIControllersAdminCustomFieldsGetValueAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomFieldsGetValueAPIResponse, any>({
        path: `/api/admin/customFields/value`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldList
     * @name CustomFieldsList
     * @request POST:/api/admin/customFields/list
     */
    customFieldsList: (apiRequest: MainStemAPIControllersAdminCustomFieldsListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersAdminCustomFieldsListAPIResponse, any>({
        path: `/api/admin/customFields/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags CustomFieldUpdate
     * @name CustomFieldsUpdate
     * @request POST:/api/admin/customFields/update
     */
    customFieldsUpdate: (
      apiRequest: MainStemAPIControllersAdminCustomFieldsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminCustomFieldsUpdateAPIResponse, any>({
        path: `/api/admin/customFields/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags DisableAllLocationsPaymentOption
     * @name PaymentMethodsLocationsDisableAllowablePayment
     * @request POST:/api/admin/paymentMethods/locations/disableAllowablePayment
     */
    paymentMethodsLocationsDisableAllowablePayment: (
      apiRequest: MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersAdminCustomersBillingPaymentMethodsDisableAllLocationsPaymentOptionAPIResponse,
        any
      >({
        path: `/api/admin/paymentMethods/locations/disableAllowablePayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderInvoiceAddItem
     * @name OrdersInvoiceItemsAdd
     * @request POST:/api/admin/orders/invoiceItems/add
     */
    ordersInvoiceItemsAdd: (
      apiRequest: MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersInvoiceItemsAddAPIResponse, any>({
        path: `/api/admin/orders/invoiceItems/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderInvoiceCreate
     * @name OrdersInvoicesCreate
     * @request POST:/api/admin/orders/invoices/create
     */
    ordersInvoicesCreate: (
      apiRequest: MainStemAPIControllersAdminOrdersInvoicesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersInvoicesCreateAPIResponse, any>({
        path: `/api/admin/orders/invoices/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderInvoiceDelete
     * @name OrdersInvoicesDelete
     * @request POST:/api/admin/orders/invoices/delete
     */
    ordersInvoicesDelete: (
      APIRequest: MainStemAPIControllersAdminOrdersInvoicesDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/invoices/delete`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrderInvoiceUpdate
     * @name OrdersInvoicesUpdate
     * @request POST:/api/admin/orders/invoices/update
     */
    ordersInvoicesUpdate: (
      apiRequest: MainStemAPIControllersAdminOrdersInvoicesUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/admin/orders/invoices/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags OrdersSalesTaxCalculate
     * @name OrdersCalculateSalesTax
     * @request POST:/api/admin/orders/calculateSalesTax
     */
    ordersCalculateSalesTax: (
      APIRequest: MainStemAPIControllersAdminOrdersSalesTaxCalculateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersSalesTaxCalculateAPIResponse, any>({
        path: `/api/admin/orders/calculateSalesTax`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersEmailVerificationLogs
     * @name OrdersEmailVerificationLogs
     * @request POST:/api/admin/orders/emailVerificationLogs
     */
    ordersEmailVerificationLogs: (
      apiRequest: MainStemAPIControllersAdminOrdersEmailVerificationLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminOrdersEmailVerificationLogsAPIResponse, any>({
        path: `/api/admin/orders/emailVerificationLogs`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UserAddPermissionManager
     * @name UsersDetailsAddPermissionManager
     * @request POST:/api/admin/users/details/addPermissionManager
     */
    usersDetailsAddPermissionManager: (
      apiRequest: MainStemAPIControllersAdminPermissionAddPermissionManagerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminPermissionAddPermissionManagerAPIResponse, any>({
        path: `/api/admin/users/details/addPermissionManager`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags UsersDetailsSupplier
     * @name UsersDetailsSuppliers
     * @request POST:/api/admin/users/details/suppliers
     */
    usersDetailsSuppliers: (
      apiRequest: MainStemAPIControllersAdminUsersDetailsSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersAdminUsersDetailsSuppliersAPIResponse, any>({
        path: `/api/admin/users/details/suppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
}

export const MainStemApi = new Api()
