// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import { Badge, Button, FormattedNumber, PageTitle, Tab, Tabs, theme } from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faRectangleHistoryCirclePlus } from '@fortawesome/pro-light-svg-icons'
// Local - Components
import { TableChildren, TableParents } from './components'

const PageProductsDashboard: React.FC = () => {
  // Counts
  const [countParentProducts, setCountParentProducts] = useState<number>(0)
  const [countParentProductsFiltered, setCountParentProductsFiltered] = useState<number>(0)
  const [countChildProducts, setCountChildProducts] = useState<number>(0)
  const [countChildProductsFiltered, setCountChildProductsFiltered] = useState<number>(0)

  return (
    <>
      <PageTitle
        actions={
          <>
            <Link to='/products/scrape'>
              <Button color='primary' icon={faCloudArrowDown}>
                Product Scrape
              </Button>
            </Link>
            <Link to='/products/management'>
              <Button color='primary' icon={faRectangleHistoryCirclePlus}>
                Product Management
              </Button>
            </Link>
            <Link to='/products/create'>
              <Button color='fancy'>
                <FontAwesomeIcon icon={theme.icons.new} />
                &nbsp;Create Product
              </Button>
            </Link>
          </>
        }
        icon={theme.icons.mainstem.products}
        subtitle="Here you can find all of the products that are listed on any of MainStem's platforms."
        tags={
          <>
            <Badge>
              Total Parent Products: <FormattedNumber value={countParentProducts} />
            </Badge>
            <Badge>
              Total Child Products: <FormattedNumber value={countChildProducts} />
            </Badge>
          </>
        }
        title='Products'
      />
      <br />
      <Tabs tabs2>
        <Tab length={countParentProducts} title='Parent Products'>
          <>
            <TableParents
              onCountsUpdated={(count, countFiltered) => {
                setCountParentProducts(count)
                setCountParentProductsFiltered(countFiltered)
              }}
            />
          </>
        </Tab>
        <Tab length={countChildProducts} title='Child Products'>
          <>
            <TableChildren
              onCountsUpdated={(count, countFiltered) => {
                setCountChildProducts(count)
                setCountChildProductsFiltered(countFiltered)
              }}
            />
          </>
        </Tab>
      </Tabs>
    </>
  )
}

export default PageProductsDashboard
